import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { TabPane } from "reactstrap";

import ListGallery from "../../components/list-gallery/ListGallery";
import Api from "../../services/api/Api";

import "./TabGallery.scss";

TabGallery.propTypes = {
  tabId: PropTypes.string,
};
function TabGallery(props) {
  const { type, tabId } = props;

  const [list, setList] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageMax, setPageMax] = useState(0);

  const handleEndScroll = useRef();

  useEffect(() => {
    handleEndScroll.current = handleLoadMore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, pageMax, list]);

  useEffect(() => {
    let timer = null;
    window.addEventListener("scroll", function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let element = document.querySelector("#gallery-new");
        if (element.getBoundingClientRect().bottom < 1500) {
          handleEndScroll.current();
        }
      }, 500);
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  useEffect(() => {
    switch (type) {
      case "news":
        Api.gallery.listNew(0).then((res) => {
          setList(mapList(res.item.gallery, res.item.likes));
          setPageMax(Number(res.item.num_page));
        });
        break;
      case "likes":
        Api.gallery.listLike(0).then((res) => {
          setList(mapList(res.item.gallery, res.item.likes));
          setPageMax(Number(res.item.num_page));
        });
        break;

      default:
        setList([]);
        break;
    }
  }, [type]);

  // Function
  function mapList(listGallery, listLike) {
    return listGallery.map((item) => {
      let index = listLike.findIndex((i) => i.gallery_id === item.id);
      return { ...item, is_like: index >= 0 };
    });
  }

  function handleLoadMore() {
    let newPage = Math.min(pageIndex + 1, pageMax);
    if (newPage > pageIndex) {
      setPageIndex(newPage);
      Api.gallery.listNew(newPage).then((res) => {
        setList([...list, ...res.item.gallery]);
      });
    }
  }

  return (
    <TabPane
      tabId={tabId}
      id="gallery-new"
      className="tab-gallery"
      role="tabpanel"
      aria-labelledby="gallery-new-tab"
    >
      {!list || list.length ? (
        <ListGallery list={list} handleLoadMore={handleLoadMore} />
      ) : (
        <div className="no-data"></div>
      )}
    </TabPane>
  );
}

export default TabGallery;
