import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../services/api/Api";

import { CourseContext } from "../../context/CourseContext";

import { TabContent, Nav, NavItem, NavLink } from "reactstrap";

import "./ProfileScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import ProfileBanner from "./ProfileBanner";
import TabProfile from "./TabProfile";
import TabCourse from "./TabCourse";
import TabAchievements from "./TabAchievements";
import TabGallery from "../gallery/TabGallery";
import UploadGalleryModal from "../../components/modals/UploadGalleryModal";

// import Player from "react-lottie-player";
// import loader from "../../src/assets/loading.json";

function ProfileScreen(props) {
  const location = useLocation();

  const { setInfo } = useContext(CourseContext);

  const tab = location.state.tab || "1";

  const [activeTab, setActiveTab] = useState(tab);
  const [addGallery, setAddGallery] = useState(false);

  // get profile
  useEffect(() => {
    let token = localStorage.getItem("accessToken");

    token &&
      Api.getProfile()
        .then(function (response) {
          setInfo({ ...response.item.user });
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [setInfo]);

  return (
    <div className="profile">
      <PosterBanner />

      <div className="m-container">
        <ProfileBanner />
        <div className="tabs-profile tabs-area">
          <div className="rows article-title justify-content-between">
            <div className="d-inline-block">
              <Nav className="nav-pills" id="pills-tab">
                <NavItem>
                  <NavLink
                    active={activeTab === "1"}
                    id="pills-account-tab"
                    onClick={() => {
                      setActiveTab("1");
                    }}
                  >
                    Tài khoản
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "2"}
                    id="pills-course-tab"
                    onClick={() => {
                      setActiveTab("2");
                    }}
                  >
                    Khóa học
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "3"}
                    id="pills-library-tab"
                    onClick={() => {
                      setActiveTab("3");
                    }}
                  >
                    Thư viện
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === "4"}
                    id="pills-achievements-tab"
                    onClick={() => {
                      setActiveTab("4");
                    }}
                  >
                    Thành tích
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="d-inline-block add-gallery">
              {activeTab === "3" && (
                <div
                  className="nav-add nav-link"
                  onClick={() => setAddGallery(true)}
                >
                  + Thêm tác phẩm
                </div>
              )}
            </div>
          </div>
          <TabContent
            activeTab={activeTab}
            className="commons-card"
            id="pills-tabContent"
          >
            <TabProfile tabId="1" />
            <TabCourse tabId="2" />
            <TabGallery type="news" tabId="3" />
            <TabAchievements />
          </TabContent>
        </div>
      </div>

      <UploadGalleryModal
        isOpen={addGallery}
        closeModal={() => setAddGallery(false)}
      />

      {/* <Player autoplay speed={2} loop src={loader} style={{ height: "100px", width: "100px" }}></Player> */}
    </div>
  );
}

export default ProfileScreen;
