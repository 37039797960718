import React, { useContext } from "react";

import { Link, useHistory } from "react-router-dom";

import { Card, CardBody } from "reactstrap";

import "./NotificationScreen.scss";

import { CourseContext } from "../../context/CourseContext";
import PosterBanner from "../../components/layouts/PosterBanner";

import iconArrowBack from "../../assets/images/icons/icon-arrow-back.svg";
import iconCalendar from "../../assets/images/icons/icons-calendar.svg";
import iconWarning from "../../assets/images/icons/icons-warning.svg";

function NotificationScreen(props) {
  const history = useHistory();
  const { notiList, getPast } = useContext(CourseContext);

  return (
    <div className="policy notification">
      <PosterBanner />

      <div className="m-container">
        <div className="legal-area commons-card">
          <div className="gallery-back">
            <a href onClick={history.goBack}>
              <img src={iconArrowBack} alt="" />
            </a>
          </div>
          <Card>
            <CardBody>
              <div className="alarm-noti">
                <div className="alarm-header rows justify-content-between">
                  <div>
                    <h5 className="card-title">Thông báo</h5>
                  </div>
                  <Link to={"/setting"} className="is-hot">
                    Cài đặt
                  </Link>
                </div>
                <ul className="alarm-list">
                  {notiList.length
                    ? notiList.map((item, index) => {
                        return (
                          <li className="alarm-item" key={index}>
                            <div className="single-item">
                              <div className="single-item-img">
                                <Link
                                  to={`/course/detail/${item.id_course}/reload`}
                                >
                                  <img
                                    src={item.course_image}
                                    alt={item.course_title}
                                  />
                                </Link>
                              </div>

                              <div className="single-item-content">
                                <h3 className="item-title">
                                  <Link
                                    to={`/course/detail/${item.id_course}/reload`}
                                  >
                                    {item.course_title}
                                  </Link>
                                </h3>
                                <div className="meta-icon">
                                  <div>
                                    <img
                                      className="mr-1"
                                      src={iconCalendar}
                                      alt=""
                                    />
                                    Bắt đầu từ {item.course_time}
                                  </div>
                                  <div className="mt-1">
                                    {getPast(item.created)} trước
                                  </div>
                                </div>
                              </div>
                            </div>

                            {item.is_read === "true" && (
                              <img
                                className="icon-warning"
                                src={iconWarning}
                                alt=""
                              />
                            )}
                          </li>
                        );
                      })
                    : ""}
                </ul>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default NotificationScreen;
