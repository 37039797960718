import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Card, CardBody, CardHeader } from "reactstrap";

import "./SettingScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import SelectList from "../../components/construct/SelectList";

import iconArrowBack from "../../assets/images/icons/icon-arrow-back.svg";

function SettingScreen(props) {
  const history = useHistory();

  const [listSetting, setListSetting] = useState([]);
  const [languages] = useState([
    { id: "1", name: "Tiếng Việt" },
    { id: "2", name: "English" },
  ]);
  const [filter] = useState("1");

  useEffect(() => {
    setListSetting([
      { name: "Nhắc nhở khoá học", active: false },
      { name: "Ai đó yêu thích tác phẩm của bạn", active: false },
      { name: "Thông tin khoá học mới", active: false },
      { name: "Ai đó yêu thích bạn", active: false },
      { name: "Thông tin khuyến mại", active: false },
    ]);
  }, []);

  function handleListSetting(index) {
    let _list = [...listSetting];
    _list[index].active = !_list[index].active;
    setListSetting(_list);
  }

  return (
    <div className="setting policy">
      <PosterBanner />

      <div className="m-container">
        <div className="legal-area commons-card">
          <div className="gallery-back">
            <a href onClick={history.goBack}>
              <img src={iconArrowBack} alt="" />
            </a>
          </div>
          <Card>
            <CardHeader>
              <h5 className="card-title">Cài đặt chung</h5>
            </CardHeader>
            <CardBody>
              <div className="setting-language">
                <h5 className="card-title font-light">Ngôn ngữ</h5>
                <p>Chọn ngôn ngữ mặc định.</p>
                <div className="select-language">
                  <SelectList
                    list={languages}
                    defaultValue={filter}
                    handleOnChange={() => {}}
                    defaultStyle={{
                      width: "100%",
                      backgroundColor: "#ffffff",
                      color: "#242424",
                      border: "1px",
                    }}
                    all={false}
                  />
                </div>
                <h5 className="card-title font-light setting-noti-title">
                  Cài đặt thông báo
                </h5>
                <ul className="list-checkbox">
                  {listSetting.map((item, index) => {
                    return (
                      <li className="checkbox-item" key={index}>
                        <a
                          href
                          className={`checkbox-link rows ${
                            item.active ? "active" : ""
                          }`}
                          title={item.name}
                          onClick={() => handleListSetting(index)}
                        >
                          <span>{item.name}</span>
                          <div className="around-tick">
                            <i className="icofont-check-alt"></i>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default SettingScreen;
