import { useContext } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";

import "./CardVerticalCourse.scss";

import Skeleton from "@mui/material/Skeleton";

function CardVerticalCourse(props) {
  const { openCourseRgsModal } = useContext(CourseContext);
  const { item } = props;

  return item ? (
    <div
      id="card-vertical"
      className="single-item fade-item vertical-item"
      style={{ display: "inline-block" }}
    >
      <div className="single-item-body">
        <Link to={`/course/detail/${item.id}/reload`} title={item.title}>
          <div
            className="single-item-img"
            style={{ backgroundImage: `url("${item.image}")` }}
          >
            <div className="single-item-hover">
              <div className="cart-metalic">
                {item.price === "0" && (
                  <span className="bg-main" title="Mới">
                    Free
                  </span>
                )}
                {item.state_name === "New" && (
                  <span className="bg-free" title="Free">
                    Mới
                  </span>
                )}
                {item.categories_name && (
                  <span className="bg-draw" title={item.categories_name}>
                    {item.categories_name}
                  </span>
                )}
              </div>
              <div className="right-lable">
                {item.is_online === "false" && (
                  <span className="offline-lable" title="Offline">
                    Offline
                  </span>
                )}
              </div>
            </div>
          </div>
        </Link>

        <div className="single-item-content">
          <div className="meta-icon">
            <span>
              <i className="icofont-users"></i> {item.num_sub} đã đăng ký
            </span>
            <span>
              <i className="icofont-calendar"></i> {item.time}
            </span>
          </div>
          <h3 className="item-title">
            <Link to={`/course/detail/${item.id}/reload`} title={item.title}>
              {item.title}
            </Link>
          </h3>
          <p className="item-content">{item.detail}</p>
          <div className="rows justify-content-between">
            <span className="item-stick">{item.discount}</span>
            <p className="item-price">
              Chỉ còn <span className="price-new">{item.price}</span> /Khóa
            </p>
          </div>

          <div className="btn-horizontal">
            <Link
              to={`/course/detail/${item.id}/reload`}
              className="btn btn-detail"
            >
              Chi tiết
            </Link>
            <button
              type="button"
              data-toggle="modal"
              data-target="#item-course-03"
              className="btn btn-regis"
              onClick={() => openCourseRgsModal(item)}
            >
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      id="card-vertical"
      className="single-item vertical-item"
      style={{ display: "inline-block" }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={200}
      />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="h1" />
      <Skeleton animation="wave" width="60%" />
      <div className="row justify-content-between px-3 mt-2 mb-4">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="40%"
          height={40}
        />
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="40%"
          height={40}
        />
      </div>
    </div>
  );
}

export default CardVerticalCourse;
