import React, { useEffect, useState } from "react";
// import Select from "react-select";

import PropTypes from "prop-types";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import "./SelectList.scss";

SelectList.prototype = {
  list: PropTypes.array,
  placeholder: PropTypes.string,
  all: PropTypes.bool,
  defaultStyle: PropTypes.object,
  handleOnChange: PropTypes.func,
};

SelectList.deafaultProps = {
  list: [],
  defaultValue: "",
  all: false,
  defaultStyle: {},
  handleOnChange: () => {},
};

function SelectList(props) {
  const { list, defaultValue, placeholder, defaultStyle, all, handleOnChange } =
    props;

  const [options, setOptions] = useState([]);
  const [deafaultOption, setDefaultOption] = useState("");

  useEffect(() => {
    if (list) {
      if (all)
        setOptions([
          { value: "", name: "Tất cả" },
          ...list.map((item) => {
            return { value: item.id, name: item.name };
          }),
        ]);
      else
        setOptions(
          list.map((item) => {
            return { value: item.id, name: item.name };
          })
        );
    }
  }, [list, all]);

  useEffect(() => {
    setDefaultOption("");
    if (defaultValue) {
      let _value;

      if (defaultValue)
        _value = options.find((item) => {
          return item.value === defaultValue;
        });
      if (!_value) _value = options[0];
      setDefaultOption(_value);
    }
  }, [defaultValue, options]);

  function handleChange(e) {
    let value = options.find((item) => {
      return item.value === e.target.value;
    });
    setDefaultOption(value);
    handleOnChange(value);
  }

  return (
    <div className="sort-select-list">
      <FormControl
        sx={{
          width: "100%",
          height: "40px",
          borderRadius: "5px",
          backgroundColor: " #f1f1f1",
        }}
      >
        <Select
          value={deafaultOption && deafaultOption.value}
          onChange={handleChange}
          inputProps={{ "aria-label": "Without label" }}
          displayEmpty
          sx={defaultStyle}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((item, index) => {
            return (
              <MenuItem
                disabled={
                  !!deafaultOption && deafaultOption.value === item.value
                }
                value={item.value}
                key={index}
                style={
                  deafaultOption && deafaultOption.value === item.value
                    ? { color: "red", opacity: "1" }
                    : {}
                }
              >
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectList;
