import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  Button,
  Collapse,
} from "reactstrap";

import swal from "sweetalert";

import Api from "../../services/api/Api";

import "./CreateComponent.scss";

function CreateDocument(props) {
  const { setTab, course } = props;

  const [listItem, setListItem] = useState([{ open: true }]);

  function openCollapse(index) {
    let _list = [...listItem];
    _list[index].open = !_list[index].open;
    setListItem(_list);
  }
  function addItem() {
    let _list = [...listItem];
    if (!_list[_list.length - 1].created) {
      swal("Oops!", `Vui lòng hoàn thành Document ${_list.length}!`, "error");
    } else {
      _list.push({ open: true });
      setListItem(_list);
    }
  }
  function createItem(event, index) {
    event.preventDefault();
    
    let form =  document.querySelector(`#form-document-${index + 1}`)

    if (!form.checkValidity()) return;

    let formData = new FormData(
     form
    );
    Api.manager
      .document(formData)
      .then((res) => {
        let _list = [...listItem];
        _list[index].created = true;
        setListItem(_list);
      })
      .catch((error) => {
        if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
        else swal("Oops!", "Thất bại!", "error");
      });
  }

  return (
    <div className="create-document create-component">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div className="title">
              Create document <span className="num">(10)</span>
            </div>
            <Button onClick={() => setTab("4")}>Next</Button>
          </div>
        </CardHeader>
        <CardBody>
          {listItem.map((item, index) => {
            return (
              <div className="description-item" key={index}>
                <Button
                  className="description-button"
                  onClick={() => openCollapse(index)}
                >
                  Document {index + 1}
                </Button>
                <Collapse isOpen={listItem[index].open}>
                  <Card>
                    <CardBody>
                      <Form id={`form-document-${index + 1}`} onSubmit={(e) => createItem(e, index)}>
                        <Row>
                          <Col lg={6} className="pr-lg-5">
                            <FormGroup>
                              <Input
                                id="serial"
                                name="serial"
                                type="text"
                                placeholder="Serial"
                                value={index + 1}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                id="course_id"
                                name="course_id"
                                type="text"
                                placeholder="Course Id"
                                value={course && course.id}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                id="name"
                                name="name"
                                type="text"
                                placeholder="Name"
                                required invalid
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="pl-lg-5">
                            <FormGroup>
                              <Label className="mb-4">
                                <span className="red">*</span> File pdf
                              </Label>
                              <div className="input-file">
                                <Input
                                  id="maple-file"
                                  name="maple-file"
                                  type="file"
                                  accept="application/pdf"
                                  required invalid
                                />
                              </div>
                            </FormGroup>
                            <Label>
                              <span className="red">*</span> Is private
                            </Label>
                            <FormGroup check row className="ml-5">
                              <Input
                                name="is_private"
                                type="radio"
                                value={"true"}
                              />
                              <Label check>true</Label>
                            </FormGroup>
                            <FormGroup check row className="ml-5">
                              <Input
                                name="is_private"
                                type="radio"
                                value={"false"}
                                checked
                              />
                              <Label check>false</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        {!item.created && (
                          <Button
                            className="button-done"
                            type="submit"
                          >
                            Done
                          </Button>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            );
          })}

          <div className="add-item">
            <Button onClick={addItem}>+ Add more document</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateDocument;
