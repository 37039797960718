import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import icon from "../../assets/images/icons/icon-camera-white.svg";

ModalDetail.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  handleCloseModal: PropTypes.func,
};
function ModalDetail(props) {
  const { data, isOpen, handleCloseModal } = props;

  var closeButton = (
    <button
      type="button"
      onClick={handleCloseModal}
      className="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleCloseModal}
      fade={true}
      className="modal-detail"
      id="viewDetailPlayer"
      centered
    >
      <ModalHeader close={closeButton}>
        <img src={icon} className="mr-2" alt="" />
        <span>{data.title}</span>
      </ModalHeader>
      <ModalBody>
        <p>{data.body}</p>
      </ModalBody>
    </Modal>
  );
}

export default ModalDetail;
