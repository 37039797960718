import React, { useContext } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";
import Api from "../../services/api/Api";
import regex from "../../services/regex";

import { Modal, ModalBody } from "reactstrap";

import iconUser from "../../assets/images/icons/icons-user.svg";
import iconEmail from "../../assets/images/icons/icons-email.svg";

import poster from "../../assets/images/close-up-student-online-class@2x.png";
import { CourseContext } from "../../context/CourseContext";

RegisterModal.propTypes = {
  none: PropTypes.bool,
};

function RegisterModal(props) {
  const { registerOpen, setRegisterOpen, changeRegisterToLogin } =
    useContext(CourseContext);

  let fullName = React.createRef();
  let phone = React.createRef();
  let email = React.createRef();
  let pass = React.createRef();
  let rePass = React.createRef();
  let agree = React.createRef();

  // Function
  function register(event) {
    event.preventDefault();

    let inputs = [agree, fullName, phone, email, pass, rePass];

    var key = 0;

    inputs.forEach((item, index) => {
      // Check empty
      if (index !== 0 && regex.emptyString(item.current.value)) {
        key = 1;
        item.current.style.borderColor = "red";
        item.current.previousElementSibling.firstChild.style.borderColor =
          "red";
        item.current.onchange = () => {
          item.current.style.borderColor = "";
          item.current.previousElementSibling.firstChild.style.borderColor = "";
        };

        // Check agree
      } else if (index === 0 && !item.current.checked) {
        key = 2;

        let _className = item.current.labels[0].className;
        item.current.labels[0].className = _className + " warning-check";
      }
    });

    switch (key) {
      case 0:
        // Check Phone Number
        if (!regex.validPhoneNumber(phone.current.value)) {
          swal("Oops!", "Số điện thoại không hợp lệ!", "error");

          // Check Email
        } else if (!regex.validEmail(email.current.value)) {
          swal("Oops!", "Địa chỉ email không hợp lệ!", "error");

          // Check repass
        } else if (pass.current.value !== rePass.current.value) {
          swal("Oops!", "Mật khẩu không trùng khớp!", "error");
        } else {
          // Check password

          if (regex.validPassword(pass.current.value)) {
            // Post api
            Api.register({
              phone: phone.current.value,
              password: pass.current.value,
              username: fullName.current.value,
              email: email.current.value,
              date_birth: "01/01/1990",
              address: "Thành phố Hồ Chí Minh, Việt Nam",
              nickname: fullName.current.value,
            })
              .then(function (response) {
                swal({
                  title: "Đăng ký thành công.",
                  text: "Bạn có thể đăng nhập.",
                  icon: "success",
                  timer: 2000,
                });
                changeRegisterToLogin();
              })
              .catch(function (error) {
                if (error.item !== undefined)
                  swal("Oops!", error.item.msg, "error");
                else swal("Oops!", "Đăng ký thất bại!", "error");
              })
              .then(function () {});
          } else {
            swal(
              "Oops!",
              "Password cần trên 8 ký tự, có ít nhất 1 chữ số, chữ in hoa và chữ thường.",
              "error"
            );
          }
        }
        break;
      case 1:
        swal("Oops!", "Điền đầy đủ thông tin!", "error");

        break;
      case 2:
        swal("Oops!", "Kiểm tra các Thỏa thuận điều kiện!", "error");

        break;

      default:
        break;
    }
  }

  function isEyeClick(e) {
    if (e.target.previousElementSibling.type === "password") {
      e.target.previousElementSibling.type = "text";
      e.target.className = "is-eye icofont-eye";
    } else {
      e.target.previousElementSibling.type = "password";
      e.target.className = "is-eye icofont-eye-blocked";
    }
  }

  // Life cycle

  return (
    <Modal isOpen={registerOpen} id="modalRegister" centered>
      <ModalBody>
        <div className="login-container">
          <div className="rows justify-content-between">
            <div className="login-left d-none d-lg-block">
              <img src={poster} alt="" />
            </div>
            <div className="login-right col-12 col-lg-6">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setRegisterOpen(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="login-header">
                <h2 className="form-title">Đăng ký</h2>
                <p>Nhập thông tin đăng ký vào khung bên dưới</p>
              </div>
              <form onSubmit={register} className="login-form">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={iconUser} alt="" />
                    </span>
                  </div>
                  <input
                    ref={fullName}
                    type="text"
                    className="form-control"
                    placeholder="Họ và tên"
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icofont-ui-touch-phone"></i>
                    </span>
                  </div>
                  <input
                    ref={phone}
                    type="text"
                    className="form-control"
                    placeholder="Số điện thoại"
                  />
                </div>

                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <img src={iconEmail} alt="" />
                    </span>
                  </div>
                  <input
                    ref={email}
                    type="email"
                    className="form-control"
                    placeholder="Email"
                  />
                </div>

                <div className="input-group style-eye mb-3 pass-register">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icofont-lock"></i>
                    </span>
                  </div>
                  <input
                    ref={pass}
                    type="password"
                    className="form-control attr-pass"
                    placeholder="Mật khẩu"
                  />

                  <i
                    className="is-eye icofont-eye-blocked"
                    onClick={(e) => {
                      isEyeClick(e);
                    }}
                  ></i>
                </div>

                <div className="input-group style-eye mb-3 re-pass-register">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icofont-lock"></i>
                    </span>
                  </div>
                  <input
                    ref={rePass}
                    type="password"
                    className="form-control attr-pass"
                    placeholder="Xác nhận lại mật khẩu"
                  />

                  <i
                    className="is-eye icofont-eye-blocked"
                    onClick={(e) => {
                      isEyeClick(e);
                    }}
                  ></i>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    ref={agree}
                    className="custom-control-input"
                    id="customCheck6"
                    type="checkbox"
                  />
                  <label
                    className="cursor-pointer d-block custom-control-label"
                    for="customCheck6"
                  >
                    Tôi đồng ý tất cả các{" "}
                    <a
                      href
                      className="forgot-pass"
                      title="Thỏa thuận điều kiện"
                    >
                      Thỏa thuận điều kiện
                    </a>
                  </label>
                </div>
                <div className="login-submit">
                  <button type="submit" className="btn bg-main">
                    Đăng ký
                  </button>
                  <p className="text-center">
                    Bạn đã có tài khoản ?{" "}
                    <a
                      href
                      className="link-register is-hot"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#modalLogin"
                      onClick={changeRegisterToLogin}
                    >
                      {" "}
                      Đăng nhập
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default RegisterModal;
