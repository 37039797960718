import React, { PureComponent } from "react";
import { TabPane } from "reactstrap";

class TabAchievements extends PureComponent {
  render() {
    return (
      <TabPane
        tabId="4"
        id="pills-achievements"
        role="tabpanel"
        aria-labelledby="pills-achievements-tab"
      >
        <div className="no-data"></div>
      </TabPane>
    );
  }
}

TabAchievements.propTypes = {};

export default TabAchievements;
