import React, { useContext, useEffect, useState } from "react";
import Api from "../../services/api/Api";

import { CourseContext } from "../../context/CourseContext";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { TagsInput } from "react-tag-input-component";
import swal from "sweetalert";

import "./UploadGalleryModal.scss";

import SelectList from "../../components/construct/SelectList";

import iconUpload from "../../assets/images/icons/icons-upload.svg";
import iconUploadImg from "../../assets/images/icons/icons-upload-img.svg";

function UploadGalleryModal(props) {
  const { categoriesList } = useContext(CourseContext);

  const { isOpen, closeModal } = props;

  const [imgSrc, setImgSrc] = useState(null);
  const [cate, setCate] = useState("");
  const [tags, setTags] = useState([]);

  let refImg = React.createRef();
  let refTitle = React.createRef();
  let refDes = React.createRef();

  useEffect(() => {
    // setImgSrc(null);
  }, []);

  // Function

  function changeImg(event) {
    let _file = event.target.files;
    if (_file && _file[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImgSrc(e.target.result);
      };

      reader.readAsDataURL(_file[0]);
    }
  }

  function removeUpload() {
    setImgSrc(null);
  }

  function uploadGallery() {
    if (
      imgSrc &&
      refTitle.current.value &&
      refDes.current.value &&
      cate &&
      tags.length
    ) {
      let oData = new FormData();

      oData.append(
        "maple-photo",
        refImg.current.files[0],
        refImg.current.files[0].name
      );
      oData.append("title", refTitle.current.value);
      oData.append("detail", refDes.current.value);
      oData.append("categories_id", cate);
      oData.append("tags", "#" + tags.join(",#"));
      Api.gallery
        .create(oData)
        .then((res) => {
          swal({
            title: "Tải lên thành công.",
            icon: "success",
            timer: 2000,
          });

          closeModal();
        })
        .catch(() => {
          swal("Oops!", "Xảy ra lỗi. Vui lòng thử lại.", "error");
        });
    } else {
      swal("Oops!", "Điền đầy đủ thông tin!", "error");
    }
  }

  return (
    <Modal id="uploadGallery" isOpen={isOpen} backdrop centered>
      <ModalHeader className="bg-main" toggle={closeModal}>
        <div className="rows">
          <img src={iconUpload} alt="" />
          <h5>Tải tác phẩm lên</h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-lg-5">
            <div className="upload-area">
              <div className="upload-layout">
                <div className="wrap-layout">
                  <div
                    className="upload-wrap rows align-items-center"
                    style={{ display: imgSrc ? "none" : "" }}
                  >
                    <input
                      className="file-upload-input"
                      type="file"
                      ref={refImg}
                      onChange={changeImg}
                      accept="image/png, image/jpeg"
                      name="maple-photo"
                    />
                    <div className="drag-text">
                      <img src={iconUploadImg} alt="" />
                      <h3>Nhấn chọn vào để tải ảnh lên</h3>
                      <p>
                        Sử dụng hình ảnh định dạng PNG hoặc JPG chất lượng cao,
                        kích thước dưới 20MB
                      </p>
                    </div>
                  </div>
                  {imgSrc && (
                    <div className="file-upload-content">
                      <img
                        src={imgSrc}
                        className="file-upload-image"
                        alt="Ảnh của bạn"
                      />
                    </div>
                  )}
                  {imgSrc && (
                    <div className="image-title-wrap">
                      <button
                        type="button"
                        onClick={removeUpload}
                        className="btn"
                      >
                        <i className="icofont-close-circled"></i>
                        <span className="image-title">Tải ảnh khác!</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 mt-4 mt-lg-0">
            <div className="upload-form">
              <form action="" method="">
                <label>
                  Tiêu đề <sup>*</sup>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nhập tên tác phẩm"
                    ref={refTitle}
                  />
                </div>
                <label>
                  Chọn chủ đề <sup>*</sup>
                </label>
                <div className="input-group mb-3">
                  <div className="sort-select-list select-upload">
                    <SelectList
                      list={categoriesList}
                      placeholder="Chọn 1 trong những chủ để"
                      defaultValue={false}
                      defaultStyle={{
                        width: "100%",
                        backgroundColor: "#ffffff",
                        color: "#242424",
                        border: "1px",
                      }}
                      handleOnChange={(e) => {
                        setCate(e.value);
                      }}
                    />
                  </div>
                </div>
                <label>Mô tả chi tiết</label>
                <div className="input-group mb-3">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Nội dung"
                    type="textarea"
                    ref={refDes}
                  />
                </div>
                <label>Thêm tag</label>
                <div className="input-group mb-3 tags">
                  <TagsInput
                    value={tags}
                    onChange={setTags}
                    name="Tags"
                    placeHolder="Nhập hashtag của bạn"
                  />
                </div>
                <div className="upload-submit">
                  <button type="button" className="btn bg-border">
                    Lưu bản nháp
                  </button>
                  <button
                    type="button"
                    className="btn bg-main"
                    onClick={uploadGallery}
                  >
                    Đăng
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default UploadGalleryModal;
