import React, { useState } from "react";
import { TabContent, TabPane } from "reactstrap";

import "../manager/ManagerScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import banner from "../../assets/images/blog-banner.jpg";
import CreateNewPost from "./CreateNewPost";
import CreateBodyPost from "./CreateBodyPost";

function CreateBlogScreen(props) {
  const [newPost, setNewPost] = useState(null);

  const [tab, setActiveTab] = useState("1");
  const [reset, setReset] = useState(true);

  function setTab(value) {
    setActiveTab(value);
  }

  function finish() {
    setReset(false);
    setNewPost(null);
    setTab("1");
    setTimeout(() => {
      setReset(true);
    }, 100);
  }

  return (
    <div className="blog-screen home-screen">
      <PosterBanner img={banner} />

      <div className="m-container mt-5">
        <div className="main">
          <div className="right-header me-4">
            <div className="list-step">
              <span className={`step ${tab === "1" && "active"}`}>1</span>
              <span className={`step ${tab === "2" && "active"}`}>2</span>
            </div>
          </div>
          <TabContent activeTab={tab}>
            <TabPane tabId="1">
              {reset && (
                <CreateNewPost
                  post={newPost}
                  setNewPost={setNewPost}
                  setTab={setActiveTab}
                />
              )}
            </TabPane>
            <TabPane tabId="2">
              {reset && newPost && (
                <CreateBodyPost post={newPost} finish={finish} />
              )}
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  );
}

export default CreateBlogScreen;
