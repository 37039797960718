import { useContext } from "react";
import { Link } from "react-router-dom";

import iconBook from "../../assets/images/icons/icon-book.svg";
import iconCheckmarkCircle from "../../assets/images/icons/icon-checkmark-circle.svg";
import { CourseContext } from "../../context/CourseContext";

import "./CardHorizontalCourse.scss";

import Skeleton from "@mui/material/Skeleton";

function CardHorizontalCourse(props) {
  const { openCourseRgsModal } = useContext(CourseContext);

  const { item } = props;

  return item ? (
    <div className="single-item fade-item horizontal-item">
      <div className="single-item-img">
        <Link to={`/course/detail/${item.id}/reload`} title={item.title}>
          <div className="img-layout">
            <div className="img-wrap">
              <img src={item.image} className="thumb-hover" alt={item.title} />
            </div>
          </div>
          <div className="single-item-hover metalic-right">
            <ul className="cart-metalic">
              <li>
                {item.is_online === "true" && (
                  <Link
                    to={`/course/detail/${item.id}/reload`}
                    className="btn bg-blue"
                    title="Online"
                  >
                    Online
                  </Link>
                )}
                {item.is_online === "false" && (
                  <Link
                    to={`/course/detail/${item.id}/reload`}
                    className="btn bg-draw"
                    title="Offline"
                  >
                    Offline
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </Link>
      </div>

      {item.is_online === "true" && (
        <div className="single-item-content">
          <h3 className="item-title">
            <Link to={`/course/detail/${item.id}/reload`} title={item.title}>
              {item.title}{" "}
            </Link>
          </h3>
          <p className="item-content">{item.detail}</p>
          <div className="meta-compress">
            <img src={iconBook} alt="" />
            <span>
              {parseInt(item.percent) > 0 ? "Đã hoàn thành" : "Bắt đầu"}
            </span>
            <span className="progressing"> {item.percent}%</span>
          </div>
          <div className="btn-horizontal">
            <div className="btn btn-registed">
              <img src={iconCheckmarkCircle} alt="" />
              <span>Đã đăng ký</span>
            </div>
            {item.is_open === "false" && (
              <a
                href
                className="btn bg-blue"
                onClick={() => openCourseRgsModal(item)}
              >
                <i className="icofont-lock mr-2"></i>Mở khóa
              </a>
            )}
            {item.is_open === "true" && (
              <Link
                to={`/course/detail/${item.id}/reload`}
                className="btn btn-regis"
              >
                Vào khóa học
              </Link>
            )}
          </div>
        </div>
      )}
      {item.is_online === "false" && (
        <div className="single-item-content">
          <h3 className="item-title">
            <Link to={`/course/detail/${item.id}/reload`} title={item.title}>
              {item.title}{" "}
            </Link>
          </h3>
          <p className="item-content">{item.detail}</p>
          <div className="meta-compress">
            <img src={iconBook} alt="" />
            <span>
              Bắt đầu từ {item.time} - Kết thúc {item.date_end}
            </span>
          </div>
          <div className="btn-horizontal">
            <div className="btn btn-registed">
              <img src={iconCheckmarkCircle} alt="" />
              <span>Đã đăng ký</span>
            </div>
            <Link
              to={`/course/detail/${item.id}/reload`}
              className="btn bg-gray"
            >
              Học tại Studio
            </Link>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="single-item">
      <Skeleton variant="rectangular" width={210} height={118} />
    </div>
  );
}

export default CardHorizontalCourse;
