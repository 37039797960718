import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Pagination.scss"

const NUM_BUTTON = 7;
Pagination.prototype = {
  page: { index: PropTypes.number, max: PropTypes.number },
  handleChangePage: PropTypes.func,
};

function Pagination(props) {
  const { page, handleChangePage } = props;

  const [arrayPag, setArrayPag] = useState([]);
  const [firstPos, setFirstPos] = useState(1);

  useEffect(() => {
    if (page.index === 0) setFirstPos(1);
  }, [page]);

  useEffect(() => {
    if (page.max > 1 && page.max <= NUM_BUTTON) {
      setArrayPag(() =>
        Array(page.max)
          .fill(0)
          .map((item, index) => {
            return { display: index + 1, key: index + 1 };
          })
      );
    } else if (page.max > NUM_BUTTON) {
      if (firstPos <= page.max - 5) {
        setArrayPag(() => [
          ...Array(5)
            .fill(0)
            .map((item, index) => {
              return {
                display: firstPos + index,
                key: firstPos + index,
              };
            }),
          { display: "...", key: "none" },
          { display: page.max, key: page.max },
        ]);
      } else {
        setArrayPag(() => [
          { display: 1, key: 1 },
          { display: "...", key: "none" },
          ...Array(5)
            .fill(0)
            .map((item, index) => {
              return {
                display: page.max - NUM_BUTTON + index + 3,
                key: page.max - NUM_BUTTON + index + 3,
              };
            }),
        ]);
      }
    }
  }, [page, firstPos]);

  // Function

  function handlePageFocus(key) {
    if (key === page.index + 1) return;

    let currentPage = page.index;
    let _first = firstPos;
    switch (key) {
      case "none":
        return;

      case "decrease":
        if (page.index + 1 === firstPos) _first = firstPos - 5;
        if (_first < 1) _first = 1;
        currentPage = page.index - 1;
        break;

      case "increase":
        if (page.index === firstPos + 3) _first = firstPos + 5;
        if (_first > page.max) _first = page.max - 5;
        currentPage = page.index + 1;
        break;

      case page.max:
        _first = page.max - 4;
        currentPage = page.max - 1;
        break;

      case 1:
        if (firstPos === page.max - 4) _first = 1;
        currentPage = 0;
        break;

      default:
        currentPage = key - 1;
        break;
    }

    setFirstPos(_first);
    handleChangePage(currentPage);
  }

  return (
    <nav className="nav-pagination">
      <ul className="pagination pagination-list">
        <li className={`page-item ${page.index === 0 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => {
              handlePageFocus("decrease");
            }}
          >
            <i className="icofont-rounded-left"></i>
          </button>
        </li>
        {arrayPag.map((item, index) => {
          return (
            <li className={`page-item ${item.key === page.index + 1 ? "active" : ""}`} key={index}>
              <button
                className="page-link"
                onClick={() => {
                  handlePageFocus(item.key);
                }}
              >
                {item.display}
              </button>
            </li>
          );
        })}

        <li className={`page-item ${page.index === page.max - 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => {
              handlePageFocus("increase");
            }}
          >
            <i className="icofont-rounded-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
