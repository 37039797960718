import React, { useState, useContext, useEffect } from "react";

import { useLocation, useHistory } from "react-router-dom";
import "./CourseSubScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import SubjectList from "../../components/list-subjects/SubjectList";
import ListCourseSub from "../../components/list-courses/ListCourseSub";
import ListCoursesVertical from "../../components/list-courses/ListCoursesVertical";

import { CourseContext } from "../../context/CourseContext";
import Api from "../../services/api/Api";

function CourseSubScreen(props) {
  const history = useHistory();

  const location = useLocation();

  const { categoriesList, subjectList, relatedList, filterCourse } =
    useContext(CourseContext);

  const [categoriesId, setCategoriesId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [list, setList] = useState(-1);
  const [pageIndex, setPageIndex] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [title, setTitle] = useState("");
  const [filter, setFilter] = useState("");

  // Handle Local change
  useEffect(() => {
    if (location.state && location.state.categoriesId) {
      if (location.state.subjectId) setSubjectId(location.state.subjectId);
      else setSubjectId("");
      setCategoriesId(location.state.categoriesId);
    } else {
      categoriesList[0] && setCategoriesId(categoriesList[0].id);
    }

    setPageIndex(0);
    setMaxPage(0);

    window.scrollTo(0, 0);
  }, [location, categoriesList]);

  // Handle catergoriesId change
  useEffect(() => {
    // if (categoriesId && subjectList.length > 0 && categoriesList.length > 0) {
    //   setSubjectId("");
    //   setTitle(categoriesList.find((item) => item.id === categoriesId).name);
    //   getList("cate", categoriesId, 0, "");
    // } else {
    //   getList("none");
    // }
    // setFilter("");
  }, [categoriesId, subjectList, categoriesList, setCategoriesId]);

  // Handle subjectId change
  useEffect(() => {
    if (subjectId && subjectList.length) {
      let _subject = subjectList.find((item) => item.id === subjectId);
      _subject && setTitle(_subject.name);

      getList("sub", subjectId, 0, "");
    } else if (categoriesId && categoriesList.length > 0) {
      setTitle(categoriesList.find((item) => item.id === categoriesId).name);
      getList("cate", categoriesId, 0, "");
    } else {
      getList("none");
    }
    setFilter("");
  }, [categoriesId, categoriesList, subjectId, subjectList]);

  // function
  function handleSelectList(e) {
    history.replace({ ...history.location, state: { categoriesId: e.value } });
    setSubjectId("");
    setCategoriesId(e.value);
  }

  function handleChangePage(index) {
    if (subjectId) {
      getList("sub", subjectId, index, filter);
    } else {
      getList("cate", categoriesId, index, filter);
    }

    window.scrollTo(
      0,
      document.querySelector(".new-course-area .single-list").offsetTop
    );
  }
  function handleSortPage(event) {
    setFilter(event.value);

    let _filter = { name: "" };

    if (event.value) {
      _filter = filterCourse.find((item) => item.id === event.value);
    }

    if (subjectId) {
      getList("sub", subjectId, 0, _filter.name);
    } else {
      getList("cate", categoriesId, 0, _filter.name);
    }
  }

  function getList(type, id, index, filter) {
    switch (type) {
      case "cate":
        setPageIndex(index);
        Api.course
          .categories(id, index, filter)
          .then((res) => {
            setList(res.item.courses);
            setSubjectId("");
            setMaxPage(res.item.num_page);
          })
          .catch(() => {
            setList([]);
          });

        break;

      case "sub":
        setPageIndex(index);
        Api.course
          .subject(id, index, filter)
          .then((res) => {
            setList(res.item.courses);
            setMaxPage(res.item.num_page);
          })
          .catch(() => {
            setList([]);
          });
        break;

      default:
        setList([]);
        setPageIndex(0);
        setMaxPage(0);
        break;
    }
  }

  return (
    <div className="course-sub-screen">
      <PosterBanner />
      <div className="m-container">
        <SubjectList
          defaultCate={categoriesId}
          defaultSub={subjectId}
          handleChangeSubject={setSubjectId}
          handleSelectList={handleSelectList}
        />
        <ListCourseSub
          list={list}
          filter={filter}
          title={"Khóa học " + title}
          page={{ index: pageIndex, max: maxPage }}
          location={location}
          handleChangePage={handleChangePage}
          handleSortPage={handleSortPage}
        />
        <ListCoursesVertical title="Gợi ý cho bạn" productList={relatedList} />
      </div>
    </div>
  );
}

export default CourseSubScreen;
