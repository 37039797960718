import React, { useContext } from "react";
import PropTypes from "prop-types";

import swal from "sweetalert";

import { Modal, ModalBody } from "reactstrap";
import "./LoginModal.scss";

import regex from "../../services/regex";
import Api from "../../services/api/Api";

import poster from "../../assets/images/close-up-student-online-class@2x.png";
import iconGg from "../../assets/images/icons/icon-logo-google.svg";
import { CourseContext } from "../../context/CourseContext";

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  handleLogin: PropTypes.func,
  changeLoginToRegister: PropTypes.func,
  handleLoginSuccess: PropTypes.func,
};
function LoginModal(props) {
  const {
    loginOpen,
    setLoginOpen,
    setIsLogin,
    setToken,
    changeLoginToRegister,
    handleLoginSuccess,
  } = useContext(CourseContext);

  let refUserName = React.createRef();
  let refPassword = React.createRef();

  // Function
  function login(event) {
    event.preventDefault();

    let userName = refUserName.current.value;
    let password = refPassword.current.value;

    if (regex.emptyString(userName) && regex.emptyString(password)) {
      swal("Oops!", "Nhập email, số điện thoại và mật khẩu!", "error");
      refUserName.current.value = "";
      refPassword.current.value = "";
    } else if (regex.emptyString(userName)) {
      swal("Oops!", "Nhập email, số điện thoại!", "error");
      refUserName.current.value = "";
    } else if (regex.emptyString(password)) {
      swal("Oops!", "Nhập mật khẩu!", "error");
      refPassword.current.value = "";
    } else {
      Api.login({
        phone_email: refUserName.current.value,
        password: password,
      })
        .then(function (response) {
          console.log(response);

          localStorage.setItem("accessToken", response.item.accessToken);
          localStorage.setItem("refreshToken", response.item.refreshToken);

          setToken(response.item.accessToken);
          setIsLogin(true);
          handleLoginSuccess();
        })
        .catch(function (error) {
          swal("Oops!", "Đăng nhập thất bại!", "error");
        })
        .then(function () {});
    }
  }

  function isEyeClick(e) {
    if (e.target.previousElementSibling.type === "password") {
      e.target.previousElementSibling.type = "text";
      e.target.className = "is-eye icofont-eye";
    } else {
      e.target.previousElementSibling.type = "password";
      e.target.className = "is-eye icofont-eye-blocked";
    }
  }

  return (
    <Modal isOpen={loginOpen} id="modalLogin" centered>
      <ModalBody>
        <div className="login-container">
          <div className="rows justify-content-between">
            <div className="login-left d-none d-lg-block">
              <img src={poster} alt="" />
            </div>
            <div className="login-right col-12 col-lg-6">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setLoginOpen(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="login-header">
                <h2 className="form-title">Đăng nhập</h2>
                <p>Nhập thông tin đăng nhập vào khung bên dưới</p>
              </div>
              <form onSubmit={login} action={() => {}} className="login-form">
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icofont-user"></i>
                    </span>
                  </div>
                  <input
                    ref={refUserName}
                    type="text"
                    className="form-control"
                    placeholder="Email, Số điện thoại"
                  />
                </div>

                <div className="input-group style-eye mb-3">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icofont-lock"></i>
                    </span>
                  </div>
                  <input
                    ref={refPassword}
                    type="password"
                    className="form-control attr-pass"
                    placeholder="Mật khẩu"
                  />

                  <i
                    className="is-eye icofont-eye-blocked"
                    onClick={(e) => {
                      isEyeClick(e);
                    }}
                  ></i>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group d-flex mb-3 ">
                    <input
                      className="input-check"
                      id="is-stick"
                      type="checkbox"
                    />
                    <label className="lab-check" for="is-stick">
                      <span>
                        <svg width="12px" height="10px" viewbox="0 0 12 10">
                          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                        </svg>
                      </span>
                      <span>Ghi nhớ đăng nhập</span>
                    </label>
                  </div>
                  <div className="form-group d-flex mb-3">
                    <a href className="forgot-pass">
                      Quên mật khẩu ?
                    </a>
                  </div>
                </div>
                <div className="login-submit">
                  <button type="submit" className="btn bg-main">
                    Đăng nhập
                  </button>
                  <div className="login-or">
                    <hr className="hr-or" />
                    <span className="span-or">Hoặc</span>
                  </div>
                  <a
                    href
                    className="btn btn-google"
                    title="Đăng nhập với Google"
                  >
                    <img src={iconGg} className="mr-2" alt="" /> Đăng nhập với
                    Google
                  </a>
                  <p className="text-center">
                    Bạn đã có tài khoản chưa ?{" "}
                    <a
                      href
                      className="link-register is-hot"
                      data-toggle="modal"
                      data-dismiss="modal"
                      data-target="#modalRegister"
                      onClick={changeLoginToRegister}
                    >
                      {" "}
                      Đăng ký
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

LoginModal.propTypes = {};

export default LoginModal;
