import React, { useEffect, useContext, useState } from "react";

import PosterBanner from "../../components/layouts/PosterBanner";
import SelectList from "../../components/construct/SelectList";
import { CourseContext } from "../../context/CourseContext";

import "./BlogScreen.scss";

import banner from "../../assets/images/blog-banner.jpg";
import Api from "../../services/api/Api";
import CardVerticalBlog from "../../components/cards/CardVerticalBlog";

function BlogScreen(props) {
  const { categoriesList } = useContext(CourseContext);
  const [listItem, setListItem] = useState([]);
  const [filter, setFilter] = useState("");
  const [list, setList] = useState([]);

  useEffect(() => {
    Api.blog.getList().then((res) => {
      setListItem(res.item.posts);
    });
  }, []);

  useEffect(() => {
    if (filter) {
      setList(listItem.filter((item) => item.categories_id === filter));
    } else {
      setList(listItem);
    }
  }, [listItem, filter]);

  function sortList(event) {
    setFilter(event.value);
  }

  return (
    <div className="blog-screen home-screen">
      <PosterBanner img={banner} />
      <div className="content-header row">
        <div className="title col-sm-6">Mapleskills's blog</div>
        <div className="col-right col-sm-6 mt-3">
          <SelectList
            list={categoriesList}
            handleOnChange={sortList}
            all={true}
          />
        </div>
      </div>

      <div className="m-container">
        <div className="list-course-sub list-courses-vertical new-course-area">
          <div className="list-item single-list rows">
            {list.length === 0 && <div className="no-data"></div>}
            {list.map((item) => {
              return (
                <div className="single-box">
                  <CardVerticalBlog item={item} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogScreen;
