import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import iconDropdown from "../../assets/images/icons/icon-material-more-horiz.svg";
import Api from "../../services/api/Api";

import "./CardGallery.scss";

import Skeleton from "@mui/material/Skeleton";
import LazyLoad from "react-lazyload";

CardGallery.prototype = {
  item: PropTypes.object,
};

function CardGallery(props) {
  const { item, isActive, setActive } = props;

  const [isLike, setIslike] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    setIslike(item.is_like || false);
  }, [item]);

  function likeGallery() {
    !isLike &&
      Api.gallery.like(item.id).then((res) => {
        setIslike(true);
      });
  }

  return (
    <div className={`gallery-item ${isActive && "active"}`}>
      {item ? (
        <div className="content">
          <Link to={`/gallery/detail?id=${item.id}&u=${item.user_id}`}>
            {!imgLoaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                style={{
                  position: "absolute",
                  top: 0,
                  zIndex: "-1",
                  borderRadius: "20px",
                }}
              />
            )}
            <LazyLoad height={250}>
              <img
                className="gallery-thumb"
                src={item.image}
                alt=""
                onLoad={() => {
                  setImgLoaded(true);
                }}
              />
            </LazyLoad>
          </Link>
          <div className="gallery-hover">
            <a
              href
              className={`tog-favorite ${isLike && "active"}`}
              onClick={likeGallery}
            >
              {}
            </a>
            <a href className="tog-more" onClick={setActive}>
              <img src={iconDropdown} alt="" />
            </a>
          </div>
          <h3 className="item-title pl-2">{item.title}</h3>
        </div>
      ) : (
        <div className="no-content">
          <Skeleton variant="rectangular" width="100%" height={250} />
          <Skeleton width="60%" />
        </div>
      )}
      {item ? (
        <div className="gallery-link">
          <Link to={`/author/${item.user_id}/reload`}>Xem tác giả</Link>
          <a href={item.download}>Tải xuống</a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CardGallery;
