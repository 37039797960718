import React, { useState } from "react";
import PropTypes from "prop-types";

import Masonry from "@mui/lab/Masonry";

import CardGallery from "../cards/CardGallery";

import "./ListGallery.scss";

ListGallery.propTypes = {
  list: PropTypes.array,
};
function ListGallery(props) {
  const { list } = props;
  const [indexActive, setIndexActive] = useState(-1);

  function setActive(index) {
    if (index === indexActive) {
      setIndexActive(-1);
    } else {
      setIndexActive(index);
    }
  }

  return (
    <div className="list-gallery">
      <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }} spacing={2}>
        {list.length
          ? list.map((item, index) => {
              return (
                <CardGallery
                  key={index}
                  item={item}
                  isActive={indexActive === index}
                  setActive={() => {
                    setActive(index);
                  }}
                />
              );
            })
          : Array.from(new Array(12)).map((item, index) => {
              return (
                <CardGallery
                  key={index}
                  item={0}
                  isActive={indexActive === index}
                  setActive={() => {
                    setActive(index);
                  }}
                />
              );
            })}
      </Masonry>
    </div>
  );
}

export default ListGallery;
