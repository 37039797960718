import React, { useContext, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { CourseContext } from "../../context/CourseContext";

import "./ListCourseSub.scss";
import btnViewMore from "../../assets/images/icons/next-more.svg";

import Pagination from "../construct/Pagination";
import CardVerticalCourse from "../cards/CardVerticalCourse";
import SelectList from "../construct/SelectList";

ListCourseSub.prototype = {
  list: PropTypes.array,
  filter: PropTypes.string,
  title: PropTypes.string,
  page: PropTypes.object,
  location: PropTypes.object,
};

function ListCourseSub(props) {
  const { filterCourse } = useContext(CourseContext);

  const {
    list,
    filter,
    title,
    page,
    location,
    handleChangePage,
    handleSortPage,
  } = props;

  const [courseList, setCourseList] = useState(-1);
  const [viewMore, setViewMore] = useState(true);
  // const [filter, setFilter] = useState("0");

  useEffect(() => {
    setViewMore(true);
  }, [location]);

  useEffect(() => {
    if (list !== -1) {
      if (page.index === 0 && viewMore) {
        if (list.length > 0 && list.length < 12) setViewMore(false);
        setCourseList(list.slice(0, 12));
      } else {
        setCourseList(list);
      }
    }
  }, [list, page, viewMore]);

  // Function
  function handleViewMore() {
    setCourseList(list);
    setViewMore(false);
  }

  function changePage(value) {
    setCourseList([]);
    handleChangePage(value);
  }

  function sortPage(value) {
    setCourseList([]);
    handleSortPage(value);
  }

  return (
    <div className="list-course-sub  new-course-area">
      <div className="row article-title justify-content-between">
        <div className="col-6">
          <h2 className="section-title">{title}</h2>
        </div>
        {handleSortPage && (
          <div className="col-6 col-right">
            <SelectList
              list={filterCourse}
              defaultValue={filter}
              handleOnChange={sortPage}
              all={true}
            />
          </div>
        )}
      </div>

      <div className="single-list rows">
        {courseList === -1
          ? Array.from(new Array(12)).map((item, index) => {
              return (
                <div className="single-box" key={index}>
                  <CardVerticalCourse item={item} />
                </div>
              );
            })
          : courseList.map((item, index) => {
              return (
                <div className="single-box" key={index}>
                  <CardVerticalCourse item={item} />
                </div>
              );
            })}

        {courseList.length === 0 && <div className="no-data"></div>}
      </div>
      {viewMore && list.length > 12 && (
        <div className="btn-area text-center">
          <button href className="btn btn-more" onClick={handleViewMore}>
            <span>Xem thêm khóa học khác</span>
            <img src={btnViewMore} alt="" />
          </button>
        </div>
      )}
      {!viewMore && page.max > 1 && (
        <Pagination
          page={{ ...page, first: 1 }}
          handleChangePage={changePage}
        />
      )}
    </div>
  );
}

export default ListCourseSub;
