import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../services/api/Api";

import { TabContent, Nav, NavItem, NavLink, TabPane } from "reactstrap";

import "./AuthorScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import AuthorBanner from "./AuthorBanner";
import ListGallery from "../../components/list-gallery/ListGallery";
import SelectList from "../../components/construct/SelectList";

function AuthorScreen(props) {
  const { id } = useParams();

  const [data, setData] = useState(null);
  const [activeTab, setActiveTab] = useState("1");

  const options = [
    { id: "1", name: "Tuần này" },
    { id: "2", name: "Tháng này" },
  ];

  // get profile
  useEffect(() => {
    Api.gallery
      .author(id, 0)
      .then(function (response) {
        setData({ ...response.item });
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  function handleSortPage() {}

  return (
    <div className="author">
      <PosterBanner />

      {data && (
        <div className="m-container">
          <AuthorBanner info={data.author} />
          <div className="tabs-profile tabs-area">
            <div className="rows article-title justify-content-between">
              <div className="d-inline-block">
                <div className="tabs-link">
                  <Nav className="nav-pills" id="gallery-tab">
                    <NavItem>
                      <NavLink
                        active={activeTab === "1"}
                        id="pills-library-tab"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-library"
                        onClick={() => {
                          setActiveTab("1");
                        }}
                      >
                        Thư viện
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <div className="d-inline-block">
                <SelectList
                  list={options}
                  handleOnChange={handleSortPage}
                  all={true}
                />
              </div>
            </div>
            <TabContent
              activeTab={activeTab}
              className="commons-card"
              id="pills-tabContent"
            >
              <TabPane
                tabId="1"
                id="gallery-new"
                className="tab-gallery"
                role="tabpanel"
                aria-labelledby="gallery-new-tab"
              >
                <ListGallery list={data.gallery} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthorScreen;
