import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

// import "./ServiceDetailScreen.scss";

import CommonsCard from "./CommonsCard";
import DetailTabs from "./DetailTabs";
import RelatedViewMore from "../course-detail/RelatedViewMore";
import ModalDetail from "../course-detail/ModalDetail";

import Api from "../../services/api/Api";
import { CourseContext } from "../../context/CourseContext";
import swal from "sweetalert";

function ServiceDetailScreen(props) {
  const { relatedList, courseRegistered, subjectList, categoriesList } =
    useContext(CourseContext);

  const { id } = useParams();

  const [detail, setDetail] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ title: "", body: "" });

  useEffect(() => {
    if (id) {
      Api.service
        .getDetail(id)
        .then((res) => {
          setDetail({ ...res.item });
        })
        .catch((error) => {
          swal("Oops!", error.item, "error");
        });
    }

    window.scrollTo(0, 0);
  }, [id, courseRegistered, subjectList, categoriesList]);

  // function

  function handleOpenModal(index) {
    setModalData({
      title: detail.sessions[index].title,
      body: detail.sessions[index].description,
    });
    setModalOpen(true);
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  return (
    <div className="course-detail">
      <div className="m-container">
        <CommonsCard
          service={detail.service || {}}
          sessions={detail.sessions || []}
          handleOpenModal={handleOpenModal}
        />
        <DetailTabs descriptions={detail.descriptions || []} />
        <RelatedViewMore title={"Có thể bạn thích"} list={relatedList || []} />
        <ModalDetail
          isOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          data={modalData}
        />
      </div>
    </div>
  );
}

export default ServiceDetailScreen;
