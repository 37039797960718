import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { CourseContext } from "../../context/CourseContext";

import Skeleton from "@mui/material/Skeleton";

function HomeSlider(props) {
  const { openCourseRgsModal } = useContext(CourseContext);

  const { list, banners } = props;

  const [sliderFor, setSliderFor] = useState(null);
  const [sliderNav, setSliderNav] = useState(null);

  return (
    <div className="slider-area bg-shadow">
      <Slider
        className="slider slider-for"
        asNavFor={sliderNav}
        ref={(slider) => setSliderFor(slider)}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        fade={true}
      >
        {banners === -1 && (
          <div>
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={170}
            />
            <Skeleton animation="wave" width="200%" height={50} />
            <Skeleton animation="wave" width="200%" height={50} />

            <Skeleton animation="wave" width="60%" height={50} />
          </div>
        )}
        {banners.length &&
          list.map((item, index) => {
            return (
              <div className="slider-item slickslider" key={index}>
                <div
                  className="poster-item"
                  style={{
                    backgroundImage: `url("${
                      banners[index] && banners[index].poster
                    }")`,
                  }}
                >
                  <div className="slick-caption">
                    <div className="slide-content">
                      <h2>{item.title}</h2>
                      <div className="meta-slide">
                        <p>
                          <i className="icofont-building"></i> Học{" "}
                          {item.is_online === "true" ? "online" : "offline"} /{" "}
                          {item.discount} chỉ còn <span>{item.price}</span> /
                          Khoá
                        </p>
                      </div>
                      <div className="btn-horizontal">
                        <div
                          className="btn btn-regis"
                          onClick={() => openCourseRgsModal(item)}
                        >
                          Đăng ký ngay
                        </div>
                        <Link
                          to={`/course/detail/${item.id}/reload`}
                          className="btn btn-detail"
                        >
                          Xem Chi tiết
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>

      <Slider
        className="thumbnail-img slider slider-nav"
        asNavFor={sliderFor}
        ref={(slider) => setSliderNav(slider)}
        slidesToShow={4}
        slidesToScroll={1}
        dots={false}
        centerMode={false}
        focusOnSelect={true}
        prevArrow={
          <button type="button" className="slick-prev slick-arrow">
            <i className="icofont-arrow-left"></i>
          </button>
        }
        nextArrow={
          <button type="button" className="slick-next slick-arrow">
            <i className="icofont-arrow-right"></i>
          </button>
        }
      >
        {banners.length &&
          list.map((item, index) => {
            return (
              <div className="thumb-item" key={index}>
                <div className="thumb-layout">
                  <img
                    src={banners[index] && banners[index].thumbnail}
                    alt=""
                  />
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
}

export default HomeSlider;
