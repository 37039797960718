import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { CourseContext } from "../../context/CourseContext";

function MenuServiceModal(props) {
  const { services } = useContext(CourseContext);
  const { isOpen, closeModal } = props;

  return (
    <Modal id="menu-course-modal" isOpen={isOpen} toggle={closeModal}>
      <ModalHeader toggle={closeModal}></ModalHeader>
      <ModalBody>
        <ul className="submenu">
          {services.map((item, index) => {
            return (
              <li className="submenu-item" key={index}>
                <Link
                  to={`service/` + item.id}
                  className="submenu-link"
                  title={item.short_title}
                  onClick={closeModal}
                >
                  {item.short_title}
                </Link>
              </li>
            );
          })}
        </ul>
      </ModalBody>
    </Modal>
  );
}

export default MenuServiceModal;
