/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import Marquee from "react-fast-marquee";

import "./BandrollFooter.scss";
import iconLeft from "../../assets/images/icons/icon-chevrons-left.svg";

function BandrollFooter(props) {
  const text = "GIẢM NGAY 300K KHI ĐĂNG KÝ ONLINE CHO MỌI KHOÁ HỌC";
  return (
    <div className="bandroll-footer">
      <Marquee gradient={false}>
        {Array.from(new Array(4)).map((el, i) => {
          return (
            <div className="animate-item" key={i}>
              <span className="left-arrow">
                <img src={iconLeft} alt="" />
              </span>{" "}
              {text}
            </div>
          );
        })}
      </Marquee>
    </div>
  );
}

export default BandrollFooter;
