import React, { useState, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";
import "./SearchScreen.scss";

import ListCourseSub from "../../components/list-courses/ListCourseSub";
import ListCoursesVertical from "../../components/list-courses/ListCoursesVertical";

import { CourseContext } from "../../context/CourseContext";
import Api from "../../services/api/Api";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SearchScreen(props) {
  const location = useLocation();
  const query = useQuery();
  const key = query.get("key");

  const { relatedList } = useContext(CourseContext);

  const [list, setList] = useState([]);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    setShowResult(false);
    setList([]);
    let _key = key;
    Api.search.get(_key).then((res) => {
      if (_key === key) {
        setList(res.item.courses);
        setShowResult(true);
      }
    });
    window.scroll(0, 0);
  }, [key]);

  return (
    <div className="search-screen">
      <div className="result-header">
        <p>
          {list.length
            ? `${list.length} Kết quả cho “${key}”`
            : showResult
            ? `Chúng tôi không tìm thấy kết quả cho “${key}”`
            : undefined}
        </p>
      </div>
      <div className="m-container">
        {list.length ? (
          <ListCourseSub
            list={list}
            title={""}
            page={{ index: 0, max: 0 }}
            location={location}
            handleChangePage={false}
            handleSortPage={false}
          />
        ) : undefined}
        <ListCoursesVertical title="Gợi ý cho bạn" productList={relatedList} />
      </div>
    </div>
  );
}

export default SearchScreen;
