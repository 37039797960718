import React from "react";
import { useContext } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  Button,
} from "reactstrap";
import swal from "sweetalert";
import { CourseContext } from "../../context/CourseContext";

import Api from "../../services/api/Api";

import "../manager/CreateComponent.scss";

function CreateNewPost(props) {
  const { categoriesList } = useContext(CourseContext);
  const { setNewPost, setTab, post } = props;

  function createPost(event) {
    event.preventDefault();

    if (post) {
      setTab("2");
      return;
    }

    let form = document.querySelector("#form-course");

    if (form.checkValidity()) {
      let formData = new FormData(form);

      Api.blog
        .createNewPost(formData)
        .then((res) => {
          setNewPost(res.item.post);
          setTab("2");
        })
        .catch((error) => {
          if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
          else swal("Oops!", "Thất bại!", "error");
        });
    }
  }

  return (
    <div className="create-component create-course">
      <Form id="form-course" onSubmit={createPost}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <div className="title">Create new post</div>
              <Button type="submit">Next</Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={6} className="pr-lg-4">
                <FormGroup row>
                  <Label for="categories_id" sm={4}>
                    <span className="red">*</span> Categories
                  </Label>
                  <Col sm={8}>
                    <Input
                      id="categories_id"
                      name="categories_id"
                      type="select"
                    >
                      {categoriesList.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label for="title" sm={4}>
                    <span className="red">*</span> Title
                  </Label>
                  <Col sm={8}>
                    <Input
                      id="title"
                      name="title"
                      type="text"
                      required
                      invalid
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col lg={6} className="pl-lg-4">
                <FormGroup row>
                  <Label for="detail" sm={4}>
                    <span className="red">*</span> Detail
                  </Label>
                  <Col sm={8}>
                    <Input
                      id="detail"
                      name="detail"
                      type="textarea"
                      required
                      invalid
                      style={{ height: "125px" }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="image" sm={4}>
                    <span>+</span> Image
                  </Label>
                  <Col sm={8}>
                    <div className="input-file">
                      <Input
                        id="image"
                        name="cover_image"
                        type="file"
                        accept="image/jpeg, image/png"
                        required
                        invalid
                      />
                    </div>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default CreateNewPost;
