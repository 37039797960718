module.exports = {
  emptyString(str) {
    return /^\s*$/.test(str);
  },
  validEmail(str) {
    return /^[a-z]\w*(\.\w+)*@[a-z]+(\.[a-z]+)+$/.test(str);
  },
  validPhoneNumber(str) {
    return /^[0-9]{10}$/.test(str);
  },
  validPassword(str) {
    return (
      this.existNumber &&
      !this.isLowerCase(str) &&
      !this.isUpperCase(str) &&
      str.length >= 8
    );
  },
  isLowerCase(str) {
    return /^[^A-Z]+$/.test(str);
  },
  isUpperCase(str) {
    return /^[^a-z]+$/.test(str);
  },
  existNumber(str) {
    return /[^^\D]+/.test(str);
  },
};
