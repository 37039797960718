import React, { useContext, useEffect, useState } from "react";

import { Card, CardBody, Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { CourseContext } from "../../context/CourseContext";
import "./CommonsCard.scss";

import iconBook from "../../assets/images/icons/icons-book-alt.svg";

import Hls from "hls.js";

CommonsCard.prototype = {
  course: PropTypes.object,
  sessions: PropTypes.array,
  handleOpenModal: PropTypes.func,
  registered: PropTypes.bool,
};

function CommonsCard(props) {
  const { openCourseRgsModal } = useContext(CourseContext);
  const { course, sessions, handleOpenModal, registered } = props;

  const [arrIsOpen, setArrIsOpen] = useState([]);

  const [videoIndex, setVideoIndex] = useState(0);

  useEffect(() => {
    setArrIsOpen(
      sessions.map((item) => {
        return false;
      })
    );
  }, [sessions]);

  useEffect(() => {
    if (sessions[videoIndex] && sessions[videoIndex].is_private === "false") {
      let video = document.getElementById("my-player");
      let hls = new Hls();
      hls.attachMedia(video);
      hls.loadSource(sessions[videoIndex].url);
      return async () => {
        await hls.destroy();
      };
    }
  }, [videoIndex, sessions]);

  function handleToggle(index) {
    let arr = [...arrIsOpen];
    arr[index] = !arr[index];
    setArrIsOpen(arr);
  }

  function openSession(index) {
    setVideoIndex(index + 1);
    !arrIsOpen[index] && handleToggle(index);
  }

  return (
    <div className="commons-card">
      <div className="card">
        <div className="card-header justify-content-between">
          <div className="col-xl-8">
            <div className="article-header-left">
              <div className="article-header-thumb d-none d-sm-block">
                <img src={iconBook} alt="" />
              </div>
              <div className="article-header-content">
                <h3 className="card-title">{course.title}</h3>
                <p className="line-clamp-1">{course.detail}</p>
              </div>
            </div>
            <div className="rows article-metics justify-content-between">
              <div className="meta-icon">
                <a href>
                  <i className="icofont-audio"></i>{" "}
                  {course.language === "vi"
                    ? "Tiếng Việt"
                    : course.language === "en"
                    ? "Tiếng Anh"
                    : course.language}
                </a>
                <span>
                  <i className="icofont-users"></i> {course.num_sub} đã đăng ký
                </span>
                <span>
                  <i className="icofont-calendar"></i> {course.time}
                </span>
              </div>

              <span className="is-hot">
                {course.discount && `Đang ${course.discount}`}
                {course.state_name === "Hot" ? " ~ Đăng ký nhiều nhất !" : ""}
              </span>
            </div>
          </div>
          <div className="col-xl-4">
            {!registered && (
              <div className="article-register">
                <span className="item-stick">{course.discount}</span>
                <div className="item-price">
                  Chỉ còn <span className="price-new">{course.price}</span>{" "}
                  /Khóa
                </div>
                <div className="btn-horizontal">
                  <a
                    href
                    className="btn btn-regis"
                    onClick={() => openCourseRgsModal(course)}
                  >
                    Đăng ký ngay
                  </a>
                </div>
              </div>
            )}
            {registered && (
              <div className="article-register">
                <div className="item-price">
                  Đã hoàn thành{" "}
                  <span className="price-new is-complete">
                    {course.percent}%
                  </span>
                </div>
                <div className="btn-horizontal">
                  <a href className="btn bg-draw">
                    Đã đăng ký
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          <div className="row justify-content-between">
            <div className="col-xl-8">
              <div className="player-area">
                <div className="player-layer">
                  <div className="video-player">
                    <video
                      poster={course.image}
                      width="100%"
                      height="100%"
                      autoPlay
                      controls
                      muted={!videoIndex}
                      id="my-player"
                    ></video>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="player-list">
                <div className="accordion" id="accordionPlayer">
                  {sessions
                    .filter((item, index) => index >= 0)
                    .map((item, index) => {
                      return (
                        <div className="card" key={index}>
                          <div className="card-header" id={`heading-${index}`}>
                            <div
                              className={`content-item rotate ${
                                arrIsOpen[index] && "active"
                              }`}
                              data-toggle="collapse"
                              data-target={`#collapse-${index}`}
                            >
                              {item.is_private === "true" && (
                                <i className="icofont-lock has-lock"></i>
                              )}
                              <button
                                type="button"
                                className={
                                  item.is_private === "false"
                                    ? "btn btn-link has-camera"
                                    : "btn btn-link"
                                }
                                onClick={() => openSession(index)}
                              >
                                <p className="line-clamp-2">{item.title}</p>
                              </button>
                              <i
                                className="icofont-rounded-down"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleToggle(index);
                                }}
                              ></i>
                            </div>
                          </div>
                          <Collapse
                            isOpen={arrIsOpen[index]}
                            id={`collapse-${index}`}
                            className="collapse"
                          >
                            <Card>
                              <CardBody>
                                <p>{item.description.split("\n")[0]}</p>
                                {item.is_private === "false" && (
                                  <button
                                    type="button"
                                    className="btn"
                                    data-toggle="modal"
                                    data-target="#viewDetailPlayer"
                                    onClick={() => {
                                      handleOpenModal(index + 1);
                                    }}
                                  >
                                    Xem thêm
                                  </button>
                                )}
                              </CardBody>
                            </Card>
                          </Collapse>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonsCard;
