import React from "react";
import { Col, Row, Button } from "reactstrap";

import "./DashboardHome.scss";
import iconFolder from "../../assets/images/icons/icon-folder.svg";
import iconDescription from "../../assets/images/icons/icon-description.svg";
import iconDocument from "../../assets/images/icons/icon-document.svg";
import iconBook from "../../assets/images/icons/icon-book-create.svg";
import iconSession from "../../assets/images/icons/icon-session.svg";

import imgDescription from "../../assets/images/create-description.png";
import imgDocument from "../../assets/images/create-document.png";
import imgExercise from "../../assets/images/create-exercise.png";
import imgSession from "../../assets/images/create-session.png";

function DashboardHome(props) {
  const { setTab } = props;
  return (
    <div className="dashboard">
      <Row>
        <Col md="8">
          <Button className="create-course" onClick={() => setTab("1")}>
            <div className="horizontal d-flex">
              <div className="text">
                <p className="title">Create course</p>
                <p className="number">10</p>
              </div>
              <div className="icon">
                <img src={iconFolder} alt="" srcset="" />
              </div>
            </div>
          </Button>
          <Row>
            <Col md="6">
              <Button
                className="create-description"
                onClick={() => setTab("2")}
              >
                <p className="title">Create Description</p>
                <p className="number">10</p>
                <div className="icon">
                  <img src={iconDescription} alt="" srcset="" />
                </div>
                <div className="image">
                  <img src={imgDescription} alt="" srcset="" />
                </div>
              </Button>
            </Col>
            <Col md="6">
              <Button className="create-document" onClick={() => setTab("3")}>
                <div className="horizontal d-flex">
                  <div className="text">
                    <p className="title">Create Document</p>
                    <p className="number">10</p>
                  </div>
                  <div className="icon">
                    <img src={iconDocument} alt="" />
                  </div>
                </div>
                <div className="image">
                  <img src={imgDocument} alt="" srcset="" />
                </div>
              </Button>
              <Button className="create-exercise" onClick={() => setTab("4")}>
                <div className="horizontal d-flex">
                  <div className="text">
                    <p className="title">Create exercise</p>
                    <p className="number">10</p>
                  </div>
                  <div className="icon">
                    <img src={iconBook} alt="" />
                  </div>
                </div>
                <div className="image">
                  <img src={imgExercise} alt="" srcset="" />
                </div>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Button className="create-session" onClick={() => setTab("5")}>
            <p className="title">Create Session</p>
            <p className="number">10</p>
            <div className="icon">
              <img src={iconSession} alt="" srcset="" />
            </div>
            <div className="image">
              <img src={imgSession} alt="" />
            </div>
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardHome;
