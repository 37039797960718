import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Api from "../../services/api/Api";

import "./SearchModal.scss";

function SearchModal(props) {
  const history = useHistory();
  const {
    isOpen,
    closeModal,
    listSearch,
    setListSearch,
    searchKey,
    setSearchKey,
  } = props;

  const [searchIndex, setSearchIndex] = useState(-1);
  const [searchTimeOut, setSearchTimeOut] = useState(null);

  // function

  function searchComplete(event) {
    let key = event.target.value;
    clearTimeout(searchTimeOut);
    if (key) {
      setSearchKey(key);
      setSearchTimeOut(
        setTimeout(() => {
          Api.search.complete(key).then((res) => {
            setListSearch(res.item.auto);
          });
        }, 500)
      );
    } else {
      setSearchKey("");
      setListSearch([]);
    }
  }

  function searchKeyDown(event) {
    switch (event.keyCode) {
      case 37:
      case 39:
        break;

      case 38:
        if (listSearch.length) {
          if (searchIndex >= 0) {
            setSearchIndex(searchIndex - 1);
          } else {
            setSearchIndex(listSearch.length - 1);
          }
        }
        break;

      case 40:
        if (listSearch.length) {
          if (searchIndex < listSearch.length - 1) {
            setSearchIndex(searchIndex + 1);
          } else {
            setSearchIndex(-1);
          }
        }
        break;

      case 13:
        if (searchKey) {
          if (searchIndex < 0) {
            routeSearch(searchKey);
          } else {
            routeSearch(listSearch[searchIndex].title);
          }
        }
        break;

      default:
        setSearchIndex(-1);
        break;
    }
  }

  function routeSearch(key) {
    if (key !== searchKey) {
      setSearchKey(key);
      Api.search.complete(key).then((res) => {
        setListSearch(res.item.auto);
      });
    }
    history.push(`/search?key=${key}`);
    closeModal();
  }

  return (
    <Modal isOpen={isOpen} id="search-modal">
      <ModalHeader toggle={closeModal}>
        <div className="search-area">
          <div className="form-search rows">
            <button
              type="button"
              className="btn-search"
              onClick={() => routeSearch(searchKey)}
            >
              <i className="icofont-search-1"></i>
            </button>
            <input
              type="search"
              id="search"
              className="input-text"
              placeholder="Tìm kiếm khóa học"
              value={searchKey}
              onChange={searchComplete}
              onKeyDown={searchKeyDown}
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        {listSearch.length ? (
          <div className="auto-complete">
            <div className="sub-complete">
              <ul>
                {listSearch.map((item, index) => {
                  return (
                    <li
                      key={index}
                      title={item.title}
                      className={searchIndex === index ? "active" : undefined}
                      onMouseOver={() => {
                        setSearchIndex(index);
                      }}
                      onMouseLeave={() => {
                        setSearchIndex(-1);
                      }}
                      onClick={() => routeSearch(item.title)}
                    >
                      <div className="line-clamp-1">{item.title}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </ModalBody>
    </Modal>
  );
}

export default SearchModal;
