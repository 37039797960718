import React, { useEffect, useState } from "react";

import avatar from "../../assets/images/avatar.png";
import iconCheckmark from "../../assets/images/icons/icons-ios-checkmark-circle.svg";
import iconFeatherHeart from "../../assets/images/icons/icon-feather-heart.svg";

import "./AuthorBanner.scss";

function AuthorBanner(props) {
  const { info } = props;
  const [userLocation, setUserLocation] = useState("");

  useEffect(() => {
    let arr = info.address.split(",");
    setUserLocation(
      arr.filter((item, index) => index >= arr.length - 2 && item).join(",")
    );
  }, [info]);

  return (
    <div className="banner-author">
      <div className="media">
        <div className="media-user">
          <img className="avatar-img" src={info.avatar_url || avatar} alt="" />
        </div>
        <div className="media-body">
          <h5>{info.nickname}</h5>
          <div className="entry-media rows">
            <div>
              <p>{info.email}</p>
            </div>
            <div>
              <i className="icofont-location-pin"></i>
              <span>{userLocation || ""}</span>
            </div>
          </div>

          <div className="d-flex align-items-center jc-center">
            <span className="text-primary">Thành viên mới</span>
            <img className="ml-2" src={iconCheckmark} alt="" />
          </div>
        </div>
      </div>
      <div className="media-favourite">
        <div>{info.role}</div>
        <img src={iconFeatherHeart} alt="" />
      </div>
    </div>
  );
}

export default AuthorBanner;
