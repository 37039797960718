import React, { useContext, useEffect, useRef, useState } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Api from "../../services/api/Api";
import regex from "../../services/regex";
import { CourseContext } from "../../context/CourseContext";
import swal from "sweetalert";
import "./CourseRegisterModal.scss";
import { useHistory } from "react-router";

const MAPLE_PHONE = "0937749722";

function ServiceRegisterModal(props) {
  const history = useHistory();

  const {
    itemServiceRgs,
    serviceRgsOpen,
    setServiceRgsOpen,
    isLogin,
    info,
    setRegisterOpen,
    getCourseRegistered,
  } = useContext(CourseContext);

  const [regisOpen, setRegisOpen] = useState(serviceRgsOpen);
  const [regisScsOpen, setRegisScsOpen] = useState(false);

  const [errorCode, setErrorCode] = useState([0, 0, 0]); // code: 0 - invalid, 1 - empty, 2- not invalid

  const nameInput = useRef(null);
  const phoneInput = useRef(null);
  const emailInput = useRef(null);

  useEffect(() => {
    setErrorCode([0, 0, 0]);
    setRegisScsOpen(false);
    setRegisOpen(serviceRgsOpen);
  }, [serviceRgsOpen]);

  function submitForm() {
    let arrInput = [nameInput, phoneInput, emailInput];
    let arr = arrInput.map((item, index) => {
      if (regex.emptyString(item.current.value)) {
        return 1;
      } else if (index === 1 && !regex.validPhoneNumber(item.current.value)) {
        return 2;
      } else if (index === 2 && !regex.validEmail(item.current.value)) {
        return 2;
      }

      return 0;
    });
    setErrorCode(arr);

    if (
      arr.reduce((result, item) => {
        return !item && result;
      }, true)
    ) {
      if (isLogin && !itemServiceRgs.order_id) {
        Api.order
          .add(itemServiceRgs.id)
          .then(() => {
            setRegisOpen(false);
            setRegisScsOpen(true);
            getCourseRegistered();
          })
          .catch(() => {
            swal("Oops!", "Xảy ra lỗi. Vui lòng thử lại.", "error");
          });
      } else {
        Api.order
          .form({
            order_id: itemServiceRgs.order_id || "",
            course_id: itemServiceRgs.id,
            course_title: itemServiceRgs.title,
            user_name: nameInput.current.value,
            user_email: emailInput.current.value,
            user_phone: phoneInput.current.value,
          })
          .then(() => {
            setRegisOpen(false);
            setRegisScsOpen(true);
          })
          .catch(() => {
            swal("Oops!", "Xảy ra lỗi. Vui lòng thử lại.", "error");
          });
      }
    }
  }

  function closeModal() {
    setServiceRgsOpen(false);
    !isLogin && setRegisterOpen(true);
  }

  function viewDetail() {
    history.push(`/course/detail/${itemServiceRgs.id}/reload`);
    setServiceRgsOpen(false);
  }

  return (
    <div>
      <Modal
        className="regis-course"
        id="item-course-02"
        isOpen={regisOpen}
        centered
      >
        <ModalHeader
          className="modal-header-left"
          toggle={() => setServiceRgsOpen(false)}
        >
          Đăng ký dịch vụ
        </ModalHeader>
        <ModalBody>
          <div className="single-item row">
            <div className="single-item-img col-12 col-sm-5">
              <div className="item-img-layout">
                <a href title={itemServiceRgs.title}>
                  <img
                    src={itemServiceRgs.cover_image}
                    alt={itemServiceRgs.title}
                  />
                </a>
              </div>
            </div>

            <div className="single-item-content col-12 col-sm-7">
              <div className="content-top">
                <h3 className="item-title">
                  <a href title={itemServiceRgs.title}>
                    {itemServiceRgs.title}
                  </a>
                </h3>
                <p className="item-content line-clamp-2">
                  {itemServiceRgs.detail}
                </p>
              </div>
              <div className="content-bottom">
                <span className="item-stick">{itemServiceRgs.discount}</span>
                <p className="item-price">Báo giá: 24h</p>
              </div>
            </div>
          </div>

          <h6 className="modal-title">Thông tin đăng ký dịch vụ</h6>
          <form>
            <div className="form-group">
              {errorCode[0] === 1 && (
                <span className="invalid-feedback">
                  * Vui lòng không bỏ trống
                </span>
              )}
              <input
                ref={nameInput}
                type="text"
                className={`form-control ${errorCode[0] && "is-invalid"}`}
                placeholder="Họ và tên"
                value={isLogin ? info.username : null}
                onChange={() => {}}
              />
            </div>
            <div className="form-group">
              {errorCode[1] > 0 && (
                <span className="invalid-feedback">
                  {errorCode[1] === 1
                    ? "* Vui lòng không bỏ trống"
                    : "* Số điện thoại không hợp lệ"}
                </span>
              )}
              <input
                ref={phoneInput}
                type="text"
                className={`form-control ${errorCode[1] && "is-invalid"}`}
                placeholder="Số điện thoại"
                value={isLogin ? info.phone : null}
                onChange={() => {}}
              />
            </div>
            <div className="form-group">
              {errorCode[2] > 0 && (
                <span className="invalid-feedback">
                  {errorCode[2] === 1
                    ? "* Vui lòng không bỏ trống"
                    : "* Email không hợp lệ"}
                </span>
              )}
              <input
                ref={emailInput}
                type="email"
                className={`form-control ${errorCode[2] && "is-invalid"}`}
                placeholder="Email"
                value={isLogin ? info.email : null}
                onChange={() => {}}
              />
            </div>
            <p className="text-contact">
              Chúng tôi sẽ liên lạc với bạn sau khi nhận được thông tin Đăng ký
              ở trên.
            </p>
            <p className="text-contact">
              Câu hỏi thắc mắc vui lòng liên hệ hotline:{" "}
              <span className="font-bold">{MAPLE_PHONE}</span>
            </p>
            <button
              type="button"
              className="btn bg-main font-bold"
              onClick={submitForm}
            >
              Gửi
            </button>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        className=" regis-course "
        id="item-course-03"
        isOpen={regisScsOpen}
        centered
      >
        <ModalHeader className="modal-header-left" toggle={closeModal}>
          Đăng ký khóa học
        </ModalHeader>
        <ModalBody>
          <div className="single-item row">
            <div className="single-item-img col-12 col-sm-5">
              <div className="item-img-layout">
                <a href title={itemServiceRgs.title}>
                  <img
                    src={itemServiceRgs.cover_image}
                    alt={itemServiceRgs.title}
                  />
                </a>
              </div>
            </div>

            <div className="single-item-content col-12 col-sm-7">
              <div className="content-top">
                <h3 className="item-title">
                  <a href title={itemServiceRgs.title}>
                    {itemServiceRgs.title}
                  </a>
                </h3>
                <p className="item-content line-clamp-2">
                  {itemServiceRgs.detail}
                </p>
              </div>
              <div className="content-bottom">
                <span className="item-stick">{itemServiceRgs.discount}</span>
                <p className="item-price">
                  Chỉ còn{" "}
                  <span className="price-new">{itemServiceRgs.price}</span>{" "}
                  /Khóa
                </p>
              </div>
            </div>
          </div>
          <div className="footer-success text-center">
            <h6 className="modal-title">Chúc mừng bạn đã đăng ký thành công</h6>

            <button type="button" className="btn-view" onClick={viewDetail}>
              Xem lại khoá học
            </button>

            <p className="text-contact">
              Chúng tôi sẽ liên lạc với bạn sau khi nhận được thông tin Đăng ký
              ở trên.
            </p>
            <p className="text-contact">
              Câu hỏi thắc mắc vui lòng liên hệ hotline:{" "}
              <span className="font-bold">{MAPLE_PHONE}</span>
            </p>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ServiceRegisterModal;
