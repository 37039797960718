import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";
import SelectList from "../../components/construct/SelectList";
import "./HomeBlogArea.scss";
import PropTypes from "prop-types";
import Skeleton from "@mui/material/Skeleton";

HomeBlogArea.prototype = {
  title: PropTypes.string,
  productList: PropTypes.array,
};

function HomeBlogArea(props) {
  const { categoriesList, openCourseRgsModal } = useContext(CourseContext);

  const { title, productList } = props;

  const [categoriesId, setCategoriesId] = useState("");

  const [firstProduct, setFirstProduct] = useState("");
  const [childProducts, setChildProducts] = useState([]);

  // Handle productList change && categoriesId change (filter list)
  useEffect(() => {
    if (productList.length > 0) {
      let _list = [];
      if (categoriesId === "") {
        _list = [...productList];
      } else {
        _list = productList.filter((item) => {
          return item.categories_id === categoriesId;
        });
      }

      if (_list.length > 0) {
        setFirstProduct(_list[0]);
        setChildProducts(_list.slice(1, 4));
      } else {
        setFirstProduct("");
        setChildProducts([]);
      }
    }
  }, [productList, categoriesId]);

  return (
    <div className="blog-area">
      <div className="row rows article-title justify-content-between">
        <div className="col-6">
          <h2 className="section-title">{title}</h2>
        </div>
        <div className="col-6 col-right">
          <SelectList
            list={categoriesList}
            handleOnChange={(e) => setCategoriesId(e.value)}
            all={true}
          />
        </div>
      </div>
      <div className="blog-wrapper">
        {productList !== -1 ? (
          <div className="blog-list row">
            {firstProduct && (
              <div className="single-poster">
                <div className="single-poster-layout">
                  <div className="single-poster-container">
                    <div className="single-poster-main">
                      <div className="single-item-img">
                        <Link
                          to={`/course/detail/${firstProduct.id}/reload`}
                          title={firstProduct.title}
                        >
                          <img
                            src={firstProduct.portrait_image}
                            alt={firstProduct.title}
                          />
                        </Link>
                      </div>

                      <div className="single-item-content">
                        <h3 className="item-title">
                          <Link
                            to={`/course/detail/${firstProduct.id}/reload`}
                            title={firstProduct.title}
                          >
                            {firstProduct.title}{" "}
                          </Link>
                        </h3>
                        <p className="item-stick">{firstProduct.discount}</p>
                        <p className="item-price">
                          Chỉ còn{" "}
                          <span className="price-new">
                            {firstProduct.price}
                          </span>{" "}
                          /Khóa
                        </p>

                        <div className="btn-horizontal">
                          <Link
                            to={`/course/detail/${firstProduct.id}/reload`}
                            className="btn btn-detail"
                          >
                            Chi tiết
                          </Link>
                          <a
                            href
                            className="btn btn-regis"
                            onClick={() => openCourseRgsModal(firstProduct)}
                          >
                            Đăng ký ngay
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="list-blog-item">
              {childProducts.length > 0 &&
                childProducts.map((item, index) => {
                  return (
                    <div className="blog-item" key={index}>
                      <div className="blog-item-layout">
                        <div className="single-item">
                          <div className="single-item-img">
                            <Link
                              to={`/course/detail/${item.id}/reload`}
                              title={item.title}
                            >
                              <img
                                src={item.image}
                                className="thumb-hover"
                                alt={item.title}
                              />
                            </Link>
                          </div>

                          <div className="single-item-content">
                            <div className="meta-icon">
                              <span>
                                <i className="icofont-users"></i> {item.num_sub}{" "}
                                đã đăng ký
                              </span>
                              <span>
                                <i className="icofont-calendar"></i> Tháng
                                05/2021
                              </span>
                            </div>
                            <h3 className="item-title">
                              <Link
                                to={`/course/detail/${item.id}/reload`}
                                title={item.title}
                              >
                                {item.title}{" "}
                              </Link>
                            </h3>
                            <p className="item-content">{item.detail} </p>
                            <div className="rows justify-content-between">
                              <span className="item-stick">
                                {item.discount}
                              </span>
                              <span className="item-price">
                                Chỉ còn{" "}
                                <span className="price-new">{item.price}</span>{" "}
                                /Khóa
                              </span>
                            </div>

                            <div className="btn-horizontal">
                              <Link
                                to={`/course/detail/${item.id}/reload`}
                                className="btn btn-detail"
                              >
                                Chi tiết
                              </Link>
                              <a
                                href
                                className="btn btn-regis"
                                onClick={() => openCourseRgsModal(item)}
                              >
                                Đăng ký ngay
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {!firstProduct && !childProducts.length && (
              <div className="no-data"></div>
            )}
          </div>
        ) : (
          <div className="blog-list row">
            <div className="col-6">
              <Skeleton animation="wave" variant="rectangular" height="100%" />
            </div>
            <div className="col-6">
              <Skeleton animation="wave" height={100} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" height={100} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" height={100} />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomeBlogArea;
