import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import "./Header.scss";

import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Api from "../../services/api/Api";

import logo from "../../assets/images/icons/logo.svg";
import iconSidebar from "../../assets/images/icons/icon-sidebar.svg";
import simpleDocker from "../../assets/images/icons/simple-docker.svg";
import iconAlarm from "../../assets/images/icons/icon-alarm.svg";
import iconCalendar from "../../assets/images/icons/icons-calendar.svg";
import iconAround from "../../assets/images/icons/icons-around-right.svg";
import iconWarning from "../../assets/images/icons/icons-warning.svg";

import { CourseContext } from "../../context/CourseContext";

import SearchModal from "../../components/modals/SearchModal";
import MenuCourseModal from "../modals/MenuCourseModal";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useEffect } from "react";
import MenuServiceModal from "../modals/MenuServiceModal";
Header.prototype = {
  noti: PropTypes.array,
};

function Header(props) {
  // const [value, setValue] = useState();
  const {
    info,
    isLogin,
    setIsLogin,
    logOut,
    menuList,
    notiList,
    services,
    setRegisterOpen,
    setLoginOpen,
    getNoti,
    getPast,
  } = useContext(CourseContext);

  const { handleSidebarOpen } = props;

  var history = useHistory();
  const [loginActive, setLoginActive] = useState(1);
  const [listSearch, setListSearch] = useState([]);
  const [searchIndex, setSearchIndex] = useState(-1);
  const [searchKey, setSearchKey] = useState("");
  const [searchTimeOut, setSearchTimeOut] = useState(null);
  const [searchModal, setSearchModal] = useState(false);
  const [menuModal, setMenuModal] = useState(false);
  const [menuServiceModal, setMenuServiceModal] = useState(false);
  const [noti, setNoti] = useState(notiList.slice(0, 4));
  const [listNotiUnread, setListNotiUnread] = useState(0);

  const [toggleCourse, setToggleCourse] = useState(false);
  const [toggleService, setToggleService] = useState(false);
  const [toggleAcount, setToggleAcount] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleNoti, setToggleNoti] = useState(false);

  useEffect(() => {
    setNoti(notiList.slice(0, 4));
    setListNotiUnread(
      notiList.filter((item) => item.is_read === "false").length
    );
  }, [notiList]);

  // Function

  function handleLogOut() {
    swal({
      title: "Bạn có muốn đăng xuất?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((value) => {
      if (value) {
        setIsLogin(false);
        logOut();
        history.push("/");
      }
    });
  }

  function searchComplete(event) {
    let key = event.target.value;
    clearTimeout(searchTimeOut);
    if (key) {
      setSearchKey(key);
      setSearchTimeOut(
        setTimeout(() => {
          Api.search.complete(key).then((res) => {
            setListSearch(res.item.auto);
          });
        }, 500)
      );
    } else {
      setSearchKey("");
      setListSearch([]);
    }
  }

  function searchKeyDown(event) {
    switch (event.keyCode) {
      case 37:
      case 39:
        break;

      case 38:
        if (listSearch.length) {
          if (searchIndex >= 0) {
            setSearchIndex(searchIndex - 1);
          } else {
            setSearchIndex(listSearch.length - 1);
          }
        }
        break;

      case 40:
        if (listSearch.length) {
          if (searchIndex < listSearch.length - 1) {
            setSearchIndex(searchIndex + 1);
          } else {
            setSearchIndex(-1);
          }
        }
        break;

      case 13:
        document.querySelector(".search-area #search").disabled = true;
        if (searchKey) {
          if (searchIndex < 0) {
            routeSearch(searchKey);
          } else {
            routeSearch(listSearch[searchIndex].title);
          }
        }
        document.querySelector(".search-area #search").disabled = false;
        break;

      default:
        setSearchIndex(-1);
        break;
    }
  }

  function routeSearch(key) {
    if (key) {
      if (key !== searchKey) {
        setSearchKey(key);
        Api.search.complete(key).then((res) => {
          setListSearch(res.item.auto);
        });
      }
      history.push(`/search?key=${key}`);
    }

    setTimeout(() => {
      setToggleSearch(false);
    });
  }

  function openNoti() {
    getNoti();
    setToggleNoti(!toggleNoti);
  }

  function readAll() {
    Api.noti.readAll().then(() => {
      getNoti();
    });
  }

  return (
    <div className="header">
      <div className="container-fluid">
        <div className="container-left">
          <div className="mobile-menu-hamburger">
            <div className="toggle-burger" onClick={handleSidebarOpen}>
              <img className="is-desktop" src={iconSidebar} alt="" />
            </div>
          </div>

          <div className="header-logo d-lg-block">
            <Link to="/">
              <img src={logo} alt="Header Logo" />
            </Link>
          </div>

          <div
            className="header-courses d-block d-sm-none"
            onClick={() => setMenuModal(true)}
          >
            <div className="courses-item">
              <img src={simpleDocker} alt="" />
              <i className="icofont-rounded-down"></i>
            </div>
          </div>

          <Dropdown
            className="header-courses d-none d-sm-block"
            isOpen={toggleCourse}
            toggle={() => {
              setToggleCourse(!toggleCourse);
            }}
          >
            <DropdownToggle className="courses-item">
              <img src={simpleDocker} alt="" />
              <div className="courses-title d-lg-block">Khóa học</div>
              <i className="icofont-rounded-down"></i>
            </DropdownToggle>
            <DropdownMenu className="courses-menu">
              <ul className="submenu">
                {menuList.map((item, index) => {
                  return (
                    <li className="submenu-item" key={index}>
                      <Link
                        to={item.director}
                        className="submenu-link"
                        title={item.name}
                        onClick={() => {
                          setToggleCourse(!toggleCourse);
                        }}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </DropdownMenu>
          </Dropdown>
          <div className="header-gallery">
            <Link to="/gallery">Gallery</Link>
          </div>

          <div
            className="header-service header-courses d-block d-lg-none"
            onClick={() => setMenuServiceModal(true)}
          >
            <div className="courses-item">
              <div className="courses-title d-lg-block">Dịch vụ</div>
              <i className="icofont-rounded-down"></i>
            </div>
          </div>

          <Dropdown
            className="header-service header-courses d-none d-lg-block"
            isOpen={toggleService}
            toggle={() => {
              setToggleService(!toggleService);
            }}
          >
            <DropdownToggle className="courses-item">
              <div className="courses-title d-lg-block">Dịch vụ</div>
              <i className="icofont-rounded-down"></i>
            </DropdownToggle>
            <DropdownMenu className="service-menu">
              <ul className="submenu">
                {services.map((item, index) => {
                  return (
                    <li className="submenu-item" key={index}>
                      <Link
                        to={`service/` + item.id}
                        className="submenu-link"
                        title={item.short_title}
                        onClick={() => {
                          setToggleService(!toggleService);
                        }}
                      >
                        {item.short_title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </DropdownMenu>
          </Dropdown>

          <div className="header-search-left d-xl-block">
            <Dropdown
              className="search-area"
              isOpen={toggleSearch}
              toggle={() => {
                setToggleSearch(!toggleSearch);
              }}
              onClick={() => setToggleSearch(true)}
            >
              <DropdownToggle className="form-search">
                <button
                  type="button"
                  className="btn-search"
                  onClick={() => {
                    routeSearch(searchKey);
                  }}
                >
                  <i className="icofont-search-1"></i>
                </button>
                <input
                  type="search"
                  id="search"
                  className="input-text"
                  placeholder="Tìm kiếm khóa học"
                  value={searchKey}
                  onChange={searchComplete}
                  onKeyDown={searchKeyDown}
                />
              </DropdownToggle>
              {listSearch.length ? (
                <DropdownMenu className="auto-complete">
                  <div className="sub-complete">
                    <ul>
                      {listSearch.map((item, index) => {
                        return (
                          <li
                            key={index}
                            title={item.title}
                            className={
                              searchIndex === index ? "active" : undefined
                            }
                            onMouseOver={() => {
                              setSearchIndex(index);
                            }}
                            onMouseLeave={() => {
                              setSearchIndex(-1);
                            }}
                            onClick={() => {
                              routeSearch(item.title);
                            }}
                          >
                            <div className="line-clamp-1">{item.title}</div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </DropdownMenu>
              ) : (
                ""
              )}
            </Dropdown>
          </div>
        </div>

        <div className="container-right">
          <div className="nav-right">
            {!isLogin && (
              <ul className="nav-right-area login-area">
                <li className="search-right d-xl-none">
                  <button
                    type="button"
                    className="btn-search"
                    onClick={() => {
                      setSearchModal(true);
                    }}
                  >
                    <i className="icofont-search-1"></i>
                  </button>
                </li>
                <li className="login-mobile d-lg-none">
                  <Dropdown
                    isOpen={toggleAcount}
                    toggle={() => {
                      setToggleAcount(!toggleAcount);
                    }}
                  >
                    <DropdownToggle className="login-icon"></DropdownToggle>
                    <DropdownMenu className="login-list">
                      <li className="item-login">
                        <div
                          className="i-item"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                            setLoginOpen(true);
                          }}
                        >
                          Đăng nhập
                        </div>
                      </li>
                      <li className="item-register">
                        <div
                          className="i-item"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                            setRegisterOpen(true);
                          }}
                        >
                          Đăng ký
                        </div>
                      </li>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="register-header d-lg-block">
                  <div
                    className={`btn-register ${loginActive === 0 && "active"}`}
                    onClick={() => {
                      setLoginActive(0);
                      setRegisterOpen(true);
                    }}
                  >
                    Đăng ký
                  </div>
                </li>
                <li className="login-header d-lg-block">
                  <div
                    className={`btn-login ${loginActive === 1 && "active"}`}
                    onClick={() => {
                      setLoginActive(1);
                      setLoginOpen(true);
                    }}
                  >
                    Đăng nhập
                  </div>
                </li>
              </ul>
            )}
            {isLogin && (
              <ul className="nav-right-area">
                <li className="search-right d-lg-none">
                  <button
                    type="button"
                    className="btn-search"
                    onClick={() => {
                      setSearchModal(true);
                    }}
                  >
                    <i className="icofont-search-1"></i>
                  </button>
                </li>
                <li className="alarm-area d-lg-block">
                  <Dropdown isOpen={toggleNoti} toggle={openNoti}>
                    <DropdownToggle className="noti-icon">
                      <div>
                        <img src={iconAlarm} alt="" />
                        {!toggleNoti && listNotiUnread ? (
                          <span>{listNotiUnread}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="noti-list">
                      <div className="alarm-noti">
                        <div className="alarm-header rows justify-content-between">
                          <div>
                            <h5 className="card-title">Thông báo</h5>
                          </div>
                          <div
                            className="is-hot"
                            style={{ cursor: "pointer" }}
                            onClick={readAll}
                          >
                            Đã đọc
                          </div>
                        </div>
                        <ul className="alarm-list">
                          {noti.length
                            ? noti.map((item, i) => {
                                return (
                                  <li className="alarm-item" key={i}>
                                    <div className="single-item">
                                      <div className="single-item-img">
                                        <Link
                                          to={`/course/detail/${item.id_course}/reload`}
                                        >
                                          <img
                                            src={item.course_image}
                                            alt="item.course_title"
                                          />
                                        </Link>
                                      </div>

                                      <div className="single-item-content">
                                        <h3 className="item-title">
                                          <Link
                                            to={`/course/detail/${item.id_course}/reload`}
                                          >
                                            {item.course_title}
                                          </Link>
                                        </h3>
                                        <div className="meta-icon">
                                          <div>
                                            <img
                                              className="mr-1"
                                              src={iconCalendar}
                                              alt=""
                                            />
                                            Bắt đầu từ {item.course_time}
                                          </div>
                                          <div className="mt-1">
                                            {getPast(item.created)} trước
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    {item.is_read === "false" && (
                                      <img
                                        className="icon-warning"
                                        src={iconWarning}
                                        alt=""
                                      />
                                    )}
                                  </li>
                                );
                              })
                            : ""}
                        </ul>
                        <div className="view-all">
                          <Link
                            to={"/notification"}
                            className="is-hot"
                            onClick={() => {
                              setToggleNoti(!toggleNoti);
                            }}
                          >
                            Xem tất cả thông báo
                            <img src={iconAround} className="ml-1" alt="" />
                          </Link>
                        </div>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li className="user-info">
                  <Dropdown
                    isOpen={toggleAcount}
                    toggle={() => {
                      setToggleAcount(!toggleAcount);
                    }}
                  >
                    <DropdownToggle className="user-avatar">
                      {info.avatar_url && <img src={info.avatar_url} alt="" />}
                    </DropdownToggle>
                    <DropdownMenu className="user-list">
                      {(info.role === "1" || info.role === "2") && (
                        <li className="user-item item-manager">
                          <Link
                            className="i-item"
                            to={{ pathname: "/blog/create" }}
                            title="Tạo blog"
                            onClick={() => {
                              setToggleAcount(!toggleAcount);
                            }}
                          >
                            Tạo blog
                          </Link>
                        </li>
                      )}
                      {(info.role === "1" || info.role === "2") && (
                        <li className="user-item item-manager">
                          <Link
                            className="i-item"
                            to={{ pathname: "/manager" }}
                            title="Tạo khóa học"
                            onClick={() => {
                              setToggleAcount(!toggleAcount);
                            }}
                          >
                            Tạo khóa học
                          </Link>
                        </li>
                      )}
                      <li className="user-item item-user">
                        <Link
                          className="i-item"
                          to={{ pathname: "/profile", state: { tab: "1" } }}
                          title="Tài khoản"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                          }}
                        >
                          Tài khoản
                        </Link>
                      </li>
                      <li className="user-item item-course">
                        <Link
                          className="i-item"
                          to={{ pathname: "/profile", state: { tab: "2" } }}
                          title="Khóa học của bạn"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                          }}
                        >
                          Khóa học của bạn
                        </Link>
                      </li>
                      <li className="user-item item-libs">
                        <Link
                          className="i-item"
                          to={{ pathname: "/profile", state: { tab: "3" } }}
                          title="Thư viện của bạn"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                          }}
                        >
                          Thư viện của bạn
                        </Link>
                      </li>

                      <li className="user-item item-setting">
                        <Link
                          className="i-item"
                          to={{ pathname: "/setting" }}
                          title="Cài đặt"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                          }}
                        >
                          Cài đặt
                        </Link>
                      </li>
                      <li className="user-item item-exit">
                        <div
                          className="i-item"
                          title="Thoát"
                          onClick={() => {
                            setToggleAcount(!toggleAcount);
                            handleLogOut();
                          }}
                        >
                          Thoát
                        </div>
                      </li>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>

      <SearchModal
        isOpen={searchModal}
        listSearch={listSearch}
        setListSearch={setListSearch}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        closeModal={() => {
          setSearchModal(false);
        }}
      />

      <MenuCourseModal
        isOpen={menuModal}
        closeModal={() => {
          setMenuModal(false);
        }}
      />

      <MenuServiceModal
        isOpen={menuServiceModal}
        closeModal={() => {
          setMenuServiceModal(false);
        }}
      />
    </div>
  );
}

export default Header;
