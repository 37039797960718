import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  TabPane,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
} from "reactstrap";
import swal from "sweetalert";

import Api from "../../services/api/Api";
import regex from "../../services/regex";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";

import "react-datepicker/dist/react-datepicker.css";
import "./TabProfile.scss";

import iconEdit from "../../assets/images/icons/icon-edit.svg";
import { CourseContext } from "../../context/CourseContext";

function TabProfile(props) {
  const { info, setInfo } = useContext(CourseContext);

  const { tabId } = props;

  const [readOnly, setReadOnly] = useState(true);
  const [currentInfo, setCurrentInfo] = useState(info);
  const [modalIndex, setModalIndex] = useState(0);
  const [countVeri, setCountVeri] = useState(-1);
  const [confirmPw, setConfirmPw] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [categories, setCategories] = useState([]);

  let emailCreate = React.createRef();
  let veriCode = React.createRef();
  let nickName = React.createRef();
  let fullName = React.createRef();
  let birthDay = React.createRef();
  let address = React.createRef();

  useEffect(() => {
    setCurrentInfo(info);
  }, [info]);

  useEffect(() => {
    let _update =
      !regex.emptyString(oldPassword) &&
      regex.validPassword(newPassword) &&
      newPassword === reNewPassword;

    setConfirmPw(_update);
  }, [oldPassword, newPassword, reNewPassword]);

  useEffect(() => {
    Api.favorite.list().then((res) => {
      setCategories(
        res.item.categories.map((item) => {
          let activeIndex = res.item.favorites.findIndex(
            (el) => el.id_categories === item.id
          );

          return { ...item, active: activeIndex >= 0 };
        })
      );
    });
  }, []);

  // Function
  function onInputChange(value) {
    setCurrentInfo({ ...currentInfo, ...value });
  }
  function handleChangeEditStatus(value) {
    setReadOnly(value);
    if (value) {
      setCurrentInfo(info);
    }
  }
  function changeModalIndex(value) {
    if (modalIndex === value) {
      setModalIndex(0);
      setConfirmPw(false);
    } else {
      setModalIndex(value);
      setConfirmPw(false);
    }
  }

  function openModalEmail() {
    if (modalIndex > 0 && modalIndex < 3) setModalIndex(0);
    else setModalIndex(1);
  }

  function getOTP() {
    if (!regex.validEmail(emailCreate.current.value)) {
      let _className = emailCreate.current.className;
      emailCreate.current.className = _className + " is-invalid";
      emailCreate.current.onchange = () => {
        emailCreate.current.className = _className;
      };
    } else {
      Api.OTP()
        .then(() => {
          changeModalIndex(2);
        })
        .catch((error) => {
          swal("Oops!", "Gửi OTP thất bại", "error");
        });
    }
  }
  function requestChangeEmail() {
    // check veri-code

    Api.changeEmail({
      otpToken: veriCode.current.value,
      email: newEmail,
    })
      .then(() => {
        onInputChange({ email: newEmail });
        changeModalIndex(0);

        setCountVeri(-1);
      })
      .catch(() => {
        switch (countVeri) {
          case -1:
            setCountVeri(4);

            break;

          case 1:
            changeModalIndex(0);
            setCountVeri(-1);

            break;

          default:
            setCountVeri(countVeri - 1);

            break;
        }
      });
  }

  function isEyeClick(e) {
    if (e.target.previousElementSibling.type === "password") {
      e.target.previousElementSibling.type = "text";
      e.target.className = "is-eye icofont-eye";
    } else {
      e.target.previousElementSibling.type = "password";
      e.target.className = "is-eye icofont-eye-blocked";
    }
  }

  // Check password

  function handleOldPassword(value) {
    setOldPassword(value);
  }

  function handleChangePassword(value) {
    setNewPassword(value);
    if (value.length >= 8) {
      document.querySelector(".rule-password .rule-item.rule-1").className =
        "rule-item rule-1 active";
    } else {
      document.querySelector(".rule-password .rule-item.rule-1").className =
        "rule-item rule-1";
    }

    let checkLowCase = !regex.isLowerCase(value);
    let checkUpCase = !regex.isUpperCase(value);

    if (checkLowCase && checkUpCase) {
      document.querySelector(".rule-password .rule-item.rule-2").className =
        "rule-item rule-2 active";
    } else {
      document.querySelector(".rule-password .rule-item.rule-2").className =
        "rule-item rule-2";
    }

    let checkNumber = regex.existNumber(value);
    if (checkNumber) {
      document.querySelector(".rule-password .rule-item.rule-3").className =
        "rule-item rule-3 active";
    } else {
      document.querySelector(".rule-password .rule-item.rule-3").className =
        "rule-item rule-3";
    }
  }

  function handleConfirmPassword(value) {
    setReNewPassword(value);
  }

  function updateNewPassword() {
    if (confirmPw) {
      changeModalIndex(0);

      Api.changePassword({
        password: oldPassword,
        new_password: newPassword,
      })
        .then((response) => {
          swal({
            title: "Thành công.",
            text: "Mật khẩu mới đã được cập nhật.",
            icon: "success",
            timer: 2000,
          });
        })
        .catch(function (error) {
          if (error.item !== undefined) swal("Oops!", error.item.smg, "error");
          else swal("Oops!", "Cập nhật thất bại!", "error");
        });
    }
  }

  function changeProfile() {
    let inputs = [nickName, fullName, birthDay, address];

    var key = 1;

    inputs.forEach((item, index) => {
      switch (index) {
        case 0:
        case 1:
        case 3:
          if (regex.emptyString(item.current.value)) {
            item.current.className = "form-control is-invalid";
            item.current.onchange = () => {
              item.current.className = "form-control";
            };
            key = 0;
          }
          break;

        case 2:
          if (regex.emptyString(item.current.firstChild.value)) {
            item.current.firstChild.className = "form-control is-invalid";
            item.current.firstChild.onchange = () => {
              item.current.firstChild.className = "form-control";
            };
            key = 0;
          }
          break;
        default:
          break;
      }
    });

    if (key === 0) {
      swal("Oops!", "Điền đầy đủ thông tin!", "error");
    } else {
      // Put Api
      Api.changeProfile({
        username: currentInfo.username,
        email: currentInfo.email,
        date_birth: birthDay.current.firstChild.value,
        address: currentInfo.address,
        nickname: currentInfo.nickname,
      })
        .then(function (response) {
          swal({
            title: response.item.smg,
            icon: "success",
            timer: 2000,
          });

          setInfo(response.item.user);
        })
        .catch(function (error) {
          if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
          else swal("Oops!", "Thay đổi thất bại!", "error");
        })
        .then(function () {
          setReadOnly(true);
        });
    }
  }

  function handleFavorite(index) {
    let _categories = [...categories];
    let _item = categories[index];

    if (_item.active) {
      Api.favorite.remove(_item.id).then(() => {
        _categories[index].active = false;
        setCategories(_categories);
      });
    } else {
      Api.favorite.add(_item.id).then(() => {
        _categories[index].active = true;
        setCategories(_categories);
      });
    }
  }

  return (
    <TabPane
      tabId={tabId}
      id="pills-account"
      role="tabpanel"
      aria-labelledby="pills-account-tab"
    >
      {readOnly && (
        <Card>
          <CardHeader>
            <h5 className="card-title">Thông tin tài khoản</h5>
            <a
              href
              className="btn btn-edit"
              data-toggle="modal"
              data-target="#editProfile"
              onClick={() => {
                handleChangeEditStatus(false);
              }}
            >
              <img src={iconEdit} alt="" />
              Chỉnh sửa
            </a>
          </CardHeader>
          <CardBody>
            <div className="author-wrap profile-readonly">
              <Form>
                <FormGroup>
                  <label>Biệt danh</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Biệt danh"
                    value={currentInfo.nickname}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Họ và tên</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Họ và tên"
                    value={currentInfo.username}
                  />
                </FormGroup>
                <div className="row">
                  <div className="col-lg-6">
                    <FormGroup>
                      <label>Tên đăng nhập</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="email, số điện thoại"
                        value={currentInfo.email}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg-6">
                    <FormGroup>
                      <label>Mật khẩu</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                        value="********"
                      />
                    </FormGroup>
                  </div>
                </div>
                <FormGroup>
                  <label>Sinh nhật</label>
                  <div className="input-group date" id="datetimepicker">
                    <input
                      type="text"
                      className="form-control"
                      value={currentInfo.date_birth}
                    />
                    <span className="input-group-addon"></span>
                  </div>
                </FormGroup>
                <FormGroup>
                  <label>Số điện thoại</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    value={currentInfo.phone}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Địa chỉ</label>
                  <textarea
                    type="text"
                    className="form-control"
                    rows="3"
                    placeholder="Thành phố Hồ Chí Minh"
                    value={currentInfo.address}
                  ></textarea>
                </FormGroup>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
      {!readOnly && (
        <Card>
          <CardHeader className="justify-content-between">
            <h5 className="card-title">Thông tin tài khoản</h5>
            <div className="btn-horizontal edit-profile">
              <a
                href
                className="btn btn-detail"
                onClick={() => {
                  handleChangeEditStatus(true);
                }}
              >
                Bỏ qua
              </a>
              <a
                href
                className="btn bg-main"
                onClick={() => {
                  changeProfile();
                }}
              >
                Lưu
              </a>
            </div>
          </CardHeader>
          <CardBody>
            <div className="author-wrap">
              <Form>
                <FormGroup>
                  <label>Biệt danh</label>
                  <input
                    ref={nickName}
                    type="text"
                    className="form-control"
                    placeholder="Biệt danh"
                    value={currentInfo.nickname}
                    onChange={(e) =>
                      onInputChange({ nickname: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <label>Họ và tên</label>
                  <input
                    ref={fullName}
                    type="text"
                    className="form-control"
                    placeholder="Họ và tên"
                    value={currentInfo.username}
                    onChange={(e) =>
                      onInputChange({ username: e.target.value })
                    }
                  />
                </FormGroup>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group edit-user-form">
                      <label>Tên đăng nhập</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="email, số điện thoại"
                        value={currentInfo.email}
                      />
                      <button
                        className="btn btn-modal"
                        type="button"
                        onClick={openModalEmail}
                      >
                        <img src={iconEdit} alt="" />
                      </button>

                      <Modal
                        isOpen={modalIndex === 1}
                        container={() =>
                          document.querySelector(".edit-user-form")
                        }
                        modalClassName="modal-account"
                        id="my-editUser"
                        backdrop={false}
                      >
                        <ModalHeader className="modal-header">
                          <h5 className="modal-title mb-2">
                            Đổi tên đăng nhập
                          </h5>
                          <p>Nhập email mới để làm tên đăng nhập</p>
                        </ModalHeader>
                        <ModalBody className="modal-body">
                          <div className="edit-form">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icofont-email"></i>
                                </span>
                              </div>
                              <input
                                ref={emailCreate}
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                onChange={(e) => {
                                  setNewEmail(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter className="modal-footer">
                          <button
                            type="button"
                            className="btn bg-border"
                            onClick={() => {
                              changeModalIndex(0);
                            }}
                          >
                            Hủy
                          </button>
                          <button
                            className="btn bg-main"
                            type="button"
                            onClick={getOTP}
                          >
                            Tiếp tục
                          </button>
                        </ModalFooter>
                      </Modal>

                      <Modal
                        isOpen={modalIndex === 2}
                        container={() =>
                          document.querySelector(".edit-user-form")
                        }
                        modalClassName="modal-account"
                        id="my-confirmChangeUser"
                        backdrop={false}
                      >
                        <ModalHeader>
                          <h5 className="modal-title mb-2">
                            Đổi tên đăng nhập
                          </h5>
                          <p>Nhập mã xác nhận đã được gửi đến Email của bạn</p>
                        </ModalHeader>
                        <ModalBody>
                          <FormGroup className="edit-form">
                            <div className="input-group">
                              {countVeri >= 0 && (
                                <span className="invalid-feedback">
                                  * Mã xác nhận không đúng - số lần thử lại còn
                                  0{countVeri}
                                </span>
                              )}
                              <input
                                ref={veriCode}
                                type="email"
                                className="form-control"
                                placeholder="Mã xác nhận"
                                required
                              />
                            </div>
                          </FormGroup>
                        </ModalBody>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn bg-border"
                            data-dismiss="modal"
                            onClick={() => {
                              changeModalIndex(0);
                            }}
                          >
                            Hủy
                          </button>
                          <a
                            href
                            className="btn bg-main"
                            onClick={requestChangeEmail}
                          >
                            Xác nhận
                          </a>
                        </div>
                      </Modal>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group edit-pass-form">
                      <label>Mật khẩu</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="********"
                      />
                      <button
                        className="btn btn-modal"
                        type="button"
                        onClick={() => {
                          changeModalIndex(3);
                        }}
                      >
                        <img src={iconEdit} alt="" />
                      </button>
                      <Modal
                        isOpen={modalIndex === 3}
                        container={() =>
                          document.querySelector(".edit-pass-form")
                        }
                        modalClassName="modal-account"
                        className="modal-dialog-centered"
                        id="my-editPass"
                        backdrop={false}
                      >
                        <ModalHeader>
                          <h5 className="modal-title mb-2">
                            Thay đổi mật khẩu
                          </h5>
                        </ModalHeader>
                        <ModalBody>
                          <Form className="edit-form">
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icofont-lock"></i>
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Mật khẩu cũ"
                                onChange={(e) =>
                                  handleOldPassword(e.target.value)
                                }
                              />
                            </div>
                            <div className="input-group style-eye mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icofont-lock"></i>
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control attr-pass"
                                placeholder="Nhập mật khẩu mới"
                                onChange={(e) =>
                                  handleChangePassword(e.target.value)
                                }
                              />
                              <i
                                className="is-eye icofont-eye-blocked"
                                onClick={(e) => isEyeClick(e)}
                              ></i>
                            </div>
                            <div className="input-group style-eye mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="icofont-lock"></i>
                                </span>
                              </div>
                              <input
                                type="password"
                                className={`form-control attr-pass ${
                                  newPassword !== reNewPassword && "is-invalid"
                                }`}
                                placeholder="Xác nhận lại mật khẩu"
                                onChange={(e) =>
                                  handleConfirmPassword(e.target.value)
                                }
                              />
                              <i
                                className="is-eye icofont-eye-blocked"
                                onClick={(e) => isEyeClick(e)}
                              ></i>
                            </div>
                          </Form>
                          <ul className="rule-password">
                            <li>
                              <span>Mật khẩu của bạn phải có:</span>
                            </li>
                            <li className="rule-item rule-1">
                              <span>8 ký tự hoặc nhiều hơn</span>
                            </li>
                            <li className="rule-item rule-2">
                              <span>Chữ in hoa & chữ thường</span>
                            </li>
                            <li className="rule-item rule-3">
                              <span>Ít nhất có 1 chữ số</span>
                            </li>
                          </ul>
                        </ModalBody>
                        <ModalFooter>
                          <button
                            type="button"
                            className="btn bg-border"
                            data-dismiss="modal"
                            onClick={() => {
                              changeModalIndex(0);
                            }}
                          >
                            Hủy
                          </button>
                          <a
                            href
                            className={
                              confirmPw ? "btn bg-main" : "btn bg-gray"
                            }
                            data-toggle="modal"
                            data-target="#confirmChangeUser"
                            data-dismiss="modal"
                            onClick={() => {
                              updateNewPassword();
                            }}
                          >
                            Thay đổi
                          </a>
                        </ModalFooter>
                      </Modal>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Sinh nhật</label>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={currentInfo.date_birth}
                      onChange={(value) => {
                        onInputChange({ date_birth: value });
                      }}
                      renderInput={({ inputRef, inputProps, InputProps }) => (
                        <div
                          ref={birthDay}
                          className="input-group date"
                          id="datetimepicker"
                        >
                          <input
                            className="form-control"
                            ref={inputRef}
                            {...inputProps}
                          />
                        </div>
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className="form-group">
                  <label>Số điện thoại</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Số điện thoại"
                    value={currentInfo.phone}
                  />
                </div>
                <div className="form-group">
                  <label>Địa chỉ</label>
                  <textarea
                    ref={address}
                    type="text"
                    className="form-control"
                    rows="3"
                    placeholder="Địa chỉ"
                    value={currentInfo.address}
                    onChange={(e) => {
                      onInputChange({ address: e.target.value });
                    }}
                  ></textarea>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      )}
      <div className="commons-card">
        <Card>
          <CardHeader className="justify-content-between">
            <CardTitle>Bạn muốn tìm hiểu về ?</CardTitle>
            <p>Chọn danh mục, khoá học bạn quan tâm</p>
          </CardHeader>
          <CardBody>
            <ul className="list-checkbox">
              {categories.map((item, index) => {
                return (
                  <li className="checkbox-item" key={index}>
                    <a
                      href
                      className={`checkbox-link rows ${
                        item.active ? "active" : ""
                      }`}
                      title={item.name}
                      onClick={() => handleFavorite(index)}
                    >
                      <span>{item.name}</span>
                      <div className="around-tick">
                        <i className="icofont-check-alt"></i>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </CardBody>
        </Card>
      </div>
    </TabPane>
  );
}

TabProfile.propTypes = {
  test: PropTypes.func,
};

export default TabProfile;
