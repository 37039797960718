import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { CourseContext } from "../../context/CourseContext";

import Slider from "react-slick";
import "./SubjectList.scss";
import Skeleton from "@mui/material/Skeleton";
import SelectList from "../construct/SelectList";

SubjectList.prototype = {
  defaultCate: PropTypes.string,
  defaultSub: PropTypes.string,
  handleSelectList: PropTypes.func,
  handleChangeSubject: PropTypes.func,
};

SubjectList.defaultProps = {
  defaultCate: "",
  defaultSub: "",
};

function SubjectList(props) {
  const {
    isLogin,
    setLoginOpen,
    favouriteList,
    addFavouriteList,
    categoriesList,
    subjectList,
  } = useContext(CourseContext);

  const { defaultCate, handleSelectList, defaultSub, handleChangeSubject } =
    props;

  const [list, setList] = useState(-1);
  const [categoriesId, setCategoriesId] = useState(defaultCate);

  // Handle defaultCate change
  useEffect(() => {
    setCategoriesId(defaultCate);
  }, [defaultCate]);

  // Sort List && update favorite
  useEffect(() => {
    function updateFavoriteValue(arr) {
      return arr.map((item) => {
        let _index = favouriteList.findIndex(
          (_item) => _item.subject_id === item.id
        );
        return { ...item, favourite: _index >= 0 };
      });
    }

    if (categoriesList.length > 0 && subjectList !== -1) {
      let _arrFilter = [];
      if (categoriesId === "" && categoriesList.length > 0) {
        _arrFilter = subjectList.filter((item) => {
          return item.categories_id === categoriesList[0].id;
        });
      } else if (categoriesId !== "") {
        _arrFilter = subjectList.filter((item) => {
          return item.categories_id === categoriesId;
        });
      }
      setList(updateFavoriteValue(_arrFilter));
    }
  }, [categoriesId, subjectList, favouriteList, categoriesList]);

  // Set fade
  useEffect(() => {
    let _dom = document.querySelector(".course-carousel");
    let _className = _dom.className.split("fade-item").join("");
    _dom.className = _className + " fade-item";
    setTimeout(() => {
      _dom.className = _className;
    }, 1000);
  }, [categoriesId]);

  function handleOnChange(e) {
    setCategoriesId(e.value);
  }

  function handleAddFavouriteList(item) {
    if (isLogin) {
      addFavouriteList(item);
    } else {
      setLoginOpen(true);
    }
  }

  return (
    <div className="course-slider">
      <div className="rows article-title justify-content-between">
        <div className="col-6">
          <h2 className="section-title">Các khóa học</h2>
        </div>
        <div className="col-6 col-right">
          <SelectList
            list={categoriesList}
            defaultValue={categoriesId}
            defaultStyle={{ backgroundColor: "#ffffff", color: "#d92e08" }}
            handleOnChange={handleSelectList || handleOnChange}
          />
        </div>
      </div>
      <Slider
        className="course-carousel slick-arrow-style"
        autoplay={false}
        speed={500}
        infinite={false}
        slidesToShow={7}
        prevArrow={
          <button type="button" className="slick-prev">
            <i className="icofont-thin-left"></i>
          </button>
        }
        nextArrow={
          <button type="button" className="slick-next">
            <i className="icofont-thin-right"></i>
          </button>
        }
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 3,
            },
          },
        ]}
      >
        {list === -1 &&
          Array.from(new Array(7)).map((item, index) => {
            return (
              <div className="single-course" key={index}>
                <div className="box-course">
                  <div className="thumb-course">
                    <div className="thumb-image">
                      <Skeleton
                        variant="circular"
                        animation="wave"
                        width="100%"
                        height="100%"
                        style={{ position: "absolute" }}
                      />
                    </div>
                  </div>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </div>
              </div>
            );
          })}
        {list.length > 0 &&
          list.map((item, index) => {
            return (
              <div
                className={`single-course ${
                  item.id === defaultSub && "active"
                }`}
                key={index}
              >
                <div className="box-course">
                  <div title={item.name}>
                    <div className="thumb-course">
                      <div
                        className="thumb-image"
                        style={{ backgroundImage: `url("${item.image}")` }}
                        onClick={() => handleChangeSubject(item.id)}
                      ></div>
                    </div>
                    <div className="box-course-hover">
                      {!item.favourite && (
                        <i
                          className="btn-more icofont-close-circled"
                          onClick={() => {
                            handleAddFavouriteList(item);
                          }}
                        ></i>
                      )}
                    </div>
                    <p className="title-alias">{item.name}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
      {list.length === 0 && <div className="no-data" height="140px"></div>}
    </div>
  );
}

export default SubjectList;
