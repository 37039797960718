import React, { useEffect, useState } from "react";

import { useLocation, useHistory } from "react-router-dom";

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";

import "./PolicyScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";

import iconArrowBack from "../../assets/images/icons/icon-arrow-back.svg";

function PolicyScreen(props) {
  const history = useHistory();
  const location = useLocation();

  const [tab, setTab] = useState(location.state && location.state.tab);

  useEffect(() => {
    if (location.state && location.state.tab) {
      setTab(location.state.tab);
    } else {
      setTab("1");
    }
  }, [location]);

  return (
    <div className="policy">
      <PosterBanner />

      <div className="m-container">
        <div className="legal-area commons-card">
          <div className="gallery-back">
            <a href onClick={history.goBack}>
              <img src={iconArrowBack} alt="" />
            </a>
          </div>
          <Card>
            <CardHeader>
              <div className="tabs-profile tabs-area">
                <Nav className="nav-pills" id="legal-tab">
                  <NavItem>
                    <NavLink
                      active={tab === "1"}
                      id="legal-contact-tab"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="legal-contact"
                      onClick={() => {
                        setTab("1");
                      }}
                    >
                      Liên hệ
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={tab === "2"}
                      id="legal-about-tab"
                      data-toggle="pill"
                      aria-controls="legal-about"
                      onClick={() => {
                        setTab("2");
                      }}
                    >
                      Giới thiệu
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={tab === "3"}
                      id="legal-terms-tab"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="legal-terms"
                      onClick={() => {
                        setTab("3");
                      }}
                    >
                      Điều khoản sử dụng
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={tab === "4"}
                      id="legal-policy-tab"
                      data-toggle="pill"
                      aria-controls="legal-policy"
                      onClick={() => {
                        setTab("4");
                      }}
                    >
                      Chính sách bảo mật
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </CardHeader>
            <CardBody>
              <div className="tabs-profile">
                <TabContent activeTab={tab} id="legal-tabContent">
                  <TabPane
                    tabId="1"
                    id="legal-contact"
                    role="tabpanel"
                    aria-labelledby="legal-contact-tab"
                  >
                    <div className="legal-wrap legal-contact-area">
                      <h3 className="legal-title">Liên hệ với chúng tôi</h3>
                      <p>
                        Maple là cộng đồng sáng tạo phát triển nhanh nhất, nơi
                        các chuyên gia sáng tạo giỏi nhất chia sẻ kiến thức và
                        kỹ năng của họ thông qua các khóa học trực tuyến được
                        sản xuất chuyên nghiệp. Tất cả bắt đầu như một diễn đàn
                        trực tuyến và một nơi giới thiệu nhỏ nhưng năng động của
                        các chuyên gia sáng tạo, được thiết kế để giúp họ kết
                        nối và học hỏi lẫn nhau. Lấy cảm hứng từ cộng đồng đang
                        phát triển mạnh của họ, Maple đã mở rộng phạm vi tiếp
                        cận của mình bằng cách tạo ra các khóa học trực tuyến
                        cho bất kỳ ai quan tâm đến việc giải phóng tiềm năng
                        sáng tạo của họ và kết nối với những nhà sáng tạo cùng
                        chí hướng từ khắp nơi trên thế giới. Maple sắp xếp cẩn
                        thận danh sách giáo viên và sản xuất tất cả các khóa học
                        ngay trong nhà, để đảm bảo trải nghiệm học tập trực
                        tuyến chất lượng cao cho tất cả mọi người. Ngày nay,
                        cộng đồng trực tuyến là nơi có hơn 6 triệu người từ khắp
                        nơi trên thế giới, những người tò mò và đam mê học hỏi
                        các kỹ năng sáng tạo mới. Maple có trụ sở chính tại TP
                        HCM . Để liên hệ với chúng tôi, hãy kiểm tra phần Liên
                        hệ.
                      </p>
                    </div>
                  </TabPane>

                  <TabPane
                    tabId="2"
                    id="legal-about"
                    role="tabpanel"
                    aria-labelledby="legal-about-tab"
                  >
                    <div className="legal-wrap">
                      <h3 className="legal-title">MAPLE STUDIO</h3>
                      <p>
                        Nếu bạn có bất kỳ câu hỏi nào hoặc chỉ muốn gửi lời
                        chào, cách tốt nhất để liên hệ với chúng tôi là thông
                        qua biểu mẫu của chúng tôi. Chúng tôi sẽ cố gắng hết sức
                        để phản hồi trong vòng chưa đầy 48 giờ, nhưng nếu thời
                        gian này lâu hơn một chút, xin hãy thứ lỗi cho chúng
                        tôi! Chúng tôi đọc từng tin nhắn và phân phối nội bộ để
                        đảm bảo rằng bạn sẽ nói chuyện với đúng người. Chúng tôi
                        nhắc bạn rằng bạn cũng có phần Trợ giúp của chúng tôi,
                        nơi bạn sẽ tìm thấy câu trả lời cho những câu hỏi thường
                        gặp nhất về Maple studio.
                      </p>
                      <p></p>
                      <form className="form-inline mb-sm-2">
                        <input
                          type="text"
                          className="form-control mr-2"
                          placeholder="Email"
                        />
                        <button type="submit" className="btn bg-main font-bold">
                          Gửi
                        </button>
                      </form>
                      <p>
                        Chúng tôi sẽ liên lạc với bạn sau khi nhận được thông
                        tin Email ở trên.
                      </p>
                      <p>
                        Câu hỏi thắc mắc vui lòng liên hệ hotline:{" "}
                        <span className="font-bold">0937749722</span>
                      </p>
                      <p>
                        Bạn nên Theo dõi chúng tôi trên{" "}
                        <a href className="font-bold" title="Instagram">
                          Instagram
                        </a>
                        ,{" "}
                        <a href className="font-bold" title="Facebook">
                          Facebook
                        </a>{" "}
                        và{" "}
                        <a href className="font-bold" title="Twitter">
                          Twitter
                        </a>
                        , hãy tin tưởng chúng tôi.
                      </p>
                    </div>
                  </TabPane>

                  <TabPane
                    tabId="3"
                    id="legal-terms"
                    role="tabpanel"
                    aria-labelledby="legal-terms-tab"
                  >
                    <div className="legal-wrap">
                      <h3 className="legal-title">Điều khoản sử dụng</h3>
                      <p>
                        Maple là cộng đồng sáng tạo phát triển nhanh nhất, nơi
                        các chuyên gia sáng tạo giỏi nhất chia sẻ kiến thức và
                        kỹ năng của họ thông qua các khóa học trực tuyến được
                        sản xuất chuyên nghiệp. Tất cả bắt đầu như một diễn đàn
                        trực tuyến và một nơi giới thiệu nhỏ nhưng năng động của
                        các chuyên gia sáng tạo, được thiết kế để giúp họ kết
                        nối và học hỏi lẫn nhau. Lấy cảm hứng từ cộng đồng đang
                        phát triển mạnh của họ, Maple đã mở rộng phạm vi tiếp
                        cận của mình bằng cách tạo ra các khóa học trực tuyến
                        cho bất kỳ ai quan tâm đến việc giải phóng tiềm năng
                        sáng tạo của họ và kết nối với những nhà sáng tạo cùng
                        chí hướng từ khắp nơi trên thế giới. Maple sắp xếp cẩn
                        thận danh sách giáo viên và sản xuất tất cả các khóa học
                        ngay trong nhà, để đảm bảo trải nghiệm học tập trực
                        tuyến chất lượng cao cho tất cả mọi người. Ngày nay,
                        cộng đồng trực tuyến là nơi có hơn 6 triệu người từ khắp
                        nơi trên thế giới, những người tò mò và đam mê học hỏi
                        các kỹ năng sáng tạo mới. Maple có trụ sở chính tại TP
                        HCM . Để liên hệ với chúng tôi, hãy kiểm tra phần Liên
                        hệ.
                      </p>
                    </div>
                  </TabPane>

                  <TabPane
                    tabId="4"
                    id="legal-policy"
                    role="tabpanel"
                    aria-labelledby="legal-policy-tab"
                  >
                    <div className="legal-wrap">
                      <h3 className="legal-title">Chính sách bảo mật</h3>
                      <p>
                        Maple là cộng đồng sáng tạo phát triển nhanh nhất, nơi
                        các chuyên gia sáng tạo giỏi nhất chia sẻ kiến thức và
                        kỹ năng của họ thông qua các khóa học trực tuyến được
                        sản xuất chuyên nghiệp. Tất cả bắt đầu như một diễn đàn
                        trực tuyến và một nơi giới thiệu nhỏ nhưng năng động của
                        các chuyên gia sáng tạo, được thiết kế để giúp họ kết
                        nối và học hỏi lẫn nhau. Lấy cảm hứng từ cộng đồng đang
                        phát triển mạnh của họ, Maple đã mở rộng phạm vi tiếp
                        cận của mình bằng cách tạo ra các khóa học trực tuyến
                        cho bất kỳ ai quan tâm đến việc giải phóng tiềm năng
                        sáng tạo của họ và kết nối với những nhà sáng tạo cùng
                        chí hướng từ khắp nơi trên thế giới. Maple sắp xếp cẩn
                        thận danh sách giáo viên và sản xuất tất cả các khóa học
                        ngay trong nhà, để đảm bảo trải nghiệm học tập trực
                        tuyến chất lượng cao cho tất cả mọi người. Ngày nay,
                        cộng đồng trực tuyến là nơi có hơn 6 triệu người từ khắp
                        nơi trên thế giới, những người tò mò và đam mê học hỏi
                        các kỹ năng sáng tạo mới. Maple có trụ sở chính tại TP
                        HCM . Để liên hệ với chúng tôi, hãy kiểm tra phần Liên
                        hệ.
                      </p>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default PolicyScreen;
