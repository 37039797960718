import React, { useContext, useEffect, useState } from "react";

import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import iconZoomIn from "../../assets/images/icons/icon-zoom-in.svg";
import iconMterialMoreHoriz from "../../assets/images/icons/icon-material-more-horiz.svg";
import avatar from "../../assets/images/avatar.png";

import Api from "../../services/api/Api";
import { CourseContext } from "../../context/CourseContext";

import "./GalleryDetailPost.scss";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

Fancybox.defaults.Image = { zoom: false };

function GalleryDetailPost(props) {
  const history = useHistory();

  const { info } = useContext(CourseContext);

  const { id, userId } = props;

  const [gallery, setGallery] = useState({});
  const [artist, setArtist] = useState({});
  const [tags, setTags] = useState([]);
  const [isLike, setIsLike] = useState(false);
  const [togMore, setTogMore] = useState(false);

  useEffect(() => {
    Api.gallery.detail(id, userId).then((res) => {
      setGallery(res.item.gallery);
      setArtist(res.item.artist);
      setTags(res.item.tags);
      setIsLike(res.item.is_like === "true");
    });
  }, [id, userId]);

  function likeGallery() {
    !isLike &&
      Api.gallery.like(id).then((res) => {
        setIsLike(true);
      });
  }

  function activeTogMore() {
    setTogMore(!togMore);
  }

  function deleteGallery() {
    console.log(gallery);
    Api.gallery
      .delete(gallery.id)
      .then((res) => {
        history.replace("/gallery");
      })
      .catch(() => {
        swal("Oops!", "Xảy ra lỗi. Vui lòng thử lại.", "error");
      });
  }

  return (
    <div className="gallery-detail-post rows">
      <div className="gallery-post col-lg-5 mb-3 mb-lg-0">
        <img src={gallery.image} alt="" />
        <a
          href
          className="handle-zoom-in"
          data-fancybox="single"
          data-src={gallery.image}
          Panzoom={{ maxScale: 2 }}
        >
          <img src={iconZoomIn} alt="" />
        </a>
      </div>
      <div className="gallery-info col-lg-6">
        <div className="gallery-topbar rows justify-content-between">
          <div className="topbar-left rows align-items-center">
            <a href className="gallery-share">
              {}
            </a>
            <a href={gallery.download} className="gallery-download">
              {}
            </a>
          </div>
          <div className="topbar-right rows align-items-center">
            <a
              href
              className={`tog-favorite ${isLike && "active"}`}
              onClick={likeGallery}
            >
              {}
            </a>
            <a href className="tog-more" onClick={activeTogMore}>
              <img src={iconMterialMoreHoriz} alt="" />
            </a>
            <div className="gallery-item active">
              {togMore && (
                <div className="gallery-link">
                  <a
                    href
                    className={
                      !info.id || info.id !== gallery.user_id ? "disable" : ""
                    }
                    onClick={deleteGallery}
                  >
                    Xóa bỏ sản phẩm
                  </a>
                  <a href={gallery.download}>Tải xuống</a>
                </div>
              )}
            </div>
          </div>
        </div>
        <h2 className="gallery-title">{gallery.title}</h2>
        <div className="gallery-desctiption">
          <p>{gallery.detail}</p>
        </div>
        <div className="gallery-tags">
          {tags.map((item, index) => (
            <a href key={index}>
              {item.name}
            </a>
          ))}
        </div>
        <div className="gallery-user rows justify-content-between align-items-center">
          <div className="media">
            <div className="media-user">
              <img src={artist.avatar_url} alt="" />
            </div>
            <div className="media-body">
              <h5>{artist.username}</h5>
              <ul className="entry-media">
                <li>
                  <p>{artist.role} yêu thích</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn tog-favorite">
            <span>Yêu thích</span>
          </div>
        </div>
        <div className="gallery-comment">
          <h3>Nhận xét</h3>
          <p>Chia sẻ phản hồi, đặt câu hỏi hoặc để lại cảm nghĩ</p>
          <div className="media">
            <div className="media-user">
              <img src={info.avatar_url || avatar} alt="" />
            </div>
            <div className="media-body">
              <form action="" method="">
                <textarea
                  name=""
                  id=""
                  className="form-control"
                  rows="2"
                ></textarea>
                <button type="button" className="btn bg-main mt-3 float-right">
                  Gửi
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryDetailPost;
