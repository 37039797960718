import React, { useState, useContext } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Button,
} from "reactstrap";

import { CourseContext } from "../../context/CourseContext";

import "./ManagerScreen.scss";
import iconCubic from "../../assets/images/icons/icon-cubic.svg";
import DashboardHome from "./DashboardHome";
import CreateCourse from "./CreateCourse";
import { useEffect } from "react";
import Api from "../../services/api/Api";
import CreateDescription from "./CreateDescription";
import CreateDocument from "./CreateDocument";
import CreateExercise from "./CreateExercise";
import CreateSession from "./CreateSession";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

function ManagerScreen(props) {
  const history = useHistory();

  const { info } = useContext(CourseContext);

  const [subjects, setSubjects] = useState([]);
  const [states, setStates] = useState([]);

  // const [course, setCourse] = useState({
  //   detail: "dasdsdasd",
  //   discount: "1212",
  //   id: "9d883ec8-23dd-4e25-9d7c-cd27d8f24a2e",
  //   image:
  //     "https://api.mapleskills.com/file/e221d92a-6db0-40dc-ba45-2971497861ca/image-1643504699051.png",
  //   language: "vi",
  //   num_sub: "0",
  //   portrait_image:
  //     "https://api.mapleskills.com/file/dbc04414-acc4-40e9-bcff-e833f7100dce/portrait_image-1643504699055.png",
  //   price: "asasasa",
  //   state_id: "bd716b23-5ccd-43c1-bef5-dc134572ad9f",
  //   subject_id: "546ca956-fd6e-49c2-9513-a85d3698dfa1",
  //   time: "123",
  //   title: "ac",
  // });

  const [course, setCourse] = useState(null);

  const [tab, setActiveTab] = useState("0");

  const [reset, setReset] = useState(true);

  useEffect(() => {
    Api.manager.getSubject().then((res) => {
      setSubjects(res.item.subjects);
    });
    Api.manager.getState().then((res) => {
      setStates(res.item.states);
    });
  }, []);

  function setTab(value) {
    switch (value) {
      case "0":
        setActiveTab(value);
        break;

      case "1":
        if (course) {
          swal({
            title: "Tạo khóa học mới?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((result) => {
            if (result) {
              handleReset();
              setActiveTab(value);
            }
          });
        } else {
          setActiveTab(value);
        }

        break;

      default:
        if (!course && value !== "0") {
          swal("Oops!", "Vui lòng tạo khóa học trước!", "error");
        } else {
          setActiveTab(value);
        }
        break;
    }
  }

  function handleReset() {
    setReset(false);
    setCourse(null);
    setTimeout(() => {
      setReset(true);
    }, 100);
  }

  function finish() {
    handleReset();
    setTab("0");
  }

  function exit() {
    swal({
      title: "Exit?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        history.push("/");
      }
    });
  }

  function getPathName() {
    switch (tab) {
      case "0":
        return "Dashboard";
      case "1":
        return "Create course";
      case "2":
        return "Create description";
      case "3":
        return "Create document";
      case "4":
        return "Create exercise";
      case "5":
        return "Create session";

      default:
        return "";
    }
  }

  return (
    <div className="manager">
      <div className="manager-container">
        <Row>
          <div className="side-bar">
            <h4 className="title">Maple studio</h4>
            <img src={iconCubic} alt="" className="icon-cubic" />
            <Nav vertical>
              <NavItem>
                <NavLink
                  className={tab === "1" && "active"}
                  onClick={() => setActiveTab("1")}
                >
                  Create course
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab === "2" && "active"}
                  onClick={() => setTab("2")}
                >
                  Create description
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab === "3" && "active"}
                  onClick={() => setTab("3")}
                >
                  Create document
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab === "4" && "active"}
                  onClick={() => setTab("4")}
                >
                  Create exercise
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={tab === "5" && "active"}
                  onClick={() => setTab("5")}
                >
                  Create session
                </NavLink>
              </NavItem>
            </Nav>
            <Button className="logout" onClick={exit}>
              Exit
            </Button>
          </div>

          <div className="main">
            <div className="right-header">
              <div className="r-user d-flex">
                <div className="r-avatar">
                  {info.avatar_url && <img src={info.avatar_url} alt="" />}
                </div>
                <div>
                  <h5 className="r-name">{info.username}</h5>
                  <p className="admin">admin</p>
                </div>
              </div>

              <div className="r-title">
                <div className="dashboard" onClick={() => setTab("0")}>
                  Dashboard
                </div>
                <div className="r-path">Home / {getPathName()}</div>
              </div>
              {tab !== "0" && (
                <div className="list-step">
                  <span className={`step ${tab === "1" && "active"}`}>1</span>
                  <span className={`step ${tab === "2" && "active"}`}>2</span>
                  <span className={`step ${tab === "3" && "active"}`}>3</span>
                  <span className={`step ${tab === "4" && "active"}`}>4</span>
                  <span className={`step ${tab === "5" && "active"}`}>5</span>
                </div>
              )}
            </div>
            <TabContent activeTab={tab}>
              <TabPane tabId="0">
                <DashboardHome setTab={setTab} />
              </TabPane>
              <TabPane tabId="1">
                {reset && (
                  <CreateCourse
                    subjects={subjects}
                    states={states}
                    setCourse={setCourse}
                    setTab={setActiveTab}
                    course={course}
                  />
                )}
              </TabPane>
              <TabPane tabId="2">
                {reset && <CreateDescription setTab={setTab} course={course} />}
              </TabPane>
              <TabPane tabId="3">
                {reset && <CreateDocument setTab={setTab} course={course} />}
              </TabPane>
              <TabPane tabId="4">
                {reset && <CreateExercise setTab={setTab} course={course} />}
              </TabPane>
              <TabPane tabId="5">
                {reset && (
                  <CreateSession
                    setTab={setTab}
                    course={course}
                    finish={finish}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default ManagerScreen;
