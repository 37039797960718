import React, { useState } from "react";

import { TabContent, Nav, NavItem, NavLink } from "reactstrap";

import PosterBanner from "../../components/layouts/PosterBanner";
import SelectList from "../../components/construct/SelectList";
import TabGallery from "./TabGallery";

import "./GalleryScreen.scss";

function GalleryScreen(props) {
  const [activeTab, setActiveTab] = useState("1");

  const options = [
    { id: "1", name: "Tuần này" },
    { id: "2", name: "Tháng này" },
  ];

  function handleSortPage() {}

  return (
    <div className="gallery">
      <PosterBanner />
      <div className="m-container">
        <div className="gallery-area">
          <div className="rows article-title justify-content-between">
            <div className="d-inline-block">
              <div className="tabs-profile tabs-area">
                <Nav className="nav-pills" id="gallery-tab">
                  <NavItem>
                    <NavLink
                      active={activeTab === "1"}
                      id="gallery-new-tab"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="gallery-new"
                      onClick={() => {
                        setActiveTab("1");
                      }}
                    >
                      Mới nhất
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      id="gallery-follow-tab"
                      data-toggle="pill"
                      aria-controls="gallery-follow"
                      onClick={() => {
                        setActiveTab("2");
                      }}
                    >
                      Theo khóa học
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "3"}
                      id="gallery-favorite-tab"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="gallery-favorite"
                      onClick={() => {
                        setActiveTab("3");
                      }}
                    >
                      Yêu thích
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "4"}
                      id="gallery-view-more-tab"
                      data-toggle="pill"
                      aria-controls="gallery-view-more"
                      onClick={() => {
                        setActiveTab("4");
                      }}
                    >
                      Xem nhiều
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="d-inline-block sort-area">
              <SelectList
                list={options}
                handleOnChange={handleSortPage}
                all={true}
              />
            </div>
          </div>
          <div className="tabs-profile">
            <TabContent
              activeTab={activeTab}
              className="commons-card"
              id="gallery-tabContent"
            >
              {activeTab === "1" && <TabGallery type="news" tabId="1" />}

              {activeTab === "2" && <TabGallery type="course" tabId="2" />}

              {activeTab === "3" && <TabGallery type="likes" tabId="3" />}

              {activeTab === "4" && <TabGallery type="most" tabId="4" />}
            </TabContent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryScreen;
