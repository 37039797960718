import React, { useState, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";
import "./CourseNewScreen.scss";

import PosterBanner from "../../components/layouts/PosterBanner";
import ListCourseSub from "../../components/list-courses/ListCourseSub";
import ListCoursesVertical from "../../components/list-courses/ListCoursesVertical";

import { CourseContext } from "../../context/CourseContext";
import Api from "../../services/api/Api";

function CourseNewScreen(props) {
  const location = useLocation();

  const { relatedList } = useContext(CourseContext);

  const [list, setList] = useState([]);

  useEffect(() => {
    getList();
    window.scroll(0, 0);
  }, []);

  function getList() {
    Api.course.new().then((res) => {
      setList(res.item.courses);
    });
  }

  return (
    <div className="course-sub-screen">
      <PosterBanner />
      <div className="m-container">
        <ListCourseSub
          list={list}
          title={"Khóa học mới"}
          page={{ index: 0, max: 0 }}
          location={location}
          handleChangePage={false}
          handleSortPage={false}
        />
        <ListCoursesVertical title="Gợi ý cho bạn" productList={relatedList} />
      </div>
    </div>
  );
}

export default CourseNewScreen;
