import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
} from "reactstrap";
import PropTypes from "prop-types";

DetailTabs.prototype = {
  descriptions: PropTypes.array,
};

function DetailTabs(props) {
  const { descriptions } = props;

  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className="course-detail-tab tabs-profile tabs-area">
      <Nav className="nav-pills" id="pills-tab">
        <NavItem>
          <NavLink
            active={activeTab === "1"}
            id="pills-introduce-tab"
            data-toggle="pill"
            aria-controls="pills-introduce"
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Giới thiệu
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="commons-card"
        id="pills-tabContent"
      >
        <TabPane tabId="1" id="pills-introduce">
          <Card>
            <CardBody>
              {
                <div className="introduce-area">
                  {descriptions.map((item, index) => {
                    return (
                      <div key={index}>
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        {item.images.length > 0 && (
                          <div className="introduce-poster">
                            {item.images.map((el, i) => (
                              <div className="introduce-img" key={i}>
                                <img src={el} alt={item.title} />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              }
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default DetailTabs;
