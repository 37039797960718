import banner from "../../assets/images/1303-original@2x.png";
import "./PosterBanner.scss";

function PosterBanner(prop) {
  return (
    <div className="poster-wrapper bg-shadow">
      <div className="poster-layout">
        <div className="poster-effect">
          <img src={prop.img || banner} alt="" />
        </div>
      </div>
    </div>
  );
}

export default PosterBanner;
