import React, { useEffect, useState } from "react";
import Api from "../services/api/Api";

export const CourseContext = React.createContext();

const DEFAULT_INFO = {
  id: "",
  username: "",
  nickname: "",
  phone: "",
  email: "",
  address: "",
  date_birth: "",
  role: "",
  avatar_url: "",
};

export function CourseProvider(props) {
  const [token, setToken] = useState(localStorage.accessToken);
  const [isLogin, setIsLogin] = useState(!!localStorage.accessToken);

  const [info, setInfo] = useState(DEFAULT_INFO);
  const [menuList, setMenuList] = useState([]);
  const [categoriesList, setcategoriesList] = useState([]);
  const [subjectList, setSubjectList] = useState(-1);
  const [favouriteList, setFavouriteList] = useState([]);
  const [relatedList, setRelatedList] = useState(-1);
  const [courseRegistered, setCourseRegistered] = useState([]);
  const [filterCourse] = useState([
    { id: "101", name: "New" },
    { id: "102", name: "Hot" },
    { id: "103", name: "Online" },
    { id: "104", name: "Offline" },
    { id: "105", name: "Free" },
  ]);
  const [notiList, setNotiList] = useState([]);

  // const [searchOpen, setSearchOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [courseRgsOpen, setCourseRgsOpen] = useState(false);
  const [itemCourseRgs, setItemCourseRgs] = useState({});
  const [services, setServices] = useState([]);
  const [serviceRgsOpen, setServiceRgsOpen] = useState(false);
  const [itemServiceRgs, setItemServiceRgs] = useState({});

  useEffect(() => {
    Api.favorite.categories().then((res) => {
      setcategoriesList(res.item.categories);
    });

    Api.subject()
      .then((res) => {
        setSubjectList(res.item.subjects);
      })
      .catch(() => {
        setSubjectList([]);
      });

    Api.course
      .related()
      .then((res) => {
        setRelatedList([
          ...res.item.courses,
          ...res.item.courses,
          ...res.item.courses,
        ]);
      })
      .catch(() => {
        setRelatedList([]);
      });

    Api.service
      .getList()
      .then((res) => {
        setServices(res.item.services);
      })
      .catch(() => {
        setServices([]);
      });

    if (isLogin) {
      Api.like.list().then((res) => {
        setFavouriteList(res.item.likes);
      });

      getProfile();
      getCourseRegistered();
    } else {
      setCourseRegistered([]);
      setInfo(DEFAULT_INFO);
      setFavouriteList([]);

      setToken("");
    }
  }, [isLogin]);

  useEffect(() => {
    !courseRgsOpen && setItemCourseRgs({});
  }, [courseRgsOpen]);

  useEffect(() => {
    !serviceRgsOpen && setItemServiceRgs({});
  }, [serviceRgsOpen]);

  useEffect(() => {
    setMenuList([
      ...categoriesList.map((_item) => {
        return { ..._item, director: "/courses" };
      }),
      { id: "tarot", name: "Tarot", director: "/tarot" },
      { id: "blog", name: "Blog", director: "/blog" },
    ]);
  }, [categoriesList]);

  // Function
  function getProfile() {
    Api.getProfile()
      .then(function (response) {
        setInfo({ ...response.item.user });
        Api.noti.list(response.item.user.id).then((res) => {
          setNotiList(res.item.notifications);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getNoti() {
    Api.noti.list(info.id).then((res) => {
      setNotiList(res.item.notifications);
    });
  }

  function getPast(time) {
    let _date = new Date(new Date() - new Date(time));
    if (_date.getFullYear() > 1970) {
      return `${_date.getFullYear() - 1970} năm`;
    } else if (_date.getMonth() > 0) {
      return `${_date.getMonth()} tháng`;
    } else {
      return `${_date.getDate()} ngày`;
    }
  }

  function getCourseRegistered() {
    Api.order.getAll().then((res) => {
      setCourseRegistered(res.item.courses);
    });
  }

  function addFavouriteList(item) {
    if (!isLogin) return;

    let _index = favouriteList.findIndex((_item) => {
      return _item.subject_id === item.id;
    });
    if (_index < 0) {
      let _arr = [...favouriteList];

      setFavouriteList([
        ..._arr,
        {
          id: "",
          subject_id: item.id,
          subject_name: item.name,
          subject_image: item.image,
        },
      ]);
      Api.like
        .add(item.id)
        .then((res) => {
          setFavouriteList([
            ..._arr,
            {
              id: res.item.like.id,
              subject_id: item.id,
              subject_name: item.name,
              subject_image: item.image,
            },
          ]);
        })
        .catch(() => {
          setFavouriteList(_arr);
        });
    }
  }
  function removeFavouriteList(item) {
    if (!isLogin) return;
    let _arr = [...favouriteList];
    setFavouriteList(
      favouriteList.filter((_item) => {
        return _item.id !== item.id;
      })
    );
    Api.like
      .remove(item.id)
      .then(() => {})
      .catch(() => {
        setFavouriteList(_arr);
      });
  }

  function logOut() {
    localStorage.setItem("accessToken", "");
    localStorage.setItem("refreshToken", "");

    setIsLogin(false);
  }

  function handleLoginSuccess() {
    setLoginOpen(false);
  }

  function changeLoginToRegister() {
    setRegisterOpen(true);
    setLoginOpen(false);
  }

  function changeRegisterToLogin() {
    setRegisterOpen(false);
    setLoginOpen(true);
  }

  function openCourseRgsModal(item) {
    setItemCourseRgs(item);
    setCourseRgsOpen(true);
  }

  function openServiceRgsModal(item) {
    setItemServiceRgs(item);
    setServiceRgsOpen(true);
  }

  return (
    <CourseContext.Provider
      value={{
        menuList,
        token,
        isLogin,
        info,
        favouriteList,
        categoriesList,
        subjectList,
        relatedList,
        services,
        courseRegistered,
        filterCourse,
        registerOpen,
        loginOpen,
        courseRgsOpen,
        serviceRgsOpen,
        itemCourseRgs,
        itemServiceRgs,
        addFavouriteList,
        removeFavouriteList,
        setInfo,
        logOut,
        setToken,
        setIsLogin,
        setLoginOpen,
        setRegisterOpen,
        setCourseRgsOpen,
        setServiceRgsOpen,
        setItemCourseRgs,
        setItemServiceRgs,
        getProfile,
        getCourseRegistered,
        handleLoginSuccess,
        changeLoginToRegister,
        changeRegisterToLogin,
        openCourseRgsModal,
        openServiceRgsModal,
        notiList,
        getNoti,
        getPast,
      }}
    >
      {props.children}
    </CourseContext.Provider>
  );
}
