import "./App.scss";
import "./assets/css/common.css";
import "./assets/css/icofont.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/slick.min.css";
import "./assets/css/bootstrap.min.css";

import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { CourseProvider } from "./context/CourseContext";

import HomeScreen from "./screens/home/HomeScreen";

import Header from "./components/layouts/Header";
import SideBar from "./components/layouts/SideBar";

import RegisterModal from "./components/modals/RegisterModal";
import LoginModal from "./components/modals/LoginModal";
import CourseRegisterModal from "./components/modals/CourseRegisterModal";

import CourseSubScreen from "./screens/course-sub/CourseSubScreen";
import CourseDetailScreen from "./screens/course-detail/CourseDetailScreen";
import CourseNewScreen from "./screens/course-new/CourseNewScreen";
import PolicyScreen from "./screens/policy/PolicyScreen";
import ProfileScreen from "./screens/profile/ProfileScreen";
import GalleryScreen from "./screens/gallery/GalleryScreen";
import GalleryDetailScreen from "./screens/gallery-detail/GalleryDetailScreen";
import AuthorScreen from "./screens/author/AuthorScreen";
import SearchScreen from "./screens/search/SearchScreen";
import NotificationScreen from "./screens/notification/NotificationScreen";
import SettingScreen from "./screens/setting/SettingScreen";
import ManagerScreen from "./screens/manager/ManagerScreen";
import TarotScreen from "./screens/tarot/TarotScreen";
import BandrollFooter from "./components/layouts/BandrollFooter";
import BlogScreen from "./screens/blog/BlogScreen";
import BlogDetailScreen from "./screens/blog/BlogDetailScreen";
import CreateBlogScreen from "./screens/blog/CreateBlogScreen";
import ServiceDetailScreen from "./screens/service/ServiceDetailScreen";
import ServiceRegisterModal from "./components/modals/ServiceRegisterModal";

function App() {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (document.body.clientWidth < 1423) {
      setToggle(true);
    }

    window.addEventListener("resize", (e) => {
      if (e.currentTarget.innerWidth < 1423) setToggle(true);
    });
  }, []);

  // Function
  function handleSidebarOpen() {
    setToggle(!toggle);
  }

  // Life cycle

  return (
    <CourseProvider>
      <Router>
        <div className="App content-wrapper">
          <Header handleSidebarOpen={handleSidebarOpen} />
          <SideBar toggle={toggle} setToggle={setToggle} />
          <div className={`container-fluid ${toggle && "active"}`}>
            <div className="container-main">
              <Switch>
                <Route exact path="/service/:id">
                  <ServiceDetailScreen />
                </Route>
                <Route exact path="/blog/create">
                  <CreateBlogScreen />
                </Route>
                <Route exact path="/blog/:id">
                  <BlogDetailScreen />
                </Route>
                <Route exact path="/blog">
                  <BlogScreen />
                </Route>
                <Route exact path="/manager">
                  <ManagerScreen />
                </Route>
                <Route exact path="/setting">
                  <SettingScreen />
                </Route>
                <Route exact path="/notification">
                  <NotificationScreen />
                </Route>
                <Route exact path="/policy">
                  <PolicyScreen />
                </Route>
                <Route exact path="/author/:id">
                  <AuthorScreen />
                </Route>
                <Route exact path="/gallery/detail">
                  <GalleryDetailScreen />
                </Route>
                <Route exact path="/gallery">
                  <GalleryScreen />
                </Route>
                <Route exact path="/courses/new">
                  <CourseNewScreen />
                </Route>
                <Route exact path="/course/detail/:id">
                  <CourseDetailScreen />
                </Route>
                <Route exact path="/courses">
                  <CourseSubScreen />
                </Route>
                <Route exact path="/tarot">
                  <TarotScreen />
                </Route>
                <Route exact path="/profile">
                  <ProfileScreen />
                </Route>
                <Route exact path="/search">
                  <SearchScreen />
                </Route>
                <Route exact path="/package">
                  <HomeScreen />
                </Route>
                <Route exact path="/">
                  <HomeScreen />
                </Route>

                <Redirect exact from="/author/:id/reload" to="/author/:id" />
                <Redirect
                  exact
                  from="/course/detail/:id/reload"
                  to="/course/detail/:id"
                />
                <Redirect exact from="/blog/:id/reload" to="/blog/:id" />
              </Switch>
            </div>
            <BandrollFooter />
          </div>
        </div>

        <RegisterModal />
        <LoginModal />
        <CourseRegisterModal />
        <ServiceRegisterModal />
      </Router>
    </CourseProvider>
  );
}

export default App;
