const axios = require("axios");

const httpApi = {
  // path: "https://maplestudio.herokuapp.com/",
  path: localStorage._api === "dev"? "https://maplestudio.herokuapp.com/":"https://api.mapleskills.com/",
};

httpApi.get = function (params) {
  return new Promise(function (resolve, reject) {
    axios
      .get(httpApi.path + params.url, {
        headers: {
          ...params.headers
        },
      })
      .then((res) => {
        resolve({ status: res.status, item: res.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpApi.delete = function (params) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(httpApi.path + params.url, {
        headers: {
          ...params.headers
        },
      })
      .then((res) => {
        resolve({ status: res.status, item: res.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpApi.put = function (params) {
  return new Promise(function (resolve, reject) {
    axios
      .put(httpApi.path + params.url, params.data, {
        headers: {
          ...params.headers
        },
      })
      .then((res) => {
        resolve({ status: res.status, item: res.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpApi.post = function (params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(httpApi.path + params.url, params.data, {
        headers: {
          ...params.headers
        },
      })
      .then((res) => {
        resolve({ status: res.status, item: res.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

httpApi.uploadFile = function (params) {
  return new Promise(function (resolve, reject) {
    axios
      .post(httpApi.path + params.url, params.data, {
        headers: {
          ...params.headers,
          "Content-Type": "multipart/form-data"
        },
      })
      .then((res) => {
        resolve({ status: res.status, item: res.data });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default httpApi;
