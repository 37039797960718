import React, { useContext, useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import PropTypes from "prop-types";
import "./DetailTabs.scss";
import { CourseContext } from "../../context/CourseContext";

DetailTabs.prototype = {
  course: PropTypes.object,
  sessions: PropTypes.array,
  descriptions: PropTypes.array,
  documents: PropTypes.array,
  exercises: PropTypes.array,
};

function DetailTabs(props) {
  const { isLogin, openCourseRgsModal } = useContext(CourseContext);

  const { course, sessions, descriptions, documents, exercises } = props;

  const [activeTab, setActiveTab] = useState("1");

  return (
    <div className="course-detail-tab tabs-profile tabs-area">
      <Nav className="nav-pills" id="pills-tab">
        <NavItem>
          <NavLink
            active={activeTab === "1"}
            id="pills-introduce-tab"
            data-toggle="pill"
            aria-controls="pills-introduce"
            onClick={() => {
              setActiveTab("1");
            }}
          >
            Giới thiệu
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "2"}
            id="pills-content-tab"
            data-toggle="pill"
            aria-controls="pills-content"
            onClick={() => {
              setActiveTab("2");
            }}
          >
            Nội dung
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "3"}
            id="pills-documents-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="pills-documents"
            onClick={() => {
              setActiveTab("3");
            }}
          >
            Tài liệu
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "4"}
            id="pills-exercise-tab"
            data-toggle="pill"
            aria-controls="pills-exercise"
            onClick={() => {
              setActiveTab("4");
            }}
          >
            Bài tập
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "5"}
            id="pills-exchange-tab"
            data-toggle="pill"
            aria-controls="pills-exchange"
            onClick={() => {
              setActiveTab("5");
            }}
          >
            Trao đổi
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className="commons-card"
        id="pills-tabContent"
      >
        <TabPane tabId="1" id="pills-introduce">
          <Card>
            <CardBody>
              {
                <div className="introduce-area">
                  {descriptions.map((item, index) => {
                    return (
                      <div key={index}>
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                        {item.images.length > 0 && (
                          <div className="introduce-poster">
                            {item.images.map((el, i) => (
                              <div className="introduce-img" key={i}>
                                <img src={el} alt={item.title} />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              }
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2" id="pills-content">
          <Card>
            <CardHeader className="justify-content-between">
              <h5 className="card-title">Nội dung khóa học</h5>
            </CardHeader>
            <CardBody>
              <div className="content-course">
                <div className="list-content">
                  {sessions
                    .filter((item, index) => index >= 0)
                    .map((item, index) => {
                      return (
                        <div className="content-item" key={index}>
                          <div className="column-one">
                            <span
                              // className={
                              //   item.lessons.reduce((result, value) => {
                              //     return result && Number(value.is_passed);
                              //   }, true)
                              //     ? "has-step active"
                              //     : "has-step"
                              // }

                              className="has-step"
                            >
                              {index}
                            </span>
                            <span>{item.short_title}</span>
                          </div>
                          <div className="column-two">
                            {item.bookmarks.map((el, i) => {
                              return (
                                <div className="item-lesson" key={i}>
                                  <a
                                    href
                                    className="has-camera"
                                    title={el.title}
                                  >
                                    {el.title}
                                  </a>
                                  {/* {el.is_passed === "1" && <img src={iconCheckComplete} alt="" />} */}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="3" id="pills-documents">
          <Card>
            <CardHeader className="justify-content-between">
              <h5 className="card-title">Tài liệu khóa học</h5>
              <a href className="dowload-more">
                Tải xuống
              </a>
            </CardHeader>
            <CardBody>
              <ul className="document-list">
                {documents.map((item, index) => {
                  return isLogin ? (
                    <li className="document-item" key={index}>
                      <a
                        href={item.url}
                        className="has-document"
                        title={item.title}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.name}
                      </a>
                      <a href={item.url} className="dowload-more">
                        {" "}
                      </a>
                    </li>
                  ) : (
                    <li className="document-item" key={index}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openCourseRgsModal(course)}
                        className="has-document"
                        title={item.title}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.name}
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openCourseRgsModal(course)}
                        className="dowload-more"
                      >
                        {" "}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="4" id="pills-exercise">
          <Card>
            <CardHeader className="justify-content-between">
              <h5 className="card-title">Bài tập khóa học</h5>
              <a href className="dowload-more">
                Tải xuống
              </a>
            </CardHeader>
            <CardBody>
              <ul className="document-list">
                {exercises.map((item, index) => {
                  return isLogin ? (
                    <li className="document-item" key={index}>
                      <a
                        href={item.url}
                        className="has-document"
                        title={item.name}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.name}
                      </a>
                      <a href={item.url} className="dowload-more">
                        {" "}
                      </a>
                    </li>
                  ) : (
                    <li className="document-item" key={index}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openCourseRgsModal(course)}
                        className="has-document"
                        title={item.name}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.name}
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => openCourseRgsModal(course)}
                        className="dowload-more"
                      >
                        {" "}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="5" id="pills-exchange">
          <Card>
            <CardHeader className="justify-content-between">
              <h5 className="card-title">Trao đổi</h5>
            </CardHeader>
            <CardBody>
              <div className="gallery-comment">
                <p>Đặt câu hỏi, nêu thắc mắc cho giáo viên - người hướng dẫn</p>
                <div className="media">
                  <div className="media-user">
                    <img src={""} alt="" />
                  </div>
                  <div className="media-body">
                    <form action="" method="">
                      <textarea
                        name=""
                        id=""
                        className="form-control"
                        rows="5"
                      ></textarea>
                      <button
                        type="submit"
                        className="btn bg-main mt-3 float-right"
                      >
                        Gửi câu hỏi
                      </button>
                    </form>
                  </div>
                </div>
                <div className="comments-area">
                  <h3 className="aside-title">Câu trả lời</h3>
                  <div className="comments-body">
                    <ul>
                      <li className="signle-comments">
                        <div className="commets-text">
                          <span>Câu hỏi - lúc 00:30 - 20/9/2021</span>
                          <p className="font-bold">
                            “ Cô cho em hỏi làm thế nào để tạo 1 bản màu riêng?”
                          </p>
                        </div>
                      </li>
                      <li className="signle-comments">
                        <div className="commets-text">
                          <span>Câu trả lời của giáo viên:</span>
                          <form action="" method="">
                            <textarea
                              name=""
                              id=""
                              className="form-control font-bold"
                              rows="2"
                              placeholder="Em hãy sử dụng kiến thức ở bài 1 và các công cụ thực hiện pha phau Theo Công thức có sẳn…."
                            ></textarea>
                          </form>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default DetailTabs;
