import React, { useState } from "react";
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Col, Input, Row, Button, Collapse, InputGroup } from "reactstrap";

import swal from "sweetalert";

import Api from "../../services/api/Api";

import "./CreateComponent.scss";

function CreateSession(props) {
  const { course, finish } = props;

  const [listItem, setListItem] = useState([{ open: true }]);
  const [listBm, setListBm] = useState([[{}]]);

  function openCollapse(index) {
    let _list = [...listItem];
    _list[index].open = !_list[index].open;
    setListItem(_list);
  }
  function addItem() {
    let _list = [...listItem];
    if (!_list[_list.length - 1].created) {
      swal("Oops!", `Vui lòng hoàn thành Session ${_list.length}!`, "error");
    } else {
      _list.push({ open: true });
      setListItem(_list);

      let _listBm = [...listBm];
      _listBm.push([{}]);
      setListBm(_listBm);
    }
  }
  function createItem(event, index) {
    event.preventDefault();

    let form = new FormData(document.querySelector(`#form-session-${index + 1}`));

    let _data = Object.fromEntries(form);

    let bookmarks = new FormData(document.querySelector(`.form-bookmarks-${index + 1}`));
    let _bm = Object.fromEntries(bookmarks);

    let _dataBm = [];
    for (let i = 0; i < Object.keys(_bm).length / 2; i++) {
      _dataBm.push({
        title: _bm[`title-${i}`],
        time_line: _bm[`time_line-${i}`],
      });
    }

    _data.bookmarks = _dataBm;

    console.log(_data);

    Api.manager
      .session(_data)
      .then((res) => {
        let _list = [...listItem];
        _list[index].created = true;
        setListItem(_list);
      })
      .catch((error) => {
        if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
        else swal("Oops!", "Thất bại!", "error");
      });
  }
  function addBm(index) {
    let _list = [...listBm];
    _list[index].push({});
    setListBm(_list);
  }

  function removeBm(index) {
    let _list = [...listBm];
    if (listBm[index].length > 1) {
      _list[index] = _list[index].slice(0, _list[index].length - 1);

      setListBm(_list);
    }
  }

  function onChangeTime(e) {
    let data = e.target.value
      .split("")
      .filter((item) => /[0-9]/.test(item))
      .join("");
    if (data.length >= 2) {
      data = data.slice(0, 2) + ":" + data.slice(2, 4);
    }

    e.target.value = data;
  }

  return (
    <div className="create-session create-component">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div className="title">
              Create session <span className="num">(10)</span>
            </div>
            <Button onClick={finish}>Finish</Button>
          </div>
        </CardHeader>
        <CardBody>
          {listItem.map((item, index) => {
            return (
              <div className="description-item" key={index}>
                <Button className="description-button" onClick={() => openCollapse(index)}>
                  Session {index + 1}
                </Button>
                <Collapse isOpen={listItem[index].open}>
                  <Card>
                    <CardBody>
                      <Form id={`form-session-${index + 1}`} onSubmit={(e) => createItem(e, index)}>
                        <Row>
                          <Col lg={6} className="pr-lg-5">
                            <FormGroup>
                              <Input id="serial" name="serial" type="text" placeholder="Serial" value={index + 1} />
                            </FormGroup>
                            <FormGroup>
                              <Input id="course_id" name="course_id" type="text" placeholder="Course Id" value={course && course.id} />
                            </FormGroup>
                            <FormGroup>
                              <Input id="title" name="title" type="text" placeholder="Title" required invalid />
                            </FormGroup>
                            <FormGroup>
                              <Input id="short_title" name="short_title" type="text" placeholder="Short title" required invalid />
                            </FormGroup>
                            <FormGroup>
                              <Input id="duration" name="duration" type="text" placeholder="Duration (00:00)" required invalid onChange={onChangeTime} />
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="pl-lg-5">
                            <FormGroup row>
                              <Input id="description" name="description" type="textarea" style={{ height: "125px" }} placeholder="Description" required invalid />
                            </FormGroup>
                            <Label>
                              <span className="red">*</span> Is private
                            </Label>
                            <FormGroup check row className="ml-5">
                              <Input name="is_private" type="radio" value={"true"} />
                              <Label check>true</Label>
                            </FormGroup>
                            <FormGroup check row className="ml-5">
                              <Input name="is_private" type="radio" value={"false"} checked />
                              <Label check>false</Label>
                            </FormGroup>
                            <FormGroup className="bookmarks mt-4">
                              <Label>Book marks</Label>
                              <Form className={`form-bookmarks-${index + 1}`}>
                                {listBm[index].map((item, index) => {
                                  return (
                                    <FormGroup row key={index}>
                                      <Col>
                                        <Input name={`title-${index}`} required invalid placeholder="Title" />
                                      </Col>
                                      <Col>
                                        <Input name={`time_line-${index}`} required invalid placeholder="Time line (00:00)" onChange={onChangeTime} />
                                      </Col>
                                    </FormGroup>
                                  );
                                })}
                              </Form>
                              <InputGroup className="justify-content-center gap-5 mt-4">
                                <Button onClick={() => removeBm(index)}>- Remove bookmarks</Button>
                                <Button onClick={() => addBm(index)}>+ Add more bookmarks</Button>
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                        {!item.created && (
                          <Button className="button-done" type="submit">
                            Done
                          </Button>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            );
          })}

          <div className="add-item">
            <Button onClick={addItem}>+ Add more session</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateSession;
