import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Row,
  Button,
  Collapse,
} from "reactstrap";

import swal from "sweetalert";

import Api from "../../services/api/Api";

import "../manager/CreateComponent.scss";

function CreateBodyPost(props) {
  const { finish, post } = props;

  const [listItem, setListItem] = useState([{ open: true }]);

  function openCollapse(index) {
    let _list = [...listItem];
    _list[index].open = !_list[index].open;
    setListItem(_list);
  }
  function addItem() {
    let _list = [...listItem];
    if (!_list[_list.length - 1].created) {
      swal(
        "Oops!",
        `Vui lòng hoàn thành description ${_list.length}!`,
        "error"
      );
    } else {
      _list.push({ open: true });
      setListItem(_list);
    }
  }
  function createItem(event, index) {
    event.preventDefault();

    let form = document.querySelector(`#form-exercise-${index + 1}`);

    if (!form.checkValidity()) return;
    let formData = new FormData(form);
    if (formData.getAll("images").length > 3) {
      swal("Oops!", "Vui lòng chỉ chọn tối đa 3 photo!", "error");
    } else {
      Api.blog
        .createBodyPost(formData)
        .then((res) => {
          let _list = [...listItem];
          _list[index].created = true;
          setListItem(_list);
        })
        .catch((error) => {
          if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
          else swal("Oops!", "Thất bại!", "error");
        });
    }
  }

  return (
    <div className="create-exercise create-component">
      <Card>
        <CardHeader>
          <div className="row justify-content-between">
            <div className="title-header col-12 col-sm-7 col-md-8">
              <div className="title">Create body post</div>
              <div className="mt-3">{post.title}</div>
            </div>
            <div className="button-right col-12 col-sm-5 col-md-4 d-flex justify-content-end">
              <div className="button-wrap mt-3">
                <Button onClick={() => finish()}>Finfish</Button>
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {listItem.map((item, index) => {
            return (
              <div className="description-item" key={index}>
                <Button
                  className="description-button"
                  onClick={() => openCollapse(index)}
                >
                  #{index + 1}
                </Button>
                <Collapse isOpen={listItem[index].open}>
                  <Card>
                    <CardBody>
                      <Form
                        id={`form-exercise-${index + 1}`}
                        onSubmit={(e) => createItem(e, index)}
                      >
                        <Row>
                          <Col lg={6} className="pr-lg-5">
                            <FormGroup row>
                              <Label for="description" sm={4}>
                                Serial
                              </Label>
                              <Col sm={8}>
                                <Input
                                  id="serial"
                                  name="serial"
                                  type="text"
                                  placeholder="Serial"
                                  value={index + 1}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="description" sm={4}>
                                Blog id
                              </Label>
                              <Col sm={8}>
                                <Input
                                  id="post_id"
                                  name="post_id"
                                  type="text"
                                  placeholder="Blog Id"
                                  value={post && post.id}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="pl-lg-5">
                            <FormGroup row>
                              <Label for="description" sm={4}>
                                <span className="red">*</span> Description
                              </Label>
                              <Col sm={8}>
                                <Input
                                  id="description"
                                  name="description"
                                  type="textarea"
                                  required
                                  invalid
                                  style={{ height: "125px" }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label for="images" sm={4}>
                                <span>+</span> images (max 3)
                              </Label>
                              <Col sm={8}>
                                <div className="input-file">
                                  <Input
                                    id="images"
                                    name="images"
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    multiple
                                    max={3}
                                    required
                                    invalid
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                        </Row>
                        {!item.created && (
                          <Button className="button-done" type="submit">
                            Done
                          </Button>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            );
          })}

          <div className="add-item">
            <Button onClick={addItem}>+ Add more description</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateBodyPost;
