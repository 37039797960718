import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";

import SelectList from "../construct/SelectList";
import CardVerticalCourse from "../cards/CardVerticalCourse";
import { CourseContext } from "../../context/CourseContext";

import "./ListCoursesVertical.scss";

ListCoursesVertical.prototype = {
  productList: PropTypes.array,
  title: PropTypes.string,
  className: PropTypes.string,
};

function ListCoursesVertical(props) {
  const { categoriesList } = useContext(CourseContext);

  const { productList, title, className } = props;

  const [list, setList] = useState(productList);

  useEffect(() => {
    setList(productList);
  }, [productList]);

  function sortList(event) {
    if (event.value === "") setList(productList);
    else {
      setList(
        productList.filter((item) => {
          return item.categories_id === event.value;
        })
      );
    }
  }

  return (
    <div
      className={`${
        className ? className + " " : ""
      }list-courses-vertical new-course-area`}
    >
      <div className="row article-title justify-content-between">
        <div className="col-6">
          <h2 className="section-title">{title}</h2>
        </div>
        <div className="col-6 col-right">
          <SelectList
            list={categoriesList}
            handleOnChange={sortList}
            all={true}
          />
        </div>
      </div>

      {list.length !== 0 && (
        <Slider
          className="new-carousel slick-arrow-style"
          autoplay={false}
          speed={1000}
          slidesToShow={4}
          infinite={false}
          prevArrow={
            <button type="button" className="slick-prev">
              <i className="icofont-thin-left"></i>
            </button>
          }
          nextArrow={
            <button type="button" className="slick-next">
              <i className="icofont-thin-right"></i>
            </button>
          }
          responsive={[
            {
              breakpoint: 1326,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                mobileFirst: true,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                mobileFirst: true,
              },
            },
            {
              breakpoint: 650,
              settings: {
                slidesToShow: 1,
                mobileFirst: true,
              },
            },
          ]}
        >
          {list === -1
            ? Array.from(new Array(4)).map((item, index) => {
                return <CardVerticalCourse item={item} key={index} />;
              })
            : list.map((item, index) => {
                return <CardVerticalCourse item={item} key={index} />;
              })}
        </Slider>
      )}
      {list.length === 0 && <div className="no-data"></div>}
    </div>
  );
}

export default ListCoursesVertical;
