import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Col,
  Input,
  Row,
  Button,
  Collapse,
} from "reactstrap";

import swal from "sweetalert";

import Api from "../../services/api/Api";

import "./CreateComponent.scss";

function CreateDescription(props) {
  const { setTab, course } = props;

  const [listDsct, setListDsct] = useState([{ open: true }]);

  function openCollapse(index) {
    let _list = [...listDsct];
    _list[index].open = !_list[index].open;
    setListDsct(_list);
  }
  function addDescription() {
    let _list = [...listDsct];
    if (!_list[_list.length - 1].created) {
      swal(
        "Oops!",
        `Vui lòng hoàn thành Description ${_list.length}!`,
        "error"
      );
    } else {
      _list.push({ open: true });
      setListDsct(_list);
    }
  }
  function createDescription(event, index) {
    event.preventDefault();

    let form = document.querySelector(`#form-description-${index + 1}`)
    let formData = new FormData(
      form
    );

    if (!form.checkValidity()) return
  

    if (formData.getAll("description-photo").length > 3) {
      swal("Oops!", "Vui lòng chỉ chọn tối đa 3 photo!", "error");
    } else {
      Api.manager
        .description(formData)
        .then((res) => {
          let _list = [...listDsct];
          _list[index].created = true;
          setListDsct(_list);
        })
        .catch((error) => {
          if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
          else swal("Oops!", "Thất bại!", "error");
        });
    }
  }

  return (
    <div className="create-description create-component">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between">
            <div className="title">
              Create description <span className="num">(10)</span>
            </div>
            <Button onClick={() => setTab("3")}>Next</Button>
          </div>
        </CardHeader>
        <CardBody>
          {listDsct.map((item, index) => {
            return (
              <div className="description-item" key={index}>
                <Button
                  className="description-button"
                  onClick={() => openCollapse(index)}
                >
                  Description {index + 1}
                </Button>
                <Collapse isOpen={listDsct[index].open}>
                  <Card>
                    <CardBody>
                      <Form id={`form-description-${index + 1}`} onSubmit={(e) => createDescription(e, index)}>
                        <Row>
                          <Col lg={6} className="pr-lg-5">
                            <FormGroup row>
                              <Input
                                id="serial"
                                name="serial"
                                type="text"
                                placeholder="Serial"
                                value={index + 1}
                              />
                            </FormGroup>
                            <FormGroup row>
                              <Input
                                id="course_id"
                                name="course_id"
                                type="text"
                                placeholder="Course Id"
                                value={course && course.id}
                              />
                            </FormGroup>
                            <FormGroup row>
                              <Input
                                id="title"
                                name="title"
                                type="text"
                                placeholder="Title"
                                required invalid
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={6} className="pl-lg-5">
                            <FormGroup row>
                              <Input
                                id="description"
                                name="description"
                                type="textarea"
                                style={{ height: "125px" }}
                                placeholder="Description"
                                required invalid
                              />
                            </FormGroup>
                            <FormGroup row>
                              <div className="input-file">
                                <Input
                                  id="description-photo"
                                  name="description-photo"
                                  type="file"
                                  accept="image/jpeg, image/png"
                                  multiple
                                  max={3}
                                  required invalid
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        {!item.created && (
                          <Button
                            className="button-done"
                            type="submit"
                          >
                            Done
                          </Button>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            );
          })}

          <div className="add-item">
            <Button onClick={addDescription}>+ Add more description</Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CreateDescription;
