import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Col, Input, Row, Button } from "reactstrap";
import swal from "sweetalert";

import Api from "../../services/api/Api";

import "./CreateComponent.scss";

function CreateCourse(props) {
  const { subjects, states, setCourse, setTab, course } = props;

  const [inputPrice, setInputPrice] = useState("");
  const [inputTime, setInputTime] = useState("");
  const [inputDiscount, setInputDiscount] = useState("");

  useEffect(() => {
    let data = inputPrice
      .split("")
      .filter((item) => /[0-9]/.test(item))
      .join("");

    if (data.length > 3) {
      for (let index = data.length - 3; index > 0; index = index - 3) {
        data = data.slice(0, index) + "." + data.slice(index, data.length);
      }
    }

    setInputPrice(data);
  }, [inputPrice]);

  useEffect(() => {
    let data = inputTime
      .split("")
      .filter((item) => /[0-9]/.test(item))
      .join("");

    if (data.length >= 2) {
      data = "Tháng " + data.slice(0, 2) + "/" + data.slice(2, 6);
    } else if (data.length) {
      data = "Tháng " + data;
    }

    setInputTime(data);
  }, [inputTime]);

  useEffect(() => {
    let data = inputDiscount
      .split("")
      .filter((item) => /[0-9]/.test(item))
      .join("");

    if (data.length) data = "Giảm " + data.slice(0, 2);

    if (data.length >= 7) data = data + "%";

    setInputDiscount(data);
  }, [inputDiscount]);

  function createCourse(event) {
    event.preventDefault();

    if (course) {
      setTab("2");
      return;
    }

    let form = document.querySelector("#form-course");

    if (form.checkValidity()) {
      let formData = new FormData(form);

      Api.manager
        .create(formData)
        .then((res) => {
          setCourse(res.item.course);
          setTab("2");
        })
        .catch((error) => {
          if (error.item !== undefined) swal("Oops!", error.item.msg, "error");
          else swal("Oops!", "Thất bại!", "error");
        });
    }
  }

  return (
    <div className="create-component create-course">
      <Form id="form-course" onSubmit={createCourse}>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between">
              <div className="title">
                Create course <span className="num">(10)</span>
              </div>
              <Button type="submit" >
                Next
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg={6} className="pr-lg-4">
                <FormGroup row>
                  <Label for="subject_id" sm={4}>
                    <span className="red">*</span> Subject
                  </Label>
                  <Col sm={8}>
                    <Input id="subject_id" name="subject_id" type="select">
                      {subjects.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="state_id" sm={4}>
                    <span className="red">*</span> State
                  </Label>
                  <Col sm={8}>
                    <Input id="state_id" name="state_id" type="select">
                      {states.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name} - {item.is_online === "true" ? "online" : "offline"}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="title" sm={4}>
                    <span className="red">*</span> Title
                  </Label>
                  <Col sm={8}>
                    <Input id="title" name="title" type="text" required invalid />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="language" sm={4}>
                    <span className="red">*</span> Language
                  </Label>
                  <Col sm={8}>
                    <Input id="language" name="language" type="select">
                      <option value="vi">Tiếng Việt</option>
                      <option value="en">Tiếng Anh</option>
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="price" sm={4}>
                    <span className="red">*</span> Price
                  </Label>
                  <Col sm={8}>
                    <Input id="price" name="price" type="text" required invalid placeholder="xxx.xxx.xxx" value={inputPrice} onChange={(e) => setInputPrice(e.target.value)} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="time" sm={4}>
                    <span className="red">*</span> Time
                  </Label>
                  <Col sm={8}>
                    <Input id="time-month" name="time" type="text" required invalid placeholder="xx/xxxx" value={inputTime} onChange={(e) => setInputTime(e.target.value)} />
                  </Col>
                </FormGroup>
              </Col>
              <Col lg={6} className="pl-lg-4">
                <FormGroup row>
                  <Label for="discount" sm={4}>
                    <span className="red">*</span> Discount
                  </Label>
                  <Col sm={8}>
                    <Input id="discount" name="discount" type="text" required invalid placeholder="xx%" value={inputDiscount} onChange={(e) => setInputDiscount(e.target.value)} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="num_sub" sm={4}>
                    <span className="red">*</span> Num_sub
                  </Label>
                  <Col sm={8}>
                    <Input id="num_sub" name="num_sub" type="number" defaultValue={0} min={0} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="detail" sm={4}>
                    <span className="red">*</span> Detail
                  </Label>
                  <Col sm={8}>
                    <Input id="detail" name="detail" type="textarea" required invalid style={{ height: "125px" }} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="image" sm={4}>
                    <span>+</span> Image
                  </Label>
                  <Col sm={8}>
                    <div className="input-file">
                      <Input id="image" name="image" type="file" accept="image/jpeg, image/png" required invalid />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="portrait_image" sm={4}>
                    <span>+</span> Portrait image
                  </Label>
                  <Col sm={8}>
                    <div className="input-file">
                      <Input id="portrait_image" name="portrait_image" type="file" accept="image/jpeg, image/png" required invalid />
                    </div>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}

export default CreateCourse;
