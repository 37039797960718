import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CardVerticalCourse from "../../components/cards/CardVerticalCourse";

import btnViewMore from "../../assets/images/icons/next-more.svg";

RelatedViewMore.prototype = {
  list: PropTypes.array,
  title: PropTypes.string,
};

function RelatedViewMore(props) {
  const { list, title } = props;

  const [courseList, setCourseList] = useState([]);
  const [viewMore, setViewMore] = useState(true);

  useEffect(() => {
    if (list !== -1) {
      if (viewMore) {
        if (list.length < 4) setViewMore(false);
        setCourseList(list.slice(0, 4));
      } else {
        setCourseList(list);
      }
    }
  }, [list, viewMore]);

  return (
    <div className="list-course-sub new-course-area mt-5">
      <div className="row article-title justify-content-between">
        <div className="col-lg-9">
          <h2 className="section-title">{title}</h2>
        </div>
      </div>
      <div className="single-list rows">
        {courseList.map((item, index) => {
          return (
            <div className="single-box" key={index}>
              <CardVerticalCourse item={item} />
            </div>
          );
        })}
      </div>
      {viewMore && (
        <div className="btn-area text-center mb-5">
          <button
            className="btn btn-more"
            onClick={() => {
              setViewMore(false);
            }}
          >
            <span>Xem thêm khóa học khác</span>
            <img src={btnViewMore} alt="" />
          </button>
        </div>
      )}
    </div>
  );
}

export default RelatedViewMore;
