import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CourseContext } from "../../context/CourseContext";

import "./CardVerticalBlog.scss";

import Skeleton from "@mui/material/Skeleton";

function CardVerticalBlog(props) {
  const { categoriesList } = useContext(CourseContext);

  const { item } = props;

  const [categoriesName, setCategoriesName] = useState("");

  useEffect(() => {
    let _cate = categoriesList.find((el) => el.id === item.categories_id);
    if (_cate) {
      setCategoriesName(_cate.name);
    }
  }, [categoriesList, item]);

  return item ? (
    <div
      id="card-vertical"
      className="blog-item single-item fade-item vertical-item"
      style={{ display: "inline-block" }}
    >
      <div className="single-item-body">
        <Link to={`/blog/${item.id}/reload`} title={item.title}>
          <div
            className="single-item-img"
            style={{ backgroundImage: `url("${item.cover_image}")` }}
          >
            <div className="single-item-hover">
              <div className="cart-metalic">
                <span className="bg-draw" title={categoriesName}>
                  {categoriesName}
                </span>
              </div>
            </div>
          </div>
        </Link>

        <div className="single-item-content">
          <h3 className="item-title">
            <Link to={`/blog/${item.id}/reload`} title={item.title}>
              {item.title}
            </Link>
          </h3>
          <p className="item-content">{item.detail}</p>
        </div>
      </div>
    </div>
  ) : (
    <div
      id="card-vertical"
      className="single-item vertical-item"
      style={{ display: "inline-block" }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        width="100%"
        height={200}
      />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="h1" />
      <Skeleton animation="wave" width="60%" />
      <div className="px-2 mt-2 mb-3">
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height={40}
        />
      </div>
    </div>
  );
}

export default CardVerticalBlog;
