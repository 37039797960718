import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import PosterBanner from "../../components/layouts/PosterBanner";
import ListGallery from "../../components/list-gallery/ListGallery";
import GalleryDetailPost from "./GalleryDetailPost";

import iconArrowBack from "../../assets/images/icons/icon-arrow-back.svg";

import Api from "../../services/api/Api";

import "./GalleryDetailScreen.scss";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function GalleryDetailScreen(props) {
  const history = useHistory();
  const query = useQuery();

  const id = query.get("id");
  const userId = query.get("u");

  const [list, setList] = useState([]);

  useEffect(() => {
    // Get related list
    Api.gallery.listNew(0).then((res) => {
      setList(mapList(res.item.gallery, res.item.likes));
    });

    window.scroll(0, 0);
  }, [id, userId]);

  // Function
  function mapList(listGallery, listLike) {
    return listGallery.map((item) => {
      let index = listLike.findIndex((i) => i.gallery_id === item.id);
      return { ...item, is_like: index >= 0 };
    });
  }

  return (
    <div className="gallery-detail">
      <PosterBanner />
      <div className="m-container">
        <div className="gallery-area">
          <div className="gallery-back">
            <a href onClick={history.goBack}>
              <img src={iconArrowBack} alt="" />
            </a>
          </div>
          <GalleryDetailPost id={id} userId={userId} />
          <div className="rows article-title">
            <h2 className="section-title">Tác phẩm tương tự</h2>
          </div>
          <ListGallery list={list} />
        </div>
      </div>
    </div>
  );
}

export default GalleryDetailScreen;
