import React, { useContext, useEffect, useState } from "react";

import avatar from "../../assets/images/avatar.png";
import iconEdit from "../../assets/images/icons/icon-edit.svg";
import iconFeatherHeart from "../../assets/images/icons/icon-feather-heart.svg";
import Api from "../../services/api/Api";
import swal from "sweetalert";

import "./ProfileBanner.scss";

import regex from "../../services/regex";
import { CourseContext } from "../../context/CourseContext";

function ProfileBanner(props) {
  const { info, setInfo } = useContext(CourseContext);

  const [editStatus, setEditStatus] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState(avatar);

  const [oldAvatar, setOldAvatar] = useState(avatar);

  let refInput = React.createRef();

  const [userLocation, setUserLocation] = useState("");

  useEffect(() => {
    setAvatarSrc(
      !regex.emptyString(info.avatar_url) ? info.avatar_url : avatar
    );

    let arr = info.address.split(",");
    setUserLocation(
      arr.filter((item, index) => index >= arr.length - 2 && item).join(",")
    );
  }, [info]);

  // Function

  function handleChangeEditClick() {
    refInput.current.click();
  }

  function changeAvatar(event) {
    let _file = event.target.files;
    if (_file && _file[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setOldAvatar(avatarSrc);
        setAvatarSrc(e.target.result);
        setEditStatus(true);
      };

      reader.readAsDataURL(_file[0]);
    }
  }

  function cancelEdit() {
    setAvatarSrc(oldAvatar);
    setEditStatus(false);
    document.querySelector(".avatar-input").value = "";
  }

  function updateAvatar() {
    setEditStatus(false);

    let oData = new FormData();
    oData.append(
      "maple-photo",
      refInput.current.files[0],
      refInput.current.files[0].name
    );

    Api.uploadAvatar(oData)
      .then((res) => {
        setAvatarSrc(res.item.user.avatar_url);
        setInfo({ ...info, avatar_url: res.item.user.avatar_url });
      })
      .catch(() => {
        swal("Oops!", "Xảy ra lỗi. Vui lòng thử lại.", "error");
      });
  }

  return (
    <div className="banner-profile">
      <div className="media">
        <div className="media-user">
          <img className="avatar-img" src={avatarSrc} alt="" />
        </div>
        <div className="media-body">
          <h5>{info.nickname}</h5>
          <div className="entry-media rows">
            <div>
              <p>{info.email}</p>
            </div>
            <div>
              <i className="icofont-location-pin"></i>
              <span>{userLocation || ""}</span>
            </div>
          </div>
          {!editStatus && (
            <div className="btn-banner btn-edit">
              <a
                href
                className="btn btn-edit"
                data-toggle="modal"
                data-target="#"
                onClick={handleChangeEditClick}
              >
                <img src={iconEdit} alt="" />
                Chỉnh sửa
              </a>
            </div>
          )}
          {editStatus && (
            <div className="btn-horizontal edit-profile btn-banner">
              <a href className="btn btn-detail" onClick={cancelEdit}>
                Bỏ qua
              </a>
              <a href className="btn bg-main" onClick={updateAvatar}>
                Lưu
              </a>
            </div>
          )}
          <form enctype="multipart/form-data" method="post" name="fileinfo">
            <input
              className="avatar-input"
              ref={refInput}
              type="file"
              name="maple-photo"
              onChange={changeAvatar}
            />
          </form>
        </div>
      </div>
      <div className="media-favourite">
        <div>{info.role}</div>
        <img src={iconFeatherHeart} alt="" />
      </div>
    </div>
  );
}

export default ProfileBanner;
