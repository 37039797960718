import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Nav } from "reactstrap";

import { Link } from "react-router-dom";
import "./SideBar.scss";

import { CourseContext } from "../../context/CourseContext";

import iconHome from "../../assets/images/icons/icon-home.svg";
import iconHomeFocus from "../../assets/images/icons/icon-home-focus.svg";
import iconNewCourse from "../../assets/images/icons/icon-new-course.svg";
import iconNewCourseFocus from "../../assets/images/icons/icon-new-course-focus.svg";
import iconPackageCourse from "../../assets/images/icons/icon-package-course.svg";
import iconPackageCourseFocus from "../../assets/images/icons/icon-package-course-focus.svg";
import iconYourCourse from "../../assets/images/icons/icon-your-course.svg";
import iconYourCourseFocus from "../../assets/images/icons/icon-your-course-focus.svg";
import iconListCourse from "../../assets/images/icons/icon-list-course.svg";
import iconListCourseFocus from "../../assets/images/icons/icon-list-course-focus.svg";

import iconFb from "../../assets/images/icons/awesome-facebook.svg";
import iconYtb from "../../assets/images/icons/awesome-youtube.svg";
import iconIg from "../../assets/images/icons/awesome-instagram.svg";
import iconPlus from "../../assets/images/icons/plus.png";
import iconMinus from "../../assets/images/icons/minus.png";
import iconTiktok from "../../assets/images/icons/tiktok.png";

function SideBar(props) {
  const history = useHistory();
  const location = useLocation();

  const { setToggle } = props;

  useEffect(() => {
    if (document.body.clientWidth < 1423) {
      setToggle(true);
    }
  }, [location, setToggle]);

  const { isLogin, favouriteList, removeFavouriteList, subjectList } =
    useContext(CourseContext);

  const { toggle } = props;

  const [listCourse, setListCourse] = useState([]);
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    if (viewMore) setListCourse(favouriteList);
    else setListCourse(favouriteList.slice(0, 4));
  }, [favouriteList, viewMore]);

  let menuList = [];
  if (isLogin)
    menuList = [
      {
        title: "Trang chủ",
        path: { pathname: "/" },
        className: "home",
        icon: iconHome,
        iconFocus: iconHomeFocus,
      },
      {
        title: "Khóa học mới",
        path: { pathname: "/courses/new" },
        className: "new-course",
        icon: iconNewCourse,
        iconFocus: iconNewCourseFocus,
      },
      {
        title: "Gói khóa học",
        path: { pathname: "/package" },
        className: "package-course",
        icon: iconPackageCourse,
        iconFocus: iconPackageCourseFocus,
      },
      {
        title: "Khóa học của bạn",
        path: { pathname: "/profile", state: { tab: "2" } },
        className: "your-course",
        icon: iconYourCourse,
        iconFocus: iconYourCourseFocus,
      },
      {
        title: "Danh sách khóa",
        path: { pathname: "/courses" },
        className: "list-course",
        icon: iconListCourse,
        iconFocus: iconListCourseFocus,
      },
    ];
  else
    menuList = [
      {
        title: "Trang chủ",
        path: { pathname: "/" },
        className: "home",
        icon: iconHome,
        iconFocus: iconHomeFocus,
      },
      {
        title: "Khóa học mới",
        path: { pathname: "/courses/new" },
        className: "new-course",
        icon: iconNewCourse,
        iconFocus: iconNewCourseFocus,
      },
      {
        title: "Gói khóa học",
        path: { pathname: "/package" },
        className: "package-course",
        icon: iconPackageCourse,
        iconFocus: iconPackageCourseFocus,
      },
      {
        title: "Danh sách khóa",
        path: { pathname: "/courses" },
        className: "list-course",
        icon: iconListCourse,
        iconFocus: iconListCourseFocus,
      },
    ];

  let socialList = [
    {
      title: "Facebook",
      img: iconFb,
      link: "https://www.facebook.com/artsoulMaple",
    },
    {
      title: "Youtube",
      img: iconYtb,
      link: "https://www.youtube.com/channel/UCIDsnzSIV4J9BxOWf0n7lsQ",
    },
    {
      title: "Instagram",
      img: iconIg,
      link: "https://www.instagram.com/maple.studio.art",
    },
    {
      title: "Tiktok",
      img: iconTiktok,
      link: "https://www.tiktok.com/@maplestudioart?_t=8UEzn3xzJy5&_r=1",
    },
  ];

  function setSubject(value) {
    let _item = subjectList.find((item) => item.id === value.subject_id);

    history.push({
      ...history.location,
      pathname: "/courses",
      state: { categoriesId: _item.categories_id, subjectId: _item.id },
    });
  }

  return (
    <div className={`sidebar ${toggle && "active"}`}>
      <div className="sidebar-space"></div>
      <div className="sidebar-container">
        <div className="sidebar-navigation">
          <Nav>
            <div className="mobile-menu-area">
              <ul className="mobile-menu">
                {menuList.map((item, index) => {
                  return (
                    <li
                      className={`has-children ${item.className} ${
                        location.pathname === item.path.pathname && "active"
                      }`}
                      key={index}
                    >
                      <Link to={item.path}>
                        <div className="box-icon">
                          <img
                            className="nav-icon"
                            src={item.icon}
                            alt={item.title}
                          />
                          <img
                            className="nav-icon-hover"
                            src={item.iconFocus}
                            alt={item.title}
                          />
                        </div>
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            {isLogin && (
              <div className="favourite-area">
                <div className="header-sidebar">
                  <span>Yêu thích</span>
                </div>

                <ul className="favourite-list">
                  {listCourse.map((item, index) => {
                    return (
                      <li
                        className={`favourite-item ${!item.id && "disable"}`}
                        key={index}
                      >
                        <a
                          href
                          title={item.subject_name}
                          onClick={() => setSubject(item)}
                        >
                          <div className="favourite-thumb">
                            <img
                              src={item.subject_image}
                              alt={item.subject_name}
                            />
                          </div>
                        </a>
                        <div className="item-right">
                          <span onClick={() => setSubject(item)}>
                            {item.subject_name}
                          </span>
                          <i className="i-plus">
                            <img src={iconPlus} alt="plus" />
                          </i>
                          <i
                            className="i-remove"
                            onClick={() => {
                              removeFavouriteList(item);
                            }}
                          >
                            <img src={iconMinus} alt="remove" />
                          </i>
                        </div>
                      </li>
                    );
                  })}
                  {!viewMore && favouriteList.length > 4 && (
                    <li className="favourite-item">
                      <div
                        className="view-more"
                        onClick={() => {
                          setViewMore(true);
                        }}
                      >
                        <i className="icofont-thin-down"></i>
                        <span>Xem thêm</span>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            )}
            <div className="social-area">
              <div className="header-sidebar">
                <span>Theo dõi</span>
              </div>

              <ul className="social-list">
                {socialList.map((item, index) => {
                  return (
                    <li className="social-item" key={index}>
                      <a
                        rel="noreferrer"
                        href={item.link}
                        target="_blank"
                        className="social-link"
                        title={item.title}
                      >
                        <img src={item.img} alt={item.title} />
                        <span>{item.title}</span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="footer-area">
              <div className="footer-menu">
                <ul className="footer-list">
                  <span className="footer-item">
                    <Link
                      to={{ pathname: "/policy", state: { tab: "1" } }}
                      className="footer-link"
                      title="Liên hệ"
                    >
                      Liên hệ
                    </Link>
                  </span>
                  <span className="footer-item">
                    <Link
                      to={{ pathname: "/policy", state: { tab: "2" } }}
                      className="footer-link"
                      title="Giới thiệu"
                    >
                      Giới thiệu
                    </Link>
                  </span>
                  <span className="footer-item">
                    <Link
                      to={{ pathname: "/policy", state: { tab: "3" } }}
                      className="footer-link"
                      title="Điều khoản sử dụng"
                    >
                      Điều khoản sử dụng
                    </Link>
                  </span>
                  <span className="footer-item">
                    <Link
                      to={{ pathname: "/policy", state: { tab: "4" } }}
                      className="footer-link"
                      title="Chính sách bảo mật"
                    >
                      Chính sách bảo mật
                    </Link>
                  </span>
                </ul>
                <div className="coppy-right">
                  <p>
                    ©{" "}
                    <a href title="Maple studio">
                      Maple studio 2021
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
