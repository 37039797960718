import React, { useEffect, useContext, useState } from "react";

import PosterBanner from "../../components/layouts/PosterBanner";
import { CourseContext } from "../../context/CourseContext";

import "./BlogScreen.scss";

import banner from "../../assets/images/blog-banner.jpg";
import Api from "../../services/api/Api";
import CardVerticalBlog from "../../components/cards/CardVerticalBlog";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { Card, CardBody, CardHeader } from "reactstrap";

import iconCalendar from "../../assets/images/icons/icon-calendar.png";

function BlogDetailScreen(props) {
  const { categoriesList } = useContext(CourseContext);

  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [categoriesName, setCategoriesName] = useState("");
  const [newestPost, setNewestPost] = useState(null);
  const [highestView, setHighestView] = useState(null);

  useEffect(() => {
    Api.blog
      .getDetail(id)
      .then((res) => {
        setDetail(res.item);
      })
      .catch((error) => {
        swal("Oops!", error.item, "error");
      });

    Api.blog.getNewestPost().then((res) => {
      setNewestPost(res.item);
    });

    Api.blog.getHighestView().then((res) => {
      setHighestView(res.item);
    });
  }, [id]);

  useEffect(() => {
    if (detail) {
      let _cate = categoriesList.find(
        (el) => el.id === detail.post.categories_id
      );
      if (_cate) {
        setCategoriesName(_cate.name);
      }
    }
  }, [categoriesList, detail]);

  return (
    <div className="blog-detail-screen blog-screen home-screen">
      <PosterBanner img={banner} />
      <div className="content-header">
        <div className="title">Mapleskills's blog</div>
      </div>
      <div className="m-container">
        <div className="list-course-sub list-courses-vertical new-course-area">
          <div className="content-main row">
            <div className="course-detail-tab col-xl-9 mb-4">
              <div className="commons-card">
                {!detail && <div className="no-data"></div>}
                {detail && (
                  <Card>
                    <CardHeader>
                      <div className="cart-metalic">
                        <span className="bg-draw" title={categoriesName}>
                          {categoriesName}
                        </span>
                      </div>
                      <div className="title">{detail.post.title}</div>
                      <div className="date">
                        <img src={iconCalendar} alt="icon calendar" />
                        <span>{detail.post.time}</span>
                      </div>
                    </CardHeader>
                    <CardBody id="pills-introduce">
                      {
                        <div className="introduce-area">
                          {detail.body.map((item, index) => {
                            return (
                              <div key={index}>
                                {item.images.length > 0 && (
                                  <div className="introduce-poster">
                                    {item.images.map((el, i) => (
                                      <div className="introduce-img" key={i}>
                                        <img src={el} alt={item.title} />
                                      </div>
                                    ))}
                                  </div>
                                )}
                                <p>{item.description}</p>
                              </div>
                            );
                          })}
                        </div>
                      }
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>
            <div className="content-right col-xl-3 row">
              {newestPost && (
                <div className="col-right col-sm-6 col-md-5 col-xl-12 ">
                  <div className="title">Mới nhất</div>
                  <div className="item">
                    <CardVerticalBlog item={newestPost.post} />
                  </div>
                </div>
              )}
              {highestView && (
                <div className="col-right col-sm-6 col-md-5 col-xl-12">
                  <div className="title">Xem nhiều nhất</div>
                  <div className="item">
                    <CardVerticalBlog item={highestView.post} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDetailScreen;
