import httpApi from "./httpApi";

const NodeRSA = require("node-rsa");

function encrypt(value) {
  let key = new NodeRSA(`-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsOyvbrpnytwwVphiRqpu3CiQL
  DBq9+4kQosd2aDBbfSSKAWzeeHyQdaimgh4W8sucfMWFhzQ84OOh10AkMZ1FZbL2
  TjNGQj32RTetpJb0K4zTxZIlPhwpBUWJOjp0VcNYOZTd2zoeNK5LjUBhQVzy/aPC
  eAy6vr9R9Kq8PIQ/BwIDAQAB
  -----END PUBLIC KEY-----`);

  let encrypted = key.encrypt(value, "base64");
  return encrypted;
}

const Api = {};

// Authen
Api.register = (params) => {
  return httpApi.post({
    url: "auth/register/",
    data: { ...params, password: encrypt(params.password) },
  });
};

Api.login = (params) => {
  return httpApi.post({
    url: "auth/login/",
    data: { ...params, password: encrypt(params.password) },
  });
};

// Profile
Api.changePassword = (params) => {
  return httpApi.put({
    url: "user/password",
    headers: { authorization: localStorage.accessToken },
    data: params,
  });
};

Api.changeProfile = (params) => {
  return httpApi.put({
    url: "user/profile",
    headers: { authorization: localStorage.accessToken },
    data: params,
  });
};

Api.getProfile = () => {
  return httpApi.get({
    url: "user/profile",
    headers: { authorization: localStorage.accessToken },
  });
};

Api.uploadAvatar = (formData) => {
  return httpApi.uploadFile({
    url: "user/avatar/upload",
    headers: { authorization: localStorage.accessToken },
    data: formData,
  });
};

// Note: use parame email when focus password
Api.OTP = () => {
  return httpApi.get({
    url: "user/otp",
    headers: { authorization: localStorage.accessToken },
  });
};

Api.changeEmail = (params) => {
  return httpApi.put({
    url: "user/email",
    headers: { authorization: localStorage.accessToken },
    data: params,
  });
};

Api.favorite = {
  categories() {
    return httpApi.get({
      url: "favorite/categories",
      headers: { authorization: localStorage.accessToken },
    });
  },

  list() {
    return httpApi.get({
      url: "favorite",
      headers: { authorization: localStorage.accessToken },
    });
  },

  add(id) {
    return httpApi.post({
      url: "favorite/add",
      headers: { authorization: localStorage.accessToken },
      data: { id_categories: id },
    });
  },
  remove(id) {
    return httpApi.delete({
      url: "favorite/" + id,
      headers: { authorization: localStorage.accessToken },
    });
  },
};

Api.subject = () => {
  return httpApi.get({
    url: "subject",
    headers: { authorization: localStorage.accessToken },
  });
};

Api.course = {
  categories(id, page = 0, filter = "") {
    return httpApi.get({
      url: `course/?categories_id=${id}&page=${page}&filter=${filter}`,
      headers: { authorization: localStorage.accessToken },
    });

    // Test~~~~~~~~~~~~~~~~~~~
    // return new Promise((resovle, reject) => {
    //   this.related()
    //     .then((res) => {
    //       let data = res;
    //       let items = data.item.courses;
    //       let length = items.length;
    //       if (length > 0 && length < 24) {
    //         for (let index = 0; index < 24 / length - 1; index++) {
    //           data.item.courses = [...data.item.courses, ...items];
    //         }
    //         let more = items.slice(0, 24 - data.item.courses.length);

    //         data.item.courses = [...data.item.courses, ...more];
    //       } else data.item.courses = items.slice(0, 24);

    //       if (filter) data.item.num_page = 7;
    //       else data.item.num_page = 20;
    //       resovle(data);
    //     })
    //     .catch(reject);
    // });
  },
  subject(id, page = 0, filter = "") {
    return httpApi.get({
      url: `course/?subject_id=${id}&page=${page}&filter=${filter}`,
      headers: { authorization: localStorage.accessToken },
    });

    // Test~~~~~~~~~~~~~~~~~~~
    // return new Promise((resovle, reject) => {
    //   this.related()
    //     .then((res) => {
    //       let data = res;
    //       let items = data.item.courses;
    //       let length = items.length;
    //       if (length > 0 && length < 24) {
    //         for (let index = 0; index < 24 / length - 1; index++) {
    //           data.item.courses = [...data.item.courses, ...items];
    //         }
    //         let more = items.slice(0, 24 - data.item.courses.length);

    //         data.item.courses = [...data.item.courses, ...more];
    //       } else data.item.courses = items.slice(0, 24);

    //       if (filter) data.item.num_page = 5;
    //       else data.item.num_page = 13;
    //       resovle(data);
    //     })
    //     .catch(reject);
    // });
  },

  new(page = 0) {
    return httpApi.get({
      url: `course/new?page=${page}`,
      headers: { authorization: localStorage.accessToken },
    });

    // Test~~~~~~~~~~~~~~~~~~~
    // return new Promise((resovle, reject) => {
    //   httpApi
    //     .get({
    //       url: `course/new?page=${0}`,
    //       headers: { authorization: localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       let data = res;
    //       let items = data.item.courses;
    //       let length = items.length;
    //       if (length > 0 && length < 24) {
    //         for (let index = 0; index < 24 / length - 1; index++) {
    //           data.item.courses = [...data.item.courses, ...items];
    //         }
    //         let more = items.slice(0, 24 - data.item.courses.length);

    //         data.item.courses = [...data.item.courses, ...more];
    //       } else data.item.courses = items.slice(0, 24);

    //       data.item.num_page = 17;
    //       resovle(data);
    //     })
    //     .catch(reject);
    // });
  },

  famous() {
    return httpApi.get({
      url: "course/wfamous",
      headers: { authorization: localStorage.accessToken },
    });
  },

  related() {
    return httpApi.get({
      url: `course/foryou`,
      headers: { authorization: localStorage.accessToken },
    });
  },

  poster() {
    return httpApi.get({
      url: `course/poster`,
      headers: { authorization: localStorage.accessToken },
    });
  },

  detail(id) {
    return httpApi.get({
      url: `course/` + id,
      headers: { authorization: localStorage.accessToken },
    });
  },
};

Api.like = {
  list() {
    return httpApi.get({
      url: "like",
      headers: { authorization: localStorage.accessToken },
    });
  },
  add(id) {
    return httpApi.post({
      url: "like/add",
      headers: { authorization: localStorage.accessToken },
      data: {
        id_subject: id,
      },
    });
  },

  remove(id) {
    return httpApi.delete({
      url: "like/" + id,
      headers: { authorization: localStorage.accessToken },
    });
  },
};

Api.order = {
  // Đăng ký khóa học
  // "order_id": "",
  // "course_id": "940b277f-6ca4-463c-90bd-10ca632e1c5d",
  // "course_title": "Vẽ màu nước căn bản 3",
  // "user_name": "Lee",
  // "user_email": "fake@mail.com",
  // "user_phone": "0123456789"
  form(params) {
    return httpApi.post({
      url: "order/form",
      data: params,
    });
  },
  add(id) {
    return httpApi.post({
      url: "order/add",
      headers: { authorization: localStorage.accessToken },
      data: {
        course_id: id,
      },
    });
  },

  getAll() {
    return httpApi.get({
      url: "order/all",
      headers: { authorization: localStorage.accessToken },
    });
  },

  detail(id) {
    return httpApi.get({
      url: `order/course/` + id,
      headers: { authorization: localStorage.accessToken },
    });
  },
};

Api.progress = {
  // "order_id": "{{:id_order}}",
  // "session_id": "{{:id_session}}",
  // "percent": "5"
  add(params) {
    return httpApi.post({
      url: "progress/add",
      headers: { authorization: localStorage.accessToken },
      data: params,
    });
  },
};

Api.gallery = {
  detail(id, userId) {
    return httpApi.get({
      url: "gallery/" + id,
      data: {
        user_id: userId,
      },
    });
  },

  listNew(page) {
    return httpApi.get({
      url: "gallery/news?page=" + page,
    });
  },

  listLike(page) {
    return httpApi.get({
      url: "gallery/likes?page=" + page,
    });
  },

  author(id, page) {
    return httpApi.get({
      url: `gallery/author/${id}?page=${page}`,
    });
  },

  like(id) {
    return httpApi.put({
      url: "gallery/like/" + id,
      headers: { authorization: localStorage.accessToken },
    });
  },

  comment(id, comment) {
    return httpApi.post({
      url: "gallery/comment/" + id,
      headers: { authorization: localStorage.accessToken },
      data: {
        content: comment,
      },
    });
  },

  create(formData) {
    return httpApi.uploadFile({
      url: "gallery/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },

  delete(id) {
    return httpApi.delete({
      url: "gallery/" + id,
      headers: { authorization: localStorage.accessToken },
    });
  },
};

Api.search = {
  get(key) {
    return httpApi.get({
      url: "search?keyword=" + key,
    });
  },
  complete(key) {
    return httpApi.get({
      url: "search/complete?keyword=" + key,
    });
  },
};

Api.noti = {
  list(idUser) {
    return httpApi.get({
      url: `notification/?id_user=${idUser}&page=0`,
    });
  },

  read(id) {
    return httpApi.put({
      url: "notification/read",
      headers: { authorization: localStorage.accessToken },
      data: {
        id_notification: id,
      },
    });
  },

  readAll() {
    return httpApi.put({
      url: "notification/read/all",
      headers: { authorization: localStorage.accessToken },
    });
  },

  delete(id) {
    return httpApi.delete({
      url: `notification/delete/${id}`,
      headers: { authorization: localStorage.accessToken },
    });
  },

  create(style, idCourse, target) {
    return httpApi.put({
      url: "notification/create",
      headers: { authorization: localStorage.accessToken },
      data: {
        style: style,
        id_course: idCourse,
        target: target,
      },
    });
  },
};

Api.manager = {
  getSubject() {
    return httpApi.get({ url: "subject" });
  },
  getState() {
    return httpApi.get({
      url: "state",
      headers: { authorization: localStorage.accessToken },
    });
  },

  create(formData) {
    return httpApi.uploadFile({
      url: "course/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },
  description(formData) {
    return httpApi.uploadFile({
      url: "description/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },
  document(formData) {
    return httpApi.uploadFile({
      url: "document/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },
  exercise(formData) {
    return httpApi.uploadFile({
      url: "exercise/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },
  session(data) {
    return httpApi.post({
      url: "session/create",
      headers: { authorization: localStorage.accessToken },
      data: data,
    });
  },
};


Api.tarot = {
  get () {
    return httpApi.get({
      url: "tarot",
    });
  }
}

Api.blog = {
  getList () {
    return httpApi.get({
      url: "blog",
    });
  },

  getDetail (id) {
    return httpApi.get({
      url: "blog/" + id,
    });
  },

  getNewestPost () {
    return httpApi.get({
      url: "blog/newest",
    });
  },

  getHighestView () {
    return httpApi.get({
      url: "blog/highest_view",
    });
  },

  createNewPost(formData) {
    return httpApi.uploadFile({
      url: "blog/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },

  createBodyPost(formData) {
    return httpApi.uploadFile({
      url: "post_body/create",
      headers: { authorization: localStorage.accessToken },
      data: formData,
    });
  },
}

Api.service = {
  getList () {
    return httpApi.get({
      url: "service",
    });
  },

  getDetail (id) {
    return httpApi.get({
      url: "service/" + id,
    });
  },
}

export default Api;
