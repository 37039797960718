import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TabPane, Card, CardBody, CardHeader, CardTitle } from "reactstrap";
// import SelectList from "../general/SelectList";
import CardHorizontalCourse from "../../components/cards/CardHorizontalCourse";
import Pagination from "../../components/construct/Pagination";
import { CourseContext } from "../../context/CourseContext";

const NUM_ITEM = 5;

TabCourse.propTypes = {
  tabId: PropTypes.string,
};

function TabCourse(props) {
  const { courseRegistered } = useContext(CourseContext);

  const { tabId } = props;

  const [list, setList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageMax, setPageMax] = useState(0);

  useEffect(() => {
    setPageMax(Math.ceil(courseRegistered.length / NUM_ITEM));
  }, [courseRegistered]);

  useEffect(() => {
    setList(
      courseRegistered.slice(pageIndex * NUM_ITEM, pageIndex * NUM_ITEM + 5)
    );
  }, [pageIndex, courseRegistered]);

  // Function
  function handleChangePage(page) {
    setPageIndex(page);
  }
  // function handleSortPage(event) {
  //   if (event.value != this.props.groupId) {
  //     this.setState((state) => {
  //       return {
  //         list: [],
  //       };
  //     });
  //     setTimeout(() => {
  //       this.props.handleSortPage(event);
  //     }, 0);
  //   }
  // }

  return (
    <TabPane
      tabId={tabId}
      id="pills-course"
      role="tabpanel"
      aria-labelledby="pills-course-tab"
    >
      <Card>
        <CardHeader className="justify-content-between">
          <CardTitle>Khóa học của bạn</CardTitle>
          {/* <SelectList list={} handleOnChange={handleSortPage} all={true} /> */}
        </CardHeader>
        <CardBody>
          <div className="profile-blog">
            {list.length > 0 &&
              list.map((item, index) => {
                return <CardHorizontalCourse item={item} key={index} />;
              })}
          </div>

          {pageMax.length > 1 && (
            <Pagination
              page={{ index: pageIndex, max: pageMax }}
              handleChangePage={handleChangePage}
            />
          )}
        </CardBody>
      </Card>
    </TabPane>
  );
}

export default TabCourse;
