import React, { useState } from "react";

import Api from "../../services/api/Api";

import "./TarotScreen.scss";

import banner from "../../assets/images/tarot-banner.jpg";
import card from "../../assets/images/tarot-card.jpg";
import ball from "../../assets/images/tarot-ball.png";

const detail = "Lật bài để biết thông tin từ vụ trụ...";

function TarotScreen(props) {
  const [tarot, setTarot] = useState(null);

  function getTarot() {
    if (tarot) {
      setTarot(null);
    } else {
      Api.tarot.get().then((res) => {
        console.log(res);
        setTarot(res.item.tarot);
      });
    }
  }
  return (
    <div className="tarot-screen">
      <div className="poster-wrapper bg-shadow">
        <div className="poster-layout">
          <div className="poster-effect">
            <img src={banner} alt="" />
            <div className="banner-title">TAROT HẰNG NGÀY</div>
          </div>
        </div>

        <div className="poster-title">
          <div className="tarot-ball">
            <img src={ball} alt="tarot ball" />
          </div>
          <div className="title">Dự đoán Tarot hằng ngày</div>
        </div>
      </div>
      <div className="m-container">
        <div className="row">
          <div className="col-lg-3">
            <div className="card-area">
              <div className="card-title">
                Lật bài để biết hôm nay của bạn như thế nào!
              </div>
              <div
                className={`card-img${tarot ? " open-card" : ""}`}
                onClick={getTarot}
              >
                <img className="back-card" src={tarot && tarot.image} alt="" />
                <img className="front-card" src={card} alt="Tarot card" />
              </div>
            </div>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <div className={`detail${tarot ? " open-card" : ""}`}>
              <div className="title-item">Tổng quan</div>
              <div className="detail-item">
                {tarot ? tarot.overview : detail}
              </div>
              <div className="title-item">Công việc</div>
              <div className="detail-item">{tarot ? tarot.career : detail}</div>
              <div className="title-item">Tình yêu</div>
              <div className="detail-item">{tarot ? tarot.love : detail}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TarotScreen;
