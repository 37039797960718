import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";

import "./CourseDetailScreen.scss";

import CommonsCard from "./CommonsCard";
import DetailTabs from "./DetailTabs";
import RelatedViewMore from "./RelatedViewMore";
import ModalDetail from "./ModalDetail";

import Api from "../../services/api/Api";
import { CourseContext } from "../../context/CourseContext";
import swal from "sweetalert";

function CourseDetailScreen(props) {
  const { relatedList, courseRegistered, subjectList, categoriesList } =
    useContext(CourseContext);

  const { id } = useParams();

  const [detail, setDetail] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ title: "", body: "" });
  const [subject, setSubject] = useState({});

  useEffect(() => {
    if (id) {
      let _index = courseRegistered.findIndex((item) => item.id === id);

      if (_index >= 0) {
        Api.order
          .detail(id)
          .then((res) => {
            setDetail({ ...res.item, registered: true });
            let _subject = subjectList.find(
              (item) => item.id === res.item.course.subject_id
            );
            _subject && setSubject(_subject);
          })
          .catch((error) => {
            setDetail({ registered: true });
            swal("Oops!", error.item.error, "error");
          });
      } else {
        Api.course
          .detail(id)
          .then((res) => {
            setDetail({ ...res.item, registered: false });
            let _subject = subjectList.find(
              (item) => item.id === res.item.course.subject_id
            );
            _subject && setSubject(_subject);
          })
          .catch((error) => {
            setDetail({ registered: false });
            swal("Oops!", error.item.error, "error");
          });
      }

      // test

      // Api.course
      //   .detail(id)
      //   .then((res) => {
      //     setDetail({ ...res.item, registered: false });
      //     let _subject = subjectList.find(
      //       (item) => item.id === res.item.course.subject_id
      //     );
      //     _subject && setSubject(_subject);
      //   })
      //   .catch((error) => {
      //     setDetail({ registered: false });
      //     swal("Oops!", error.item, "error");
      //   });
    }

    window.scrollTo(0, 0);
  }, [id, courseRegistered, subjectList, categoriesList]);

  // function

  function handleOpenModal(index) {
    setModalData({
      title: detail.sessions[index].title,
      body: detail.sessions[index].description,
    });
    setModalOpen(true);
  }

  function handleCloseModal() {
    setModalOpen(false);
  }

  return (
    <div className="course-detail">
      <div className="m-container">
        {detail.course && (
          <p className="path-title">
            Khóa học &gt;{" "}
            <span>
              {" "}
              <Link
                to={{
                  pathname: "/courses",
                  state: { categoriesId: subject.categories_id },
                }}
              >
                {subject.categories_name}
              </Link>{" "}
              &gt;{" "}
              <Link
                to={{
                  pathname: "/courses",
                  state: {
                    categoriesId: subject.categories_id,
                    subjectId: subject.id,
                  },
                }}
              >
                {subject.name}
              </Link>
            </span>
          </p>
        )}
        <CommonsCard
          course={detail.course || {}}
          sessions={detail.sessions || []}
          handleOpenModal={handleOpenModal}
          registered={detail.registered}
        />
        <DetailTabs
          course={detail.course || {}}
          sessions={detail.sessions || []}
          descriptions={detail.descriptions || []}
          documents={detail.documents || []}
          exercises={detail.exercises || []}
        />
        <RelatedViewMore title={"Có thể bạn thích"} list={relatedList || []} />
        <ModalDetail
          isOpen={modalOpen}
          handleCloseModal={handleCloseModal}
          data={modalData}
        />
      </div>
    </div>
  );
}

export default CourseDetailScreen;
