import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Api from "../../services/api/Api";

import { CourseContext } from "../../context/CourseContext";
import { useHistory } from "react-router";

import "./HomeScreen.scss";

import HomeSlider from "./HomeSlider";
import HomeBlogArea from "./HomeBlogArea";
import ListCoursesVertical from "../../components/list-courses/ListCoursesVertical";
import SubjectList from "../../components/list-subjects/SubjectList";

HomeScreen.prototype = {
  sidebarOpen: PropTypes.bool,
};

function HomeScreen(props) {
  const history = useHistory();

  const { relatedList, categoriesList } = useContext(CourseContext);

  const [sliderList, setSliderList] = useState([]);
  const [banners, setBanners] = useState(-1);
  const [newCourses, setNewCourses] = useState(-1);
  const [famousCourses, setFamousCourses] = useState(-1);

  const [cateId, setCateId] = useState("");

  useEffect(() => {
    if (categoriesList[0]) {
      setCateId(categoriesList[0].id);
    }
  }, [categoriesList]);

  useEffect(() => {
    // get data

    Api.course
      .poster()
      .then((res) => {
        setSliderList([...res.item.courses, ...res.item.courses]);
        setBanners([...res.item.banner, ...res.item.banner]);
      })
      .catch(() => {
        setBanners([]);
      });

    Api.course
      .new(0)
      .then((res) => {
        setNewCourses([...res.item.courses, ...res.item.courses]);
      })
      .catch(() => {
        setNewCourses([]);
      });

    Api.course
      .famous()
      .then((res) => {
        setFamousCourses([...res.item.courses, ...res.item.courses]);
      })
      .catch(() => {
        setFamousCourses([]);
      });

    window.scrollTo(0, 0);
  }, []);

  function setSubjectId(id) {
    history.push({
      ...history.location,
      pathname: "/courses",
      state: { categoriesId: cateId, subjectId: id },
    });
  }

  return (
    <div className="home-screen">
      <HomeSlider list={sliderList} banners={banners} />
      <div className="bg-title">
        <div></div>
      </div>
      <div className="m-container">
        <ListCoursesVertical
          className="home-new"
          title="Khóa học mới"
          productList={newCourses}
        />
        <SubjectList
          defaultCate={cateId}
          defaultSub={""}
          handleChangeSubject={setSubjectId}
          handleSelectList={(e) => setCateId(e.value)}
        />
        <HomeBlogArea title="Khóa học nổi bật" productList={famousCourses} />
        <ListCoursesVertical title="Gợi ý cho bạn" productList={relatedList} />
      </div>
    </div>
  );
}

export default HomeScreen;
